import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter} from "react-router-dom";

//---
import Home from "./pages/Home/Home/Home";
import FAQs from './pages/FAQs/FAQs';
import Error404 from './pages/Error404/Error404';
import TermsConditions from './pages/TermsConditions/TermsConditions';
import Averia from './pages/Averia/Averia';
import OfficeSchedule from "./pages/OfficeSchedule/OfficeSchedule";
import Footer from './components/Footer/Footer';

//Account
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import AccountDashboard from './pages/Account/AccountDashboard/AccountDashboard';
import AccountSettings from './pages/Account/AccountSettings/AccountSettings';
import AccountPaperlessBilling from './pages/Account/AccountPaperlessBilling/AccountPaperlessBilling';
import PaperlessBillingTNC from './pages/Account/AccountPaperlessBillingTNC/AccountPaperlessBillingTNC';
import AccountChange from './pages/Account/AccountChange/AccountChange';
import AddAccount from './pages/Account/AddAccount/AddAccount';
import AccountConsumptionHistory from './pages/Account/AccountConsumptionHistory/AccountConsumptionHistory';
import AccountPayslip from './pages/Account/AccountPayslip/AccountPayslip';

//About
import Profile from './pages/About/Profile';
import MissionVision from './pages/About/MissionVision';
import History from './pages/About/History';
import Milestones from './pages/About/Milestones';
import Opportunities from './pages/About/Opportunities';
import ContactUs from './pages/About/ContactUs';

//Services
import MeterApplication from './pages/Services/MeterApplication';
import SCDiscount from './pages/Services/SCDiscount';
import SMSProgram from './pages/Services/SMSProgram';
import ChangeOfName from './pages/Services/ChangeOfName';
import PBService from './pages/Services/PBService';
import LifelineRate from './pages/Services/LifelineRate';
import OtherServices from './pages/Services/OtherServices';

//Bills Payment
import CollectionOffices from './pages/BillsPayment/CollectionOffices';
import Online from './pages/BillsPayment/Online';
import LocalBanks from './pages/BillsPayment/LocalBanks';

//News & Advisories
import News from './pages/NewsAdvisories/News';
import Advisories from './pages/NewsAdvisories/Advisories';
import RatesArchives from './pages/NewsAdvisories/RatesArchives';
import Announcements from './pages/NewsAdvisories/Announcements';
import NewsArchive from './pages/NewsArchive/NewsArchive';
import NewsInfo from './pages/NewsInfo/NewsInfo';

//Infomercials
import CelcorLive from './pages/Infomercials/CelcorLive';
import CustomerGuide from './pages/Infomercials/CustomerGuide';
import WattsUp from './pages/Infomercials/WattsUp';
import GuessWatt from './pages/Infomercials/GuessWatt';

// Advisories
import ERCAdvisories from "./pages/NewsAdvisories/ERCAdvisories";
import DOEAdvisories from "./pages/NewsAdvisories/DOEAdvisories";
import ServiceAdvisories from './pages/NewsAdvisories/ServiceAdvisories';
import BillAdvisories from './pages/NewsAdvisories/BillAdvisories';
import OfficeAdvisories from './pages/NewsAdvisories/OfficeAdvisories';
import ScheduledPowerInterruption from './pages/NewsAdvisories/ScheduledPowerInterruption';
import EmergencyPowerInterruption from './pages/NewsAdvisories/EmergencyPowerInterruption';
import CityWidePowerInterruption from './pages/NewsAdvisories/CityWidePowerInterruption';
import NGCPAlerts from './pages/NewsAdvisories/NGCPAlerts';

function App() {

  return (
    <React.Fragment>
    <div className="App">
      <div className='appContainer'>
        <BrowserRouter>
          <Routes>
            {/* Account */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<AccountDashboard />} />
            <Route path="/manage-account" element={<AccountSettings />} />
            <Route path="/change-account" element={<AccountChange />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/paperless-billing" element={<AccountPaperlessBilling />} />
            <Route path="/paperless-billing-terms-and-conditions" element={<PaperlessBillingTNC />} />
            <Route path="/add-account" element={<AddAccount />} />
            <Route path="/consumption-history" element={<AccountConsumptionHistory />} />
            <Route path="/averia" element={<Averia />} />
            {/* <Route path="/payslip" element={<AccountPayslip />} /> */}

            {/* About CELCOR */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/mission-and-vision" element={<MissionVision />} />
            <Route path="/history" element={<History />} />
            <Route path="/milestones" element={<Milestones />} />
            <Route path="/opportunities" element={<Opportunities />} />
            <Route path="/contact-us" element={<ContactUs />} />

            {/* Services */}
            <Route path="/application-for-meter" element={<MeterApplication />} />
            <Route path="/senior-citizen-discount" element={<SCDiscount />} />
            <Route path="/sms-program" element={<SMSProgram />} />
            <Route path="/change-of-name" element={<ChangeOfName />} />
            <Route path="/paperless-billing-service" element={<PBService />} />
            <Route path="/services/lifeline-rate" element={<LifelineRate />} />
            <Route path="other-services" element={<OtherServices />} />

            {/* Bills Payment */}
            <Route path="/collection-offices" element={<CollectionOffices />} />
            <Route path="/online" element={<Online />} />
            <Route path="/local-banks-and-establishments" element={<LocalBanks />} />

            {/* News & Advisories */}
            <Route path="/news" element={<News />} />
            <Route path="/advisories" element={<Advisories />} />
            <Route path="/rate-archives" element={<RatesArchives />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/news-archive/:buwan" element={<NewsArchive />} />
            <Route path="/news/:aydi" element={<NewsInfo />} />

            {/* Infomercials */}
            <Route path="/celcor-live" element={<CelcorLive />} />
            <Route path="/customer-guide" element={<CustomerGuide />} />
            <Route path="/watts-up" element={<WattsUp />} />
            <Route path="/guess-watt" element={<GuessWatt />} />

            {/* Advisories */}
            <Route path="/erc-advisories" element={<ERCAdvisories />} />
            <Route path="/doe-advisories" element={<DOEAdvisories />} />
            <Route path="/service-advisories" element={<ServiceAdvisories />} />
            <Route path="/bill-advisories" element={<BillAdvisories />} />
            <Route path="/office-advisories" element={<OfficeAdvisories />} />
            <Route path="/scheduled-power-interruption" element={<ScheduledPowerInterruption />} />
            <Route path="/emergency-power-interruption" element={<EmergencyPowerInterruption />} />
            <Route path="/city-wide-power-interruption" element={<CityWidePowerInterruption />} />
            <Route path="/ngcp-alerts" element={<NGCPAlerts />} />

            {/* Others */}
            <Route path="*" element={<Error404 />} />
            <Route path="terms-and-conditions" element={<TermsConditions />} />
            <Route path="office-schedule" element={<OfficeSchedule />} />
            <Route path="/frequently-asked-questions" element={<FAQs />} />

          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
    </React.Fragment>
  )
  
}

export default App;

