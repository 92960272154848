import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./css/LatestAdvisories.css";
import moment from "moment";
import config from 'react-global-configuration';

function LatestAdvisories() {

    const [state, setState] = useState({
        list: [],
        adv: true,
        header: 'SERVICE'
    });

    useEffect(() => {
        axios
			.get(`${config.get('apiUrl')}/Advisories/homeAdvisories`, {
				params: {
					tmp: 1
				}
			})
			.then(response => {
                setState(prevState => ({
                    ...prevState,
                    header: response.data.header,
                    list: response.data.list
                }));
			});
    },[],);

    return (
        <React.Fragment>
            <div id="latestadvisory" className="container-fluid relate">
                <div className="card gradient-bg shadow">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <div className="text-primary h5 font-weight-bold"><b>{state.header} ADVISORY</b></div>
                    </div>
                    <div className="card-body pb-5">
                        {state.adv && state.adv === true ? (
                            <img src={`https://restapi.celcor.com.ph/files/imgs/special-advisory-2.jpg`} onError={(e) => {
                                setState(prevState => ({
                                    ...prevState,
                                    adv: false
                                }));
                            }} className="img-fluid sp-adv" />
                        ) : (
                            <React.Fragment>
                                <div className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="10000">
                                    <div className="">
                                {state.list && state.list.map((item, idx) => {
                                    if(idx === 0)
                                        return(
                                            <div className="carousel-item active">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className="centered-text-container">
                                                            <div className={`centered-text color-light-black ${item.Identifier}`} >
                                                                <p className="text-align-center color-black">
                                                                    {moment(item.Date).format('MMM').toUpperCase()}<br />
                                                                    {moment(item.Date).format('DD')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-9">
                                                        <strong className="color-black advisory-no-left">{item.Category}</strong><br />
                                                        <strong className="color-black advisory-no-left">DURATION : </strong>
                                                        <span className="color-light-black">{item.Duration}</span><br />
                                                        <strong className="color-black advisory-no-left">REASON : </strong>
                                                        <span className="color-light-black">{item.Reason}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <strong className="color-black">AFFECTED AREAS :</strong><br />
                                                        <span className="color-light-black">
                                                            {item.AffectedAreas.split('\n').map(i => {
                                                                return <span>{i}<br /></span>
                                                            })}
                                                            <br />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    return(
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="centered-text-container">
                                                        <div className={`centered-text color-light-black ${item.Identifier}`} >
                                                            <p className="centered-text">
                                                                {moment(item.Date).format('MMM').toUpperCase()}<br />
                                                                {moment(item.Date).format('DD')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <strong className="color-black advisory-no-left">{item.Category}</strong><br />
                                                    <strong className="color-black advisory-no-left">DURATION : </strong>
                                                    <span className="color-light-black">{item.Duration}</span><br />
                                                    <strong className="color-black advisory-no-left">REASON : </strong>
                                                    <span className="color-light-black">{item.Reason}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <strong className="color-black">AFFECTED AREAS :</strong><br />
                                                    <span className="color-light-black">
                                                        {item.AffectedAreas.split('\n').map(i => {
                                                            return <span>{i}<br /></span>
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                            </React.Fragment>
                        )}
                    {Object.keys(state.list).length > 0 ? (
                        <div className="bottom-link blog_card_content">
                            <Link className="hyperlink" to={`/advisories`}><h6>Read More &gt;&gt;</h6></Link>
                        </div>
                    ) : ""}
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
}

export default LatestAdvisories;