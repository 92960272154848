import React from "react";
import { Link } from "react-router-dom";
import "./css/NewsAdvisoriesComponents.css";

function AnnouncementSidebar() {
	return(
		<div className="advisories-bodystyle">
			<div className="card gradient-bg shadow mb-2">
				<div className="list-group">
					<Link className="hyperlink list-group-item archive-item list-group-item-action" to="/erc-advisories">ERC Advisories</Link>
					<Link className="hyperlink list-group-item archive-item list-group-item-action" to="/doe-advisories">DOE Advisories</Link>
				</div>
			</div>
		</div>
	)
}
export default AnnouncementSidebar;