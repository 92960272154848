import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import HeroHome from "../../../components/Hero/HeroHome/HeroHome";
import MainMenu from "../../../components/MainMenu/MainMenu";
import FAQs from "../../../components/HomeComponents/FAQs";
import LatestNews from "../../../components/HomeComponents/LatestNews";
import SpecialAdvisories from "../../../components/HomeComponents/SpecialAdvisories";
import LatestAdvisories from "../../../components/HomeComponents/LatestAdvisories";
import LatestCelcorLive from "../../../components/HomeComponents/LatestCelcorLive";
import Particles from "react-tsparticles";
import ParticlesSetup from '../../../components/AboutComponents/js/particlesjs-config.json';
import particlesInit from "../../../components/AboutComponents/js/particles.init";

function Home() {

    useEffect (() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			document.title = "CELCOR | Cabanatuan Electric Corporation";
    }, []);

    return (
		<div className="">
			<Particles className="particlesdiv" id="tsparticles" init={particlesInit}	options={ParticlesSetup}/>
			<div className="Home">
				<Navbar />
				<HeroHome />
				<MainMenu />
				<div className="container-about">
				<div className="container pt-2 relate">
				<div className="row">
					<div className="col-lg-7 col-xl-7 col-md-7 col-sm-12 col-12 mb-0">
						<LatestNews />
					</div>
					<div className="col-lg-5 col-xl-5 col-md-5 col-sm-12 col-12">
						<SpecialAdvisories />
						<LatestAdvisories />
						<LatestCelcorLive />
						<FAQs />
					</div>
				</div>
			</div>
			</div>
			</div>
		</div>
    );
}

export default Home;