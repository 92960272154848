import React, { useEffect } from "react";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import MainMenu from "./../../components/MainMenu/MainMenu";
import Navbar from "./../../components/Navbar/Navbar";
import ContentComponent from "../../components/NewsAdvisoriesComponents/ScheduledPowerInterruption";

function ScheduledPowerInterruption () {
	
  useEffect (() => {
    document.title = "CELCOR | Scheduled Power Interruption";
  });
  
  return (
    <div className="Home">
      <Navbar />
      <HeroNews title="Scheduled Power Interruption" />
      <MainMenu />
      <ContentComponent />
    </div>
  );

}

export default ScheduledPowerInterruption;
