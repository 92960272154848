import React, { useEffect } from "react";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import ContentComponent from "../../components/NewsAdvisoriesComponents/Advisories";

function Advisories() {
    useEffect(() => {

        document.title = "CELCOR | Advisories";
    });
    
    return (
        <div className="Advisories">
            <Navbar />
            <HeroNews title="Advisories"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );
}

export default Advisories;