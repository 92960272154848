import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroBillsPayment from "../../components/Hero/HeroBillsPayment/HeroBillsPayment";
import ContentComponent from '../../components/BillsPaymentComponents/CollectionOffices';

function CollectionOffices() {
    
    useEffect(() => {
        document.title = "CELCOR | Collection Offices";
    },[]);
    
    return (
        <div className="CollectionOffices">
            <Navbar />
            <HeroBillsPayment title="Collection Offices"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default CollectionOffices;