import React, { useEffect } from "react";
import Navbar from "./../../components/Navbar/Navbar";
import MainMenu from "./../../components/MainMenu/MainMenu";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import ContentComponent from "../../components/NewsAdvisoriesComponents/ServiceAdvisories";
import ContentComponent2 from "../../components/NewsAdvisoriesComponents/Testt";

function ServiceAdvisories () {

  useEffect(() => {
    document.title = "CELCOR | Service Advisories";
  });

  return (
    <div className="Home">
      <Navbar />
      <HeroNews title="Service Advisories" />
      <MainMenu />
      <ContentComponent />
    </div>
  );
}

export default ServiceAdvisories;
