import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroAbout from '../../components/Hero/HeroAbout/HeroAbout';
import ContentComponent from '../../components/AboutComponents/Opportunities';

function Opportunities() {
    
    useEffect(() => {
        document.title = "CELCOR | Opportunities";
    },[],);
    
    return (
        <div className="Opportunities">
            <Navbar />
            <HeroAbout title="Opportunities"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default Opportunities;