import React, { Component } from "react";
import "./css/NewsAdvisoriesComponents.css";
import AnnouncementSidebar from "./AnnouncementSidebar";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

const pdf = {
	rulesToGovernTheILPofDistributionUtilities2010 :
		'Rules_to_Govern_the_ILP_of_Distribution_Utilities_2010.pdf',
	billingAndCompensation2013 :
		'Billing_and_Compensation_2013.pdf',
	amendedRulesToGovernTheILPofDistributionUtilities2015 :
		'Amended_Rules_to_Govern_the_ILP_of_Distribution_Utilities_2015.pdf',
	clarificationOnActivationOfILP2019 :
		'Clarification_on_Activation_of_ILP_2019.pdf',
}

function DOEAdvisories() {

    return (
        <div className="container-fluid relate container-newsList pt-5 advisories-bodystyle" style={{backgroundImage: 'none'}}>
        <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup} />
            <div className="container px-0 pt-2">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <AnnouncementSidebar />
                    </div>
                    <div className="col-sm-12 col-md-8 pb-5">
                        <div className="row mb-4">
                            <div className="col-sm-12 col-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'justify'}}>
                                In line with the Department of Energy's (DOE) initiatives to ensure the security and reliability
                                of the supply of electric power and avert the impact of any unexpected power plant outages,
                                the DOE encourages the implementation of the Interruptible Load Program (ILP) in the
                                franchise areas of the different distribution utilities as well as directly connected customers
                                through the National Grid Corporation of the Philippines (NGCP).
                                <br /><br />
                                ILP is a voluntary, demand-side management program that allows customers to operate their
                                generating sets and collectively reduce electricity drawn from the grid when power
                                interruptions are imminent to ration limited power supply. Participating customers will be
                                compensated for the de-loaded kWh for the period covered by ILP.
                                <br /><br />
                                ILP is open to captive, contestable, economic, and freeport zones, and directly connected
                                customers.
                            </div>
                        </div>
                        <div className="row mb-3 ml-1">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">Rules to Govern the ILP of Distribution Utilities (2010)</div>
                            <div className="col-md-4 col-lg-4 col-xl-4"><a href={`https://restapi.celcor.com.ph/files/announcements/${pdf.rulesToGovernTheILPofDistributionUtilities2010}`} className="download-pdf-link" target="_blank"><button type="button" className="btn btn-download-pdf">Download PDF</button></a></div>
                        </div>
                        <div className="row mb-3 ml-1">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">Billing and Compensation (2013)</div>
                            <div className="col-md-4 col-lg-4 col-xl-4"><a href={`https://restapi.celcor.com.ph/files/announcements/${pdf.billingAndCompensation2013}`} className="download-pdf-link" target="_blank"><button type="button" className="btn btn-download-pdf">Download PDF</button></a></div>
                        </div>
                        <div className="row mb-3 ml-1">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">Amended Rules to Govern the ILP of Distribution Utilities (2015)</div>
                            <div className="col-md-4 col-lg-4 col-xl-4"><a href={`https://restapi.celcor.com.ph/files/announcements/${pdf.amendedRulesToGovernTheILPofDistributionUtilities2015}`} className="download-pdf-link" target="_blank"><button type="button" className="btn btn-download-pdf">Download PDF</button></a></div>
                        </div>
                        <div className="row ml-1">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">Clarification on Activation of ILP (2019)</div>
                            <div className="col-md-4 col-lg-4 col-xl-4"><a href={`https://restapi.celcor.com.ph/files/announcements/${pdf.clarificationOnActivationOfILP2019}`} className="download-pdf-link" target="_blank"><button type="button" className="btn btn-download-pdf">Download PDF</button></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
	
}
export default DOEAdvisories;