import React from 'react';


function SC01(props) {

    const ownerclicked = (e) =>
    {
        props.goToStep(7);      
    }

    const businessclicked = (e) =>
    {
        props.goToStep(11);
    }

  return (
    <>
    <h6 className="text-center">To whom does your Account named after?</h6>
    <div className="row pt-3 mb-5">
        <div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
            <button type="button" className="btn btn-cta d-block mx-auto mt-3" onClick={ownerclicked}>Owner's Name</button>
        </div>
        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
            <button type="button" className="btn btn-cta d-block mx-auto mt-3" onClick={businessclicked}>Business Name</button>
        </div>
        <div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>
</>
  )
}

export default SC01