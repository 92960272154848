import React, {useState, useEffect} from 'react'
import { Navigate } from 'react-router-dom';
import AccountSettingsForm from '../../../components/Account/AccountSettings/AccountSettings';
import Navbar from '../../../components/Navbar/Navbar';

function AccountSettings() {

  const [state, setState] = useState ({
    redirect: false,
    firstName: "",
    lastName: "",
    middleName: "",
    account_number: "",
    mobile_number: "",
    email: ""
  });

  useEffect (() => {
    document.title = "CELCOR | Manage Account";

    if (localStorage.getItem("userData"))
    {
    }
    else
    {
      setState(prevState => ({
        ...prevState,
        redirect: true
      }));
    }
  }, [])

  if (state.redirect)
  {
    return <Navigate to={"/login"} />
  }

  return (
    <>
    <Navbar />
    <div className="container-fluid h-100">
      <div className="row h-100">
        <main className="col">
          <AccountSettingsForm />
        </main>
      </div>
    </div>
    </>
  )
}

export default AccountSettings