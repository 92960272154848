import React from "react";
import "./css/NewsAdvisoriesComponents.css";
import AnnouncementSidebar from "./AnnouncementSidebar";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

function Announcements () {
    return (
    <div className="container-fluid relate container-newsList pt-5 advisories-bodystyle" style={{backgroundImage: 'none'}}>
    <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
        <div className="container px-0 pt-2">
            <div className="row">
                <div className="col-sm-12 col-md-4">
                    <AnnouncementSidebar />
                </div>
            </div>
        </div>
    </div>
    );
}
export default Announcements;