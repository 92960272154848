import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroAbout from '../../components/Hero/HeroAbout/HeroAbout';
import ContentComponent from '../../components/AboutComponents/History';

function History() {

    useEffect(() => {
        document.title = "CELCOR | History";
    },[],);
    
    return (
        <div className="History">
            <Navbar />
            <HeroAbout title="History"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default History;