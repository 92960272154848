import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/CELCOR seeks assistance from Barangay for Lifeline Rate Subsidy Application 1.jpg";
import Pic2 from "./image/CELCOR seeks assistance from Barangay for Lifeline Rate Subsidy Application 2.jpg";

function Content () {
    return (
        <React.Fragment>
            <div className="text-justify">
                    Cabanatuan Electric Corporation visited different Barangays in Cabanatuan City 
                         to seek assistance in encouraging the beneficiaries of the Lifeline Rate Subsidy to apply. Tarpaulins 
                         were handed out to be displayed providing basic information.
                         <br /><br />
                         Members of Pantawid Pamilyang Pilipino Program (4Ps) and Indigent Residents 
                         who consumes less than 75 kiloWatt monthly are the beneficiaries of this 
                         program as amended by Republic Act no. 11552. The primary requirements for a 
                         4Ps member includes Application Form from CELCOR, Copy of Latest CELCOR Bill, 
                         and Government Issued ID. While Indigent Residents must bring Certification from 
                         City Social Welfare and Development Office (Cabanatuan City Hall) in addition to 
                         the other requirements.
                         <br /><br />
                         Last April 2023, ERC met with Distribution Utilities to discuss the Information 
                         Dissemination of the program. Visiting each Barangay is one of CELCOR's 
                         Information, Education, and Communication (IEC) Campaign.
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic1} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic2} />
					</div>
				</div>
        </React.Fragment>
    );

}

export default Content;