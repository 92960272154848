import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroBillsPayment from "../../components/Hero/HeroBillsPayment/HeroBillsPayment";
import ContentComponent from '../../components/BillsPaymentComponents/LocalBanks';

function LocalBanks() {

    useEffect(() => {
        document.title = "CELCOR | Local Banks";
    },[]);
    
    return (
        <div className="LocalBanks">
            <Navbar />
            <HeroBillsPayment title="Local Banks"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default LocalBanks;