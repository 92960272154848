import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroServices from "../../components/Hero/HeroServices/HeroServices";
import ContentComponent from '../../components/ServicesComponents/PBService';

function PBService() {
    useEffect(() => {

        document.title = "CELCOR | Paperless Billing Service";
    }, []);
    
    return (
        <div className="PBService">
            <Navbar />
            <HeroServices title="Paperless Billing Service"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default PBService;