import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/CELCOR Prepares For The Lifeline Rate Subsidy 1.jpg";
import Pic2 from "./image/CELCOR Prepares For The Lifeline Rate Subsidy 2.jpg";
import Pic3 from "./image/CELCOR Prepares For The Lifeline Rate Subsidy 3.jpg";

function Content () {
    return (
        <React.Fragment>
				<div className="text-justify">
					June 17, 2023—Cabanatuan Electric Corporation (CELCOR) Department Heads and Officials held a meeting in preparation for the new rules and regulations 
					regarding the Lifeline Rate Subsidy for the Pantawid Pamilyang Pilipino Program (4Ps) and Marginalized End-Users.
					<br /><br />
					In accordance to Republic Act no. 11552 which extended the Lifeline Rate Subsidy grant up to 50 years, Energy Regulatory Commission (ERC), Department of Energy (DOE)  
					in coordination with Department of Social Welfare and Development (DSWD) and Philippine Statistics Authority (PSA) published the Implementing Rules and Regulations 
					last October 2022 which provides information on the new implementing guidelines about the subsidy.
					<br /><br />
					Furthermore, CELCOR just recenty attended a signing of Data Sharing Agreement with ERC along with other Private Distribution Utilities.
					<br /><br />
					DUs, inluding CELCOR, are required to encourage eligible beneficiaries to register and submit applications for the Lifeline rate Subsidy that was addressed at the said meeting.
					<br /><br />
					The beginning of the application is set to start on July 3, 2023.
                    <br /><br />
					(Photo taken: June 17, 2023 by J. Legaspi)
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic1} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic2} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic3} />
					</div>
				</div>
			</React.Fragment>
    );
}

export default Content;