import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import "./css/NewsAdvisoriesComponents.css";
import loader from "./../../loader.svg";
import moment from "moment";
import config from 'react-global-configuration';
import AdvisorySidebarArchived from "./AdvisorySidebarArchived";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";
import { useStateWithCallbackLazy } from "use-state-with-callback";

const loaderStyle = {
	height: "150px",
	width: "150px"
};

function ServiceAdvisories () {

	const [state, setState] = useStateWithCallbackLazy ({
		latestNews: [],
		limit: 3,
		loader: true,
		maxlimit: 0,
		ismaxed: false
	});

	useEffect (() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		axios
			.all([
				axios.get(config.get('apiUrl') + "/Advisories/getLatestAdvisories", {
					params: {
						limit: 3
					}
				}),
				axios.get(config.get('apiUrl') + "/Advisories/advisoriescount")
			])
			.then(
				axios.spread((...responses) => {
					setState(prevState => ({
						...prevState,
						latestNews: responses[0].data,
						maxlimit: parseInt(responses[1].data),
						loader: false
					}), (currentState) => {
						if(Object.keys(currentState.latestNews).length === currentState.maxlimit)
						setState(prevState => ({
							...prevState,
							ismaxed: true
						}));
					});
				})
			)
			.catch(ex => {

			});
	},[],);

	const filterlist = (currentState) =>
	{
		axios
			.get(config.get('apiUrl') + "/Advisories/getLatestAdvisories", {
				params: {
					limit: currentState.limit
				}
			})
			.then(response => {
				setState(prevState => ({
					...prevState,
					latestNews: response.data,
					loader: false
				}), (currentState) => {
					if(Object.keys(currentState.latestNews).length === currentState.maxlimit)
					setState(prevState => ({
						...prevState,
						ismaxed: true
					}));
				});
			})
			.catch(ex => {
				
			})
	}

	return (
		<>
			<div className="container-fluid relate container-newsList pt-5" style={{backgroundImage: 'none'}}>
			<Particles className="particlesdiv"	id="tsparticles" init={ParticlesInit}	options={ParticlesSetup}/>
			<div className="container px-0 pt-2">
				<div className="row">
				<div className="col-sm-12 col-md-4">
					<AdvisorySidebarArchived />
				</div>
				<div className="col-sm-12 col-md-8 mb-3">
					{state.latestNews && Object.keys(state.latestNews).length > 0 ? (
						<React.Fragment>
							<h3 className="gradient-text mb-3 text-center">LATEST SERVICE INTERRUPTION ADVISORIES</h3><br />
							{state.latestNews.map((item, idx) => {
								return (
									<React.Fragment>
										<div className="row advisories-list-with-category-caller">
											<div className="col-3 col-3 col-md-2 col-lg-2 col-xl-2">
												<div className="centered-text-container-big">
													<div className={`centered-text-big color-light-black ${item.Identifier}`} >
														<p className="centered-text">
															{moment(item.Date).format('MMM').toUpperCase()}<br />
															{moment(item.Date).format('DD')}
														</p>
													</div>
												</div>
											</div>
											<div className="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 text-info">
												<strong>{item.Category}</strong><br />
												<strong>DURATION : </strong>
												<span>{item.Duration}</span><br />
												<strong>REASON : </strong>
												<span>{item.Reason}</span>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<strong className="hyperlink-no-cursor">AFFECTED AREAS :</strong><br />
												<span className="color-light-black text-align-justify">
													{item.AffectedAreas.split('\n').map(i => {
														return <span>{i}<br /></span>
													})}
													<br />
												</span>
											</div>
										</div>
									</React.Fragment>
								)
							})}
							{state.ismaxed === false ? (
							<div className="row mb-3">
								<div className="col-md-4 col-lg-4"></div>
								<div className="col-sm-12 col-12 col-md-4 col-lg-4 col-xl-4">
								<button style={{visibility: 'visible'}} type="button" className="btn btn-download-pdf" onClick={(e) => {
									setState(prevState => (
										{
											...prevState,
											limit: prevState.limit + 3,
											loader: true
										}
									), (currentState) => {
										filterlist(currentState);
									});
								}}>Show More</button>
								</div>
								<div className="col-md-4 col-lg-4"></div>
							</div>
							) : (
							<React.Fragment />
							)}
							</React.Fragment>
							) : state.loader === true ? "" : <h3 className="gradient-text text-center">NO AVAILABLE DATA</h3>}	
						<div className="row">
						<div className="col-12 text-center">
							{state.loader && state.loader === true ? (
								<img
									src={loader}
									style={loaderStyle}
								/> 
							) : ""}
						</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</>
	);
}
export default ServiceAdvisories;