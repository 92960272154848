import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/CELCOR joins Feeding Program for underweight children in Barangay San Juan Accfa 1.jpg";
import Pic2 from "./image/CELCOR joins Feeding Program for underweight children in Barangay San Juan Accfa 2.jpg";
import Pic3 from "./image/CELCOR joins Feeding Program for underweight children in Barangay San Juan Accfa 3.jpg";
import Pic4 from "./image/CELCOR joins Feeding Program for underweight children in Barangay San Juan Accfa 1.jpg";
import Pic5 from "./image/CELCOR joins Feeding Program for underweight children in Barangay San Juan Accfa 5.jpg";
import Pic6 from "./image/CELCOR joins Feeding Program for underweight children in Barangay San Juan Accfa 6.jpg";
import Pic7 from "./image/CELCOR joins Feeding Program for underweight children in Barangay San Juan Accfa 7.jpg";

function Content () {
    return (
        <React.Fragment>
            <div className="text-justify">
                    July 10, 2024- Cabanatuan Electric Corporation joined the Local Government's “Adopt-a-Barangay” 
                    Supplemental Feeding Program for the underweight/stunted 
                    children from ages 1 to 7 years old in Barangay San Juan Accfa, Cabanatuan City.
					<br /><br />
					Together with representatives from the City Health Office and Barangay Officials, 
                    Ms. Vilma Yatco, CELCOR's Human Resource and Development Head, oversaw 
                    the event's kickoff and distributed nutrious meals and dental kits to 50 selected children.
					<br /><br />
					The event aims to fight malnutrition and reduce the number of underweight 
                    children in Cabanatuan City. The program is to run for three (3) months every 
                    Monday and Friday starting July 10, 2023 at Barangay San Juan Accfa covered court.
					<br /><br />
					CELCOR has been participating in Feeding Programs since 2013. It only came to a 
                    halt due to the pandemic. Barangay Bitas was the first Feeding Program 
                    beneficiary of CELCOR. They also previously held programs in different Barangays 
                    such as San Josef Sur, Aduas Centro, and DS Garcia.
                    <br /><br />
					(Photo taken: July 10, 2023 by J. Calitis)
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic1} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic2} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic3} />
					</div>
				</div>
                <div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic4} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic5} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic6} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic7} />
					</div>
				</div>
        </React.Fragment>
    );
}
export default Content;