import React from 'react';
import Assistance from "./Assistance";

function LastPage(props) {
  return (
    <>
    <div className="container">
					<p className="font-weight-bold reg-no-margin">Transaction Acknowledgement</p>
					<div className="reg-no-margin">
						<p className="reg-no-margin">Thank you for registering to CELCOR Online Services.</p>
						<ul className="reg-no-margin">
							<li>Your registration has been forwarded to CELCOR for processing. You will receive an email/SMS notification once your registration has been approved.</li>
							<li>Once approved, you will receive an activation link via email. Upon successful account validation, you may immediately inquire your CELCOR account and enroll to paperless billing.</li>
						</ul>
					</div>
					
					<span className="reg-header reg-no-margin">Account Being Registered</span>
					<hr className="hr-no-margin" />
					<ul className="reg-ul below-hr">
						<li><span className="reg-ul-span font-weight-bold">Account Number</span><span>{props.getState('accountNo')}</span></li>
						<li><span className="reg-ul-span font-weight-bold">Name</span><span>{props.getState('name')}</span></li>
					</ul>

					<span className="reg-header reg-no-margin">Additional Information</span>
					<hr className="hr-no-margin" />
					<ul className="reg-ul below-hr">
						<li><span className="reg-ul-span font-weight-bold">Total kWh</span><span>{props.getState('kwh')}</span></li>
						<li><span className="reg-ul-span font-weight-bold">Due Date</span><span>{props.getState('duedate')}</span></li>
						<li><span className="reg-ul-span font-weight-bold">Email Address</span><span>{props.getState('email')}</span></li>
						<li><span className="reg-ul-span font-weight-bold">Mobile Number</span><span>{props.getState('phone')}</span></li>
						<li><span className="reg-ul-span font-weight-bold">Subscribe to Paperless Bill</span><span>{props.getState('paperlessBill')}</span></li>
					</ul>
					{props.getState('authLname') !== '' && props.getState('authFname') !== '' ? (
						<React.Fragment>
							<span className="reg-header reg-no-margin">Authorized Personnel</span>
							<hr className="hr-no-margin" />
							<ul className="reg-ul below-hr">
								<li><span className="reg-ul-span font-weight-bold">Last Name</span><span>{props.getState('authLname')}</span></li>
								<li><span className="reg-ul-span font-weight-bold">First Name</span><span>{props.getState('authFname')}</span></li>
								<li><span className="reg-ul-span font-weight-bold">Middle Name</span><span>{props.getState('authMname')}</span></li>
							</ul>
						</React.Fragment>
					) : ''}
					<span className="reg-header reg-no-margin">Uploaded Documents</span>
					<hr className="hr-no-margin" />
					<ul className="below-hr">
						{props.getState('document1') !== null ? (
							<React.Fragment>
								<li>Primary ID/Secondary IDs</li>
							</React.Fragment>
						) : ''}
						{props.getState('document2') !== null ? (
							<React.Fragment>
								<li>Authorization Letter</li>
							</React.Fragment>
						) : ''}
					</ul>
					<hr className="hr-no-margin" />
					<Assistance />
				</div>
    </>
  )
}

export default LastPage