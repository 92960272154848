import React from "react";
import { Link } from "react-router-dom";
import "./css/FAQs.css";

function FAQs() {

    return(
        <React.Fragment>
            <div id="faqs" className="container-fluid relate faqcontainer">
                <div className="card gradient-bg shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <div className="text-primary h5 font-weight-bold"><b>FREQUENTLY ASKED QUESTIONS</b></div>
                    </div>
                    <div className="card-body pb-5 faqs-body card-custom-border">
                        <div className="bottom-link blog_card_content">
                            <Link className="hyperlink" to={`/frequently-asked-questions`}><h6>Read Here &gt;&gt;</h6></Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FAQs;