import React, {useState, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import Navbar from '../../../components/Navbar/Navbar';
import AccountAddAccount from '../../../components/Account/AddAccount/AddAccount';

function AddAccount() {

  const [state, setState] = useState ({
    redirect: false
  });

  useEffect(() => {
    document.title = "CELCOR | Add Account";
  }, [])

  if (state.redirect)
  {
    return <Navigate to ={"/login"} />
  }

  return (
    <>
    <Navbar />
    <div className="container-fluid h-100">
		  <div className="row h-100">
			<main className="col">
				<AccountAddAccount />
			</main>
		  </div>
		</div>
    </>
  )
}

export default AddAccount