import React from "react";
import "../../../InfomercialsComponents/css/InfomercialsComponents.css";
const link = `https://www.youtube.com/embed/QH7wCv50eVA`;

function Content () {
    return(
        <React.Fragment>
            <div className="text-justify">
					Employees of Cabanatuan Electric Corporation in collaboration with the Management distributed 200 gift wrapped toys
					to children as a part of its Annual Christmas Program called 'Sparks of Joy, Share a Toy'.
					<br /><br />
					Children from ages three to nine (3-9) years accompanied by their guardians as they visit CELCOR's Main Office in Barangay
					Bitas recived toys for sports, music, education and enjoyment.
					<br /><br />
					The Sparks of Joy, Share a toy program was first launched in 2019 and has been continued every Christmas Season.
					<br /><br />
				</div>
				<div className="youtube-vid" style={{marginBottom: '1rem'}}>
					<div className="container-fluid">
						<iframe src={link} className="img-thumbnail"
							frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
						</iframe>
					</div>
				</div>
        </React.Fragment>
    )
	
}

export default Content;