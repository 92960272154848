import React from 'react'

function SC02(props) {

  const prevClicked = (e) =>
  {
    props.goToStep(6);
  }

  const noclicked = (e) =>
  {
    props.goToStep(8);
  }

  const yesclicked = (e) =>
  {
    props.goToStep(9);
  }

  return (
    <>
    <h6 className="text-center">Is the account named after you?</h6>
				<div className="row pt-3">
					<div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
					<div className="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
						<button type="button" className="btn btn-cta d-block mx-auto mt-3" onClick={noclicked}>No</button>
					</div>
					<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
					<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
						<button type="button" className="btn btn-cta d-block mx-auto mt-3" onClick={yesclicked}>Yes</button>
					</div>
					<div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
				</div>
				<div className="row mt-3">
					<div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<button className="btn btn-registration" onClick={prevClicked}>
							{props.prevIcon} BACK
						</button>
					</div>
					<div className="col-md-9 col-lg-9 col-xl-9">
					</div>
				</div>
    </>
  )
}

export default SC02