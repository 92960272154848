import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroServices from "../../components/Hero/HeroServices/HeroServices";
import ContentComponent from '../../components/ServicesComponents/LifelineRate';

function LifelineRate() {
    useEffect(() => {
        document.title = "CELCOR | Lifeline Rate";
    }, []);
    
    return (
        <div className="LifelineRate">
            <Navbar />
            <HeroServices title="Lifeline Rate"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default LifelineRate;