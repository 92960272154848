import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroLogin from "../../components/Hero/HeroLogin/HeroLogin";
import MainMenu from "../../components/MainMenu/MainMenu";
import RegisterForm from "../../components/RegistrationWizard/RegistrationWizard";

function Register() {

    useEffect(() =>{
        document.title="CELCOR | Register";
    });
    
    return (
        <div className="Register">
         <Navbar />
         <HeroLogin title="Register" />
         <MainMenu />
         <RegisterForm />
        </div>
    );
}
export default Register;