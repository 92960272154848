import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import TermsCondition from "../../components/TermsConditions/TermsConditions";

function TermsConditions () {
	
  useEffect (() => {
    document.title = "CELCOR | Terms and Conditions";	
  })

    return (
      <div className="Contact">
        <Navbar />
		<div className="container-fluid h-100">
          <div className="row h-100">
            <main className="col container-TNC">
             	<TermsCondition />
            </main>
          </div>
        </div>
      </div>
    );
}

export default TermsConditions;
