import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import "./css/NewsArchive.css";
import SectionTitle from "./../SectionTitle/SectionTitle";
import "react-sharingbuttons/dist/main.css";
import config, { set } from 'react-global-configuration';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import particlesInit from "../AboutComponents/js/particles.init";

function NewsArchive () {

    const[state, setState] = useState ({
			archiveName: [],
			latestNews: [],
			redirect: false,
			currentName: "",
    });	

    const navigateTo = (link) =>
    {
			if(link === state.currentName) return;
			setState(prevState => ({
				...prevState,
				currentName: link
			}));
			axios
					.get(config.get('apiUrl') + "/News/getArchive", {
						params: {
							buwan: link
						}
					})
					.then(response => {
						setState(prevState => ({
							...prevState,
							latestNews: response.data
						}));
					})
    }

    useEffect (() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });

			const url_string = window.location.href;
			const spl = url_string.split("/");
			var x = 0;
			for(x = 0; x < spl.length; x++)
			{
				if(spl[x] === "news-archive")
				{
					x++;
					break;
				}
			}
			const param = spl[x];
			setState(prevState => ({
				...prevState,
				currentName: param
			}));
			axios
					.all([
						axios.get(config.get('apiUrl') + "/News/getArchive", {
							params: {
								buwan: param
							}
						}),
						axios.get(config.get('apiUrl') + "/News/archivename")
					])
					.then(
						axios.spread((...responses) => {
							setState(prevState => ({
								...prevState,
								latestNews: responses[0].data,
								archiveName: responses[1].data
							}));
						})
					)
		}, []);

    
    if (state.redirect)
    {
			return <Navigate to = "/" />
    }

    return (
		<div className="container-fluid relate container-newsList pt-5" style={{backgroundImage: 'none'}}>
		<Particles className="particlesdiv" id="tsparticles" init={particlesInit}	options={ParticlesSetup}/>
		<div className="container px-0 pt-">
			<SectionTitle title="News Archive" />
			<div className="row">
			<div className="col-sm-12 col-md-3">
				<div className="card gradient-bg shadow mb-2">
					<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between consumption-header">
						<div className="text-primary h5">News Archive</div>
					</div>
					<div className="list-group">
						{state.archiveName ? (
							state.archiveName
							.slice(0, Object.keys(state.archiveName).length)
							.map(item => {
								return <Link className="hyperlink list-group-item archive-item list-group-item-action" to={"/news-archive/" + item.buwan} onClick={(e) => navigateTo(item.buwan)}>{item.buwan.replace(/-/g, ' ')}</Link>
							})
						) : ""}
					</div>
				</div>
			</div>
			<div className="col-sm-12 col-md-8 box-shadow newsCardMain">
				{state.latestNews ? (
				state.latestNews
				.slice(0, Object.keys(state.latestNews).length)
				.map(item => {
					 return (
					 <div className="row">
						 <div className="col-sm-12 col-sm-12">
							<Link className="hyperlink hyperlink-nocursor hover-underline" to={`/news/${item.Link}`}><h5 title={item.Category}>{item.Title}</h5>
							</Link>
							<hr />
						 </div>
					 </div>
					 )
				})
				) : ""}
			</div>
			</div>
		</div>
		</div>
    );
}
export default NewsArchive;