import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/CELCOR joins Cabanatuan City Association of Barangay Captains Meeting to discuss Lifeline Rate - 1.JPG";
import Pic2 from "./image/CELCOR joins Cabanatuan City Association of Barangay Captains Meeting to discuss Lifeline Rate - 2.JPG";

function Content(){
	return(
    <React.Fragment>
      <div className="text-justify">
                  August 2, 2023—CELCOR's Corporate Communication Department joins the Cabanatuan City Association 
                  of Barangay Captains' Meeting to discuss the Lifeline Rate Subsidy.
        <br /><br />
        Christopher Duldulao, CELCOR's CCD Head took the lead in CELCOR's IEC Campaign regarding the new 
                  Implementing Rules and Regulations of the Lifeline Rate Subsidy. He also mentioned in the discussion 
                  that the nationwide implementation of the revised Lifeline Rate will be extended until September 1st 
                  due to the low turn-out of applicants according to ERC. In this regard, CELCOR sought the help of the 
                  Barangay Officials in disseminating the information to their constituents.
        <br /><br />
        CELCOR is continuously conducting its Information Campaign through barangay visitations, social media, 
                  Radio, TV, and CELCOR's official website.
        <br /><br />
                  <br />
                  CELCOR CCD Head C. Duldulao during the discussion of Lifeline Rate
        (Photo taken: August 2, 2023 by J. Calitis)
      </div>
      <div className="row">
        <div className="col-12">
          <img className="img-thumbnail img-fluid image" src={Pic1} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <img className="img-thumbnail img-fluid image" src={Pic2} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Content;