import React, { useState } from "react";
import './css/LatestAdvisories.css';
import moment from "moment";
import config from 'react-global-configuration';
import axios from "axios";

function SpecialAdvisories() {

    const [state, setState] = useState({
        adv: true
    });

    return(
        <React.Fragment>
             {state.adv && state.adv === true ? (
					<div className="container-fluid relate">
						<img src={`https://restapi.celcor.com.ph/files/imgs/special-advisory-1.jpg`} onError={() => setState({
                            adv: false
                        }) }
                        id="sp-adv" className="img-fluid sp-adv" />
					</div>
				) : ("")}
        </React.Fragment>
    );
}

export default SpecialAdvisories;