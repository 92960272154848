import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroInfomercials from "../../components/Hero/HeroInfomercials/HeroInfomercials";
import ContentComponent from '../../components/InfomercialsComponents/InfomercialsComponents';

function CustomerGuide() {

    useEffect(() => {
        document.title = "CELCOR | Customer Guide";
    });
    
    return (
        <div className="CustomerGuide">
            <Navbar />
            <HeroInfomercials title="Customer Guide"/>
            <MainMenu />
            <ContentComponent code="ccg" />
        </div>
    );

}
export default CustomerGuide;