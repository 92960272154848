import React from "react";
const link = `https://www.youtube.com/embed/aCunkJ_h4jU`;

function Content () {
    return(
        <React.Fragment>
            <div className="text-justify">
                What makes CELCOR, CELCOR?
                <br /><br />
                Watch this video to find out and join us as we celebrate Cabanatuan Electric Corporations'
                <br /><br />
                113TH ANNIVERSARY
                <br /><br /><br />
                #CELCOR<br />
                #EnergizingProgress<br />
                #Since1910<br />
                #113YearsInService
            </div>
            <div className="youtube-vid" style={{marginBottom: '1rem'}}>
                <div className="container-fluid">
                    <iframe src={link} className="img-thumbnail"
                        frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                    </iframe>
                </div>
            </div>
        </React.Fragment>
    )
	
}

export default Content;