import React, {useState, useEffect, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./subcomponents/css/Registration.css";
import "../AboutComponents/css/contentstyle.css"
import { Navigate } from "react-router-dom";
import StepWizard from "react-step-wizard";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from '../AboutComponents/js/particles.init';

import MainPage from "./subcomponents/MainPage";

import R01 from "./subcomponents/R01";
import R02 from "./subcomponents/R02";
import R03 from "./subcomponents/R03";
import R04 from "./subcomponents/R04";

import SC01 from "./subcomponents/SC01";
import SC02 from "./subcomponents/SC02";
import SC03 from "./subcomponents/SC03";
import SC04 from "./subcomponents/SC04";
import SC05 from "./subcomponents/SC05";
import SC06 from "./subcomponents/SC06";
import SC07 from "./subcomponents/SC07";

import LC01 from "./subcomponents/LC01";
import LC02 from "./subcomponents/LC02";

import LastPage from "./subcomponents/LastPage";

const next = <FontAwesomeIcon icon={faArrowRight} />;
const prev = <FontAwesomeIcon icon={faArrowLeft} />;

function RegistrationWizard (props){

		useEffect(() => {
			// window.scrollTo({ top: 0, behavior: 'smooth' });
		}, []);

    const [state, setState] = useState ({
			jsonData: {
				accountNo: '',
				name: '',
				rate: '',
				em: '',
				document1: null,
				document2: null,
				kwh: '',
				duedate: '',
				email: '',
				phone: '',
				authLname: '',
				authFname: '',
				authMname: '',
				paperlessBill: ''
			}
    });

    const handleState = (name, value) =>
    {
    if (name === 'em')
			setState(prevState => ({
					jsonData: {
						...prevState.jsonData,
						[name]: value,
						'email': value
					}
			}));
    else
			setState(prevState => ({
					jsonData: {
						...prevState.jsonData,
						[name]: value
					}
			}));
    }

    const getState = (name) =>
    {
			return state.jsonData[name];
    }

    const classType = (type) =>
    {
			switch(type.replace(" ", ""))
			{
			case "R":
				return "Residential";
			case "SC":
				return "Small Commercial";
			case "LC":
				return "Large Commercial";
			case "VL":
				return "Very-Large Commercial";
			case "I":
				return "Semi-Industrial";
			case "SI":
				return "Small Industrial";
			case "LI":
				return "Large Industrial";
			 case "CC":
				return "Hospital";
			default:
				return "";
			}
    }

    if (localStorage.getItem("userData")) {
			return <Navigate to={"/change-account"} />;
    }

    return (
			<>
			<div className="container-fluid relate bodystyle-new-register">
				<Particles className="particlesdiv"	id="tsparticles" init={ParticlesInit}	options={ParticlesSetup}/>
				<div className="container px-0 pt-5 pb-5">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
							<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
								<div className="card gradient-bg shadow">
									<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
										<div className="text-primary h5">REGISTRATION</div>
									</div>
									<div className="card-body">
										{state.jsonData.rate === '' ? (
											''
										) : (
											<div className="container">
												<ul className="reg-ul">
													<li><span className="reg-ul-span">Account No.</span><span className="reg-ul-span">:</span><span>{state.jsonData.accountNo}</span></li>
													<li><span className="reg-ul-span">Name</span><span className="reg-ul-span">:</span><span>{state.jsonData.name}</span></li>
													<li><span className="reg-ul-span">Classification</span><span className="reg-ul-span">:</span><span>{classType(state.jsonData.rate)}</span></li>
												</ul>
												<hr />
											</div>
										)}
										
										<StepWizard>
											<MainPage 	step='01' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Check Account Number' />
											<R01 		step='02' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Is the account named after you?' />
											<R02 		step='03' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='R01 - NO' /> 
											<R03 		step='04' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='R01 - YES (Requirements)' /> 
											<R04 		step='05' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Registration Submission' /> 
											<SC01 		step='06' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Owner or Business?' />
											<SC02 		step='07' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='SC01 - Owner (Is the account named after you?)' />
											<SC03 		step='08' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='SC02 - NO' />
											<SC04 		step='09' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='SC02 - YES (Requirements)' />
											<SC05 		step='10' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Registration Submission - Owner' /> 
											<SC06 		step='11' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='SC01 - Business (Requirements)' /> 
											<SC07 		step='12' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Registration Submission - Business' /> 
											<LC01 		step='13' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Other Business (Requirements)' /> 
											<LC02 		step='14' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Registration Submission' /> 
											<LastPage 	step='15' handleState={handleState} getState={getState} nextIcon={next} prevIcon={prev} comment='Registration Acknowledgement' /> 
										</StepWizard>
									</div>
								</div>
							</div>
						<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
					</div>
				</div>
			</div>
			</>
    );
}
export default RegistrationWizard;