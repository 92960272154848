import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroServices from "../../components/Hero/HeroServices/HeroServices";
import ContentComponent from '../../components/ServicesComponents/MeterApplication';

function MeterApplication() {
    useEffect(() => {

        document.title = "CELCOR | Meter Application";
    }, []);
    
    return (
        <div className="MeterApplication">
            <Navbar />
            <HeroServices title="Meter Application"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default MeterApplication;