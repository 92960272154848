import React, { Component } from "react";
import {Link} from 'react-router-dom';
import img1 from './image/PAPERLESS BILLING SERVICE-1.png';
import img2 from './image/PAPERLESS BILLING SERVICE-2.png';
import img3 from './image/PAPERLESS BILLING SERVICE-CLICK HERE.png';
import './css/ServicesComponents.css';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

function MeterApplication() {

    return (
			<React.Fragment>
			<div className="container-fluid relate">
				<Particles className="particlesdiv "
				id="tsparticles"
				init={ParticlesInit}
				options={ParticlesSetup}
				/>
				<div className="container px-0 pt-5 pb-5">
					<div className="card relate box-shadow card-contact">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<img src={img1} className="pb-column pb-left" />
								<Link to="/register" className="pb-column pb-right"><img src={img2} className="pb-column pb-right" /></Link>
								<div className="pbs-vid">
									<div className="container-fluid">
										<iframe src={`https://www.youtube.com/embed/9zP5RvRrWoU`}
											frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
										</iframe>
									</div>
								</div>
								<Link to="/contact-us"><img src={img3} className="about-new-img-pb" /></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
    );
}

export default MeterApplication;