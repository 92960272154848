import React from "react";
import "./css/HeroLogin.css";

function HeroLogin(props) {
  return (
	<React.Fragment>
		<div className="div-nothing"></div>
		<div>
			<div className="container-fluid container-hero-login text-center d-flex align-items-center justify-content-center">
			<p className="hero-title text-raised mt-5 hero-title">{props.title}</p>
			</div>
		</div>
	</React.Fragment>
  );
}

export default HeroLogin;
