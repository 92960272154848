import React, {useState, useEffect, useRef, useCallback} from 'react';
import './css/AccountConsumptionHistory.css';
import '../../AboutComponents/css/contentstyle.css';
import axios from 'axios';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { encrypt } from '../../../Common';
import loader from "../../../loader.svg";
import config from 'react-global-configuration';
import {Logo} from './base64';
import Chart from 'chart.js/auto';
import jsPDF from 'jspdf';
import Particles from "react-tsparticles";
import ParticlesSetup from '../../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from '../../AboutComponents/js/particles.init';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const BodyStyle = {
 paddingTop: "96px"
};

const border = {
 border: "3px solid #DCDBD9",
 marginBottom: "20px"
};

const loaderStyle = {
 height: "150px",
 width: "150px"
};

function AccountConsumptionHistory() {

  const [state, setState] = useStateWithCallbackLazy ({
    serverValidationError: null,
		serverValidationError2: null,
	  consumption: [],
	  ave: 0.0,
	  width: 0,
	  tblData: [],
  })

  const chartRef = useRef();

  useEffect (() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

		if(localStorage.getItem('userData'))
		{
			setState(prevState => ({...prevState, width: localStorage.getItem("x")}));
			const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
			const token = encrypt(localStorage.getItem("Token"));
			let formData = new FormData();
			formData.append("cust_id", userData[0].Id);
			formData.append("token", token);
			formData.append("idx", localStorage.getItem("idx"));

			setState(prevState => ({
				...prevState,
				serverValidationError: "Please wait..."
			}));
			
			axios
				.post(config.get('apiUrl') + "/Home/consumptionHistory", formData)
				.then(response => {
					if(response.data == "0")
					{
						setState(prevState => ({
							...prevState,
							serverValidationError: "Authentication Failed, please log out and log in again."
						}));
					}
					if(response.data == "-2")
					{
						setState(prevState => ({
							...prevState,
							serverValidationError: "Error getting Consumption history! Please Contact Technical Support."
						}));
					}
					else
					{
						const resp = response.data;
						
						if(response.data[0].kWh == null)
						{
							setState(prevState => ({
								...prevState,
								serverValidationError: "Updating consumption history. This might take some time, refresh your browser to check if consumption history is already updated"
							}));
						}
						else
						{
							const labels = [];
							const datas = [];
							const bg = [];
							const tblData = [];
							resp
								.map(item => {
									var r = Math.floor((Math.random() * 187) + 1);
									var g = Math.floor((Math.random() * 187) + 1);
									var b = Math.floor((Math.random() * 187) + 1);
									var a = 0.80;
									var adjustRemarks = item.Remarks;
									var billMonth = moment(item.BillMonth).format("MMM YY");
									var billMonthh = moment(item.BillMonth).format("MMMM YYYY");

									labels.push(billMonth);
									datas.push(parseInt(item.kWh.replace(/,/g, '')));
									bg.push("rgba(" +r +", " +g +", " +b +", " +a +")");
									tblData.push({
										periodcovered: moment(item.PeriodFrom).format("MMM DD, YYYY") + " to " +moment(item.PeriodTo).format("MMM DD, YYYY"),
										billmonth: billMonthh,
										kwh: item.kWh,
										amount: `\u20B1${item.BillAmount}`
									});
								});
								setState(prevState => ({
									...prevState,
									consumption: resp,
									tblData: tblData,
									serverValidationError: null
								}), () => {
									var canvas = chartRef.current;
									var ctx = canvas.getContext('2d');
									
									var gradient = ctx.createLinearGradient(0, 0, 0, 400);
									gradient.addColorStop(0, '#1aa5bc');   
									gradient.addColorStop(1, '#92d689');
									let consumptionChart = {
										type: "bar",
										data: {
											labels: labels,
											datasets: [
												{
													label: "",
													data: datas,
													backgroundColor: gradient
												}
											]
										},
										options: {
											layout: {
												padding: {
													left: 0,
													right: 1,
													top: 0,
													bottom: 0
												}
											},
											plugins: {
												legend: {
													display: false
												}
											},
											scales: {
												x: {
													title: {
														display: true,
														text: 'Bill Month',
														font: {
															weight: 'bold',
															size: 13
														}
													}
												},
												y: {
													title: {
														display: true,
														text: 'kwh Used',
														font: {
															weight: 'bold',
															size: 13
														}
													}
												},
												yAxes: [{
													ticks: {
														beginAtZero: true
													}
												}]
											}
										}
								}
								new Chart(ctx, consumptionChart);
							});
						}
					}
				})
				.catch(error => {
					setState(prevState => ({
						...prevState,
						serverValidationError: "Error getting Consumption History! Please Contact Technical Support."
					}));
				})
		}
  }, [])

	const handlePrint = (e) =>
  {
		if(typeof jsPDF !== "undefined")
	  {
		  var doc = new jsPDF({
			  orientation: 'landscape',
			  format: 'letter'
		  });
		  
		  //region Image
		  doc.addImage(Logo, 'JPEG', doc.internal.pageSize.width / 3.25, 8, 90, 12.5);
		  //endregion
		  
		  //region account information placeholder
		  doc.setFont('courier', 'bold');
		  // doc.setFontType('bold');
		  doc.setFontSize(14);
		  doc.text('  Account Name :', 20, 26.75);
		  doc.text('       Address :', 20, 32.5);
		  doc.text('Account Number :', 20, 38.5);
		  //endregion

		  //region account information value
		  /* doc.setFont('helvetica'); */
		  /* doc.setFontType('normal'); */
		  doc.setFontSize(13);
		  doc.text(state.consumption[0].Name, 70, 27);
		  doc.text(state.consumption[0].Address, 70, 33);
		  //endregion
		  
		  /* doc.setFontType('bold'); */
		  doc.text(state.consumption[0].AccountNo, 70, 38.75);
		  
		  //region Consumption History header

		  doc.setFont('courier');
		  doc.setFontSize(20);
		  doc.text('Consumption History [' +(state.consumption[0].kWh == null ? 'null' : moment(state.consumption[0].PeriodFrom).format("MMM DD, YYYY") +' to ' +moment(state.consumption[Object.keys(state.consumption).length - 1].PeriodTo).format("MMM DD,YYYY")) +']', doc.internal.pageSize.width / 2, 47, null, null, 'center');
		  //endregion
		  doc.setDrawColor('gray');
	
		  doc.setLineWidth(0.8);
		  //x1, y1, x2, y2
		  doc.line(20, 53, 255, 53);
		  
		  //region consumption table header
		  doc.setFont('courier');
		  doc.setLineWidth(0.4);
		  doc.setFontSize(15);
		  
		  doc.text('Bill Month', 23, 59);
		  doc.text('Period Covered', 73, 59);
		  doc.text('kWh Used', 153, 59);
		  doc.text('Bill Amount', 205, 59);
	
		  //table header line
		  doc.line(20, 62, 255, 62);
		  
		  //region consumption data
		  var y = 68;
		  doc.setFont('helvetica', 'normal');
		  // doc.setFontType('normal');
		  doc.setFontSize(13);
		  state.consumption.forEach(item => {
			 var billMonth = moment(item.BillMonth).format("MMMM YYYY");
			 doc.text(billMonth, 23, y);
			 doc.text(moment(item.PeriodFrom).format("MMM DD,YYYY") + " to " +moment(item.PeriodTo).format("MMM DD,YYYY"), 73, y);
			 doc.text(item.kWh, 153, y);
			 doc.text(item.BillAmount, 205, y);
			 doc.line(20, y + 3, 255, y + 3);
			 y += 9;
		  });
		  //endregion
		  
		  //region vertical line
		  doc.setLineWidth(0.8);
		  doc.line(20, 52.6, 20, y - 5.8); 
		  doc.line(255, 52.6, 255, y - 5.8);
		  //endregion
	
		  //region bottom line
		  doc.setLineWidth(0.4);
		  doc.line(19.6, y - 5.7, 255.4, y - 5.7);
		  //endregion
		  
		  //region footer
		  doc.setFont('courier', 'bold');
		  // doc.setFontType('bold');
		  doc.setFontSize(13);
		  doc.text('Daang Maharlika, Bitas, Cabanatuan City 3100', doc.internal.pageSize.width / 2, y + 3, null, null, 'center');
		  doc.text('Tel. No. 463-0505/463-0811/463-0408', doc.internal.pageSize.width / 2, y + 3 + 6, null, null, 'center');
		  doc.text('Cabanatuan Electric Corporation', doc.internal.pageSize.width / 2, y + 3 + 6 + 6, null, null, 'center');
		  //endregion
	
		  //region date printed
			doc.setFont('courier', 'bold');
		  // doc.setFontType('bold');
		  doc.setFontSize(11);
		  doc.text('Date Printed : ' +moment().format('DD-MMM-YYYY h:mm:ss a'), 20, y + 3 + 6 + 6 + + 6 + 3 + 5);
		  //endregion
		  
		  //save pdf file
		  doc.save(state.consumption[0].AccountNo +'_Consumption_History.pdf');
	  }
	  else
	  	alert('Your browser is not compatible for this feature, we recommend you to use Google Chrome or Mozilla Firefox.');
	}

  const getAdjustedLegend = (remarks) =>
  {
    switch(remarks)
	  {
		case "OVER BILLING":
			return "blue";

		case "UNDER BILLING":
			return "red";

		case "CANCELED BILL":
			return "orange";

		case "ADDT'L BILL":
			return "green";

		case null:
			return "";
	  }
  }

  return (
    <>
    <div className="bodyStyle bodyStyleConsumption bodystyle-new" style={BodyStyle}>
			<div className="container-fluid relate">
			<Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
				<React.Fragment>
				{state.serverValidationError == null ? (
					<React.Fragment>
						{Object.keys(state.consumption).length > 0 && state.consumption[0].Status === "Active" ? (
							<React.Fragment>
								<div className="row mb-2">
									<div className="col-md-1 col-lg-1 col-xl-1"></div>
									<div className="col-sm-12 col-md-12 col-md-10 col-lg-10 col-xl-10 consumption-account-caller">
										<h4 className="h4-title">Consumption History</h4>
										<h6 className="h6-subtitle">{state.consumption[0].kWh != null && state.consumption[0].Status === "Active" ? '[' +moment(state.consumption[0].PeriodFrom).format("MMM DD, YYYY") +' to ' +moment(state.consumption[Object.keys(state.consumption).length - 1].PeriodTo).format("MMM DD, YYYY") +']' : ""}</h6>
										<h4 className="h4-title">{state.consumption[0].Name}</h4>
										<h6 className="h6-subtitle">Account Name</h6>
										<h4 className="h4-title">{state.consumption[0].AccountNo}</h4>
										<h6 className="h6-subtitle">Account Number</h6>
									</div>
									<div className="col-md-1 col-lg-1 col-xl-1"></div>
								</div>
								<div className="row mb-2">
									<div className="col-md-1 col-lg-1 col-xl-1"></div>
									<div className="col-sm-12 col-md-12 col-md-10 col-lg-10 col-xl-10">
										<div className="card gradient-bg shadow mb-2">
											<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between consumption-header">
												<div className="text-primary h5">Detailed History</div>
												<div className="dropdown no-arrow">
													<a href="#" role="button" id="dropdownMenuLink"
														data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<FontAwesomeIcon icon={faInfoCircle} />
													</a>
													<div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
														aria-labelledby="dropdownMenuLink">
														<div className="dropdown-header">Action</div>
														<span className="dropdown-item action-print" onClick={handlePrint}><FontAwesomeIcon icon={faFilePdf} /> Download as PDF</span>
													</div>
												</div>
											</div>
											<div className="card-body">
												<BootstrapTable keyField='billmonth' 
													columns={[
														{
															dataField: 'billmonth',
															text: 'Bill Month',
															headerStyle: {
																fontWeight: 'normal',
																textAlign: 'center'
															}
														},
														{
															dataField: 'periodcovered',
															text: 'Period Covered',
															headerStyle: {
																fontWeight: 'normal',
																textAlign: 'center'
															}
														},
														{
															dataField: 'kwh',
															text: 'kwh Used',
															headerStyle: {
																fontWeight: 'normal',
																textAlign: 'center'
															}
														},
														{
															dataField: 'amount',
															text: 'Bill Amount',
															headerStyle: {
																fontWeight: 'normal',
																textAlign: 'center'
															}
														}
													]} 
													data={state.tblData}
													wrapperClasses={"table-responsive"}
													hover={true}
													rowClasses={(row, index) => 'custombstable-tr'}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-1 col-lg-1 col-xl-1"></div>
								</div>
								<div className="row">
									<div className="col-md-1 col-lg-1 col-xl-1"></div>
									<div className="col-sm-12 col-md-12 col-md-10 col-lg-10 col-xl-10">
										<div className="card gradient-bg shadow mb-2 card-chart">
											<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between consumption-header">
												<div className="text-primary h5">Bar Chart </div>
											</div>
											<div className="card-body card-body-chart"><canvas id="chart" ref={chartRef} style={{width: '100%'}}/></div>
										</div>
									</div>
									<div className="col-md-1 col-lg-1 col-xl-1"></div>
								</div>
							</React.Fragment>
						)  : <div className="col-12 text-center h5">Your account is currently Inactive, go to our Main Office at Bitas, Cabanatuan City. Thank you</div>}
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className="row">
							<div className="col-12 text-center h5">
								{state.serverValidationError !== 'Authentication Failed, please log out and log in again.' ? (
										<React.Fragment>
											<img
												src={loader}
												style={loaderStyle}
												alt="Please wait loader from loader.io"
											/> 
											<br />
											{state.serverValidationError}
										</React.Fragment>
									) : (<React.Fragment>{state.serverValidationError}</React.Fragment>
								)}
							</div>
						</div>
					</React.Fragment>
				)}
				</React.Fragment>
			</div>
	  </div>
    </>
  )
}

export default AccountConsumptionHistory;