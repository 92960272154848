import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Navbar from '../../components/Navbar/Navbar';
import HeroLogin from "../../components/Hero/HeroLogin/HeroLogin";
import MainMenu from "../../components/MainMenu/MainMenu";
import LoginForm from "../../components/LoginForm/LoginForm";
import Test from "../../components/LoginForm/Test";
import Test2 from "../../components/LoginForm/Testcopy";
function Login() {

  useEffect(() => {
    document.title = "CELCOR  | Login";
  }, []);

  return (
    <>
      <React.Fragment>
        <Navbar />
        <HeroLogin title="Login" />
        <MainMenu />
        <LoginForm />
        {/* <Test /> */}
      </React.Fragment>
    </>
  );
}

export default Login;
