import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroAbout from '../../components/Hero/HeroAbout/HeroAbout';
import ContentComponent from '../../components/AboutComponents/MissionVision';

function MissionVision() {

    useEffect(() => {
        document.title = "CELCOR | Mission and Vision";
    },[],);
    
    return (
        <div className="MissionVision">
            <Navbar />
            <HeroAbout title="Mission & Vision"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default MissionVision;