import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/CSR 2021 1.jpg";
import Pic2 from "./image/CSR 2021 2.jpg";
import Pic3 from "./image/CSR 2021 3.jpg";
import Pic4 from "./image/CSR 2021 4.jpg";
import Pic5 from "./image/CSR 2021 5.jpg";
import Pic6 from "./image/CSR 2021 6.jpg";
import Pic7 from "./image/CSR 2021 7.jpg";
import Pic8 from "./image/CSR 2021 8.jpg";

function Content () {
    return(
        <React.Fragment>
            <div className="text-justify">
				The Cabanatuan Electric Corporation (CELCOR) held its annual corporate-social-responsibility project
				‘Sparks of Joy’ last December 2021. This event was made possible because of the CELCOR employees’
				initiative to give back to the community; all their monetary donation were pooled into hundreds of goodie bags,
				each containing hygiene kits (alcohol spray bottle, towel, and small tote bag), and were given to consumers who
				visited Bitas and SMI collection offices. Employees who were on rotation to give away the bags also took pictures with
				the consumers to commemorate the event.
				<br />
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic1} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic2} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic3} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic4} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic5} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic6} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic7} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic8} />
					</div>
				</div>
        </React.Fragment>
    )
	
}

export default Content;