import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/CELCOR Signs Data Sharing 1.png";

function Content () {
    return(
        <React.Fragment>
            <div className="text-justify">
                         April 4, 2023 - Cabanatuan Electric Corporation together with other Private Electric Distribution
                         Utilities (DUs) attended the signing of Data Sharing Agreement with the Energy Regulatory
                         Commision (ERC) at the ERC Main Office in Pasig. The intention of the said gathering is to speed
                         up the implementation of the new policy of the Lifeline Rate Subsidy by providing the list of their
                         certified marginalized End users under Pantawid Pamilyang Pilipino Program (4Ps).
                         <br /><br />
                         Present in the event is CELCOR’S Chief Executive Officer, Engr. Villamor Dagamac as well as
                         CELCOR’s Regulatory Compliance Officer and Head of the Engineering Department, Engr. Bernie
                         Laguni and the company’s Data Privacy Officer and Management Information System Head, Mr.
                         John Tagle. ERC Chairperson and CEO Monalisa C. Dimalanta led the signing event along with
                         Engr. Legario Galang, the Director of Regulatory Operations Service.
                         <br /><br />
                         Pursuant to the Republic Act No. 11552 or also known as the Act of Extending and Enhancing the
                         Implementation of the Lifeline Rate subsidy, ERC joined by DOE and DSWD inked the
                         Implementing Rules and Regulations of the RA no. 11552 last October 2022, this provided
                         guidelines in determining qualifications of consumers who can avail the lifeline rate which was
                         extended to a period of 50 years. A Lifeline Rate is a subsidized rate given to consumers who
                         cannot afford to pay their electricity bills at full cost. 
                         <br /><br />
                         The execution of this event puts in place such safeguards and controls for data privacy and
                         security as information is shared among the parties involved in the Lifeline Rate Program.
                         <br /><br />
                         (Photo taken: April 4, 2023 by ERC)
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic1} />
					</div>
				</div>
        </React.Fragment>
    )
	
}

export default Content;