import React, { useEffect, useState } from "react";
import axios from "axios";
import './css/LatestCelcorLive.css';
import config from 'react-global-configuration';

function LatestCelcorLive() {

    const [state, setState] = useState({
        item: null
    });

    useEffect (() => {
        axios
			.get(`${config.get('apiUrl')}/Home/celcorlive`)
			.then(response => {
				if(Object.keys(response.data).length > 0)
				{
					setState({
						item: response.data[0]
					})
				}
			});
    },[],);
    return (
        <React.Fragment>
            <div id="latestcelcorlive" className="container-fluid relate">
                <div className="card gradient-bg shadow">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <div className="text-primary h5 font-weight-bold"><b>CELCOR LIVE</b></div>
                    </div>
                    <div className="card-body pb-5">
                        {state.item !== null ? (
                            <React.Fragment>
                                <strong className="color-black">{state.item.Title}</strong><br />
                                {state.item.VideoType === 'Facebook' ? (
                                    <React.Fragment>
                                        <div className="fb-video"
                                            data-href={state.item.Link}
                                            data-width="auto"
                                            data-show-captions="false">
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div id="livevideo">
                                        <iframe src={state.item.Link.replace('watch?v=', 'embed/')}
                                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                        </iframe>
                                    </div>
                                )}
                                <div className="bottom-link blog_card_content">
                                    <a className="hyperlink" target="_blank" href={state.item.Link}><h6>Watch Here &gt;&gt;</h6> </a>
                                </div>
                            </React.Fragment>
                        ) : ""}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default LatestCelcorLive;