import React, {useState, useEffect, startTransition} from "react";
import logo from "./images/logo000.png";
import "./css/Navbar.css";
import { Link, Navigate, withRouter } from "react-router-dom";
import { encrypt } from "../../Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt, faSignOutAlt, faCog, faSync, faDesktop, faPlus, faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import localForage from "localforage";
import { useCallback } from "react";

const iconUser = <FontAwesomeIcon icon={faUserAlt} />;
const iconDashboard = <FontAwesomeIcon icon={faDesktop} />;
const iconSignout = <FontAwesomeIcon icon={faSignOutAlt} />;
const iconSettings = <FontAwesomeIcon icon={faCog} />;
const iconChange = <FontAwesomeIcon icon={faSync} />;
const iconAdd = <FontAwesomeIcon icon={faPlus} />;
const iconMoney = <FontAwesomeIcon icon={faMoneyBillAlt} />;
const img = <img src={logo} alt="celcor_image" className="celcor-logo" style={{height: "68px", width: "272px"}} />;

function Navbar() {

  const [state, setState] = useStateWithCallbackLazy ({
    loggedIn: false,
    navigate: false,
    btnName: "Logout",
    btnName2: "Account",
    path: "",
    width: 0,
    emp: false
  });

  useEffect(() => {
      navbarEffect();
    // setState(prevState => ({
    //   ...prevState,
    //   width: localStorage.getItem("x")
    // }));
    // if (localStorage.getItem("userData"))
    // {
    //   const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2))[0];
    //   if (userData.hasOwnProperty('Emp'))
    //   {
    //     setState(prevState => ({
    //       ...prevState,
    //       emp: true
    //     }));
    //   }
    // }
  }, []);

  // useEffect(() => {
  //   setState(prevState => ({
  //     ...prevState,
  //     width: localStorage.getItem("x")
  //   }));
  //   if (localStorage.getItem("userData"))
  //   {
  //     const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
  //     if (userData.hasOwnProperty('Emp'))
  //     {
  //       setState(prevState => ({
  //         ...prevState,
  //         emp: true
  //       }));
  //     }
  //     setState(prevState => ({
  //       ...prevState,
  //       loggedIn: true,
  //       path: window.location.pathname
  //     }));
	// 		if(userData[0].TradeId == 3)
	// 		{
	// 			setState(prevState => ({
  //         ...prevState,
  //         btnName2: "Accounts"
  //       }));
	// 		}
	// 		else
	// 		{
  //       setState(prevState => ({
  //         ...prevState,
  //         btnName2: "Overview"
  //       }));
	// 		}
  //   }
  // }, []);

  const navbarEffect = () => {
    setState(prevState => ({
      ...prevState,
      width: localStorage.getItem("x")
    }));
    if (localStorage.getItem("userData"))
    {
      const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
      if (userData.hasOwnProperty('Emp'))
      {
        setState(prevState => ({
          ...prevState,
          emp: true
        }));
      }
      setState(prevState => ({
        ...prevState,
        loggedIn: true,
        path: window.location.pathname
      }));
			if(userData[0].TradeId == 3)
			{
				setState(prevState => ({
          ...prevState,
          btnName2: "Accounts"
        }));
			}
			else
			{
        setState(prevState => ({
          ...prevState,
          btnName2: "Overview"
        }));
			}
    }
  }

  const logout = () => 
  {
    
		setState(prevState => ({
      ...prevState,
      navigate: state.path !== '/',
			loggedIn: false
    }), () => {
      localStorage.setItem("userData", "");
      localStorage.setItem("Token", "");
      localStorage.setItem("idx", "");
      localStorage.clear();
    });
	}

  const getNavBar = () =>
	{
		if (state.loggedIn)
		{
      if (state.path === "/change-account")
      {
        return(
					<React.Fragment>
						<Link className="navbar-brand nav-link" to="/">
							{img}
						</Link>
						<button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse" id="navbarSupportedContent1">
							<ul className="navbar-nav ms-auto">
								<li className="nav-item active">
									<a className="nav-link logout-btn-nav" href="#" onClick={logout}>{iconSignout} Log Out</a>
								</li>
							</ul>
						</div>
					</React.Fragment>
				)
      }
      return (
        <React.Fragment>
          <Link className="navbar-brand nav-link" to="/">
            {img}
          </Link>
          <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
  
          <div className="collapse navbar-collapse" id="navbarSupportedContent2">
            <ul className="navbar-nav navbar-nav2 ms-auto">
              <li className="nav-item active dropdown ">
                <a className="nav-link dropdown-toggle hover-green nav-left-margin" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{iconUser} Account</a>
                <div className="dropdown-container"> 
                  <div className="dropdown-menu text-align-right-nav margin-top-nav" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item hover-green"
                    to="/dashboard">{iconDashboard} Dashboard</Link>
                    <Link className="dropdown-item hover-green" to="/manage-account">{iconSettings} Manage Account</Link>
                    {state.btnName2 === "Accounts"
                    ? <Link className="dropdown-item hover-green" to="/change-account">{iconChange} Change Electric Account</Link>
                    : <a className="dropdown-item hover-green disabled" href="#">{iconChange} Change Electric Account</a>
                    }
                    <Link className="dropdown-item hover-green" to="/add-account">{iconAdd} Add Electric Account</Link>
                    {state.emp && state.emp === true
                    ? (
                      <Link className="dropdown-item hover-green" to="/payslip">{iconMoney} Employee Payslip</Link>
                      )
                    : ""}
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item hover-green" href="#" onClick={logout}>{iconSignout} Log Out</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </React.Fragment>
        );
		}
    else
    {
    return (
      <React.Fragment>
        <Link className="navbar-brand nav-link" to="/">
          {img}
        </Link>
        <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent3" aria-controls="navbarSupportedContent3" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent3">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item active">
              <Link className="nav-item btn btn-green mx-1" to="/register">
              Register
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-item btn btn-hallow-green mx-1" to="/login">
              Login
              </Link>
            </li>
          </ul>
        </div>
      </React.Fragment>
      );
    }
	}

  if(state.navigate)
  {
    return <Navigate to ="/" />
  }

  return (
    <nav className="navbar top-navbar navbar-expand-lg navbar-light bg-light-transparent py-0 box-shadow no-print">
    {getNavBar()}
    </nav>    
  );
}

export default Navbar;
