import React, {useState, useRef} from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import config from 'react-global-configuration';
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

function LC02(props) {

  const [state, setState] = useStateWithCallbackLazy({
    isBusy: false,
    response: {
			Message: '',
			Type: ''
		},
    showterms: true
  })

  const tncl = useRef(null);
  const pbl = useRef(null);

  const prevClicked = () =>
  {
    props.goToStep(13);
  }

  const submitClicked = () =>
  {
    if(state.isBusy === true) return;
    if(tncl.current.checked == false)
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'You must agree to CELCOR Terms and Conditions first',
					Type: 'Error'
				}
			}));
			return;
		}
		
		let document1;
		if((document1 = props.getState('document1')) === null)
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'Primary ID/Secondary IDs is required',
					Type: 'Error'
				}
			}));	
			return;
		}

		let document2;
		if((document2 = props.getState('document2')) === null)
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'Authorization Letter is required',
					Type: 'Error'
				}
			}));
			return;
		}

		let lastname;
		if((lastname = props.getState('authLname')) === '')
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'Last Name is required',
					Type: 'Error'
				}
			}));
			return;
		}

		let firstname;
		if((firstname = props.getState('authFname')) === '')
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'First Name is required',
					Type: 'Error'
				}
			}));
			return;
		}

		let kwh;
		if((kwh = props.getState('kwh')) === '')
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'Invalid value for Total kWh',
					Type: 'Error'
				}
			}));
			return;
		}

		let dueDate;
		if((dueDate = props.getState('duedate')) === '')
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'Invalid value for Due Date',
					Type: 'Error'
				}
			}));
			return;
		}

		let email;
		if((email = props.getState('email')) === '')
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'Email Address is required',
					Type: 'Error'
				}
			}));
			return;
		}

		let phone;
		if((phone = props.getState('phone')) === '')
		{
			setState(prevState => ({
				...prevState,
				response: {
					Message: 'Mobile number must be 11 numbers',
					Type: 'Error'
				}
			}));
			return;
		}
		
		setState(prevState => ({
			...prevState,
			isBusy: true,
			response: {
				Message: "Submitting registration, please wait...",
				Type: 'Info'
			}
		}));

		let pb = pbl.current.checked ? 1 : 0;
		const formData = new FormData();
		let accountno = props.getState('accountNo');
		let middlename = props.getState('authMname');
		formData.append("accountno", accountno);
		formData.append("kwh", kwh);
		formData.append("duedate", dueDate);
		formData.append("email", email);
		formData.append("phoneno", phone);
		formData.append("authlname", lastname);
		formData.append("authfname", firstname);
		formData.append("authmname", middlename);
		formData.append("doc1", document1);
		formData.append("doc2", document2);
		formData.append("rate", props.getState('rate').replace(' ', ''));
		formData.append("pb", pb);
		axios
			.post(config.get('apiUrl') + '/Main/insert_registration', formData)
			.then(response => {
				let data = response.data;
				setTimeout (() => {
					setState(prevState => ({
						...prevState,
						isBusy: false,
						response: {
							Message: "",
							Type: ''
						}
					}), () => {
						if(data.Type === 'Success')
						{
							props.handleState('rate', '');
							if (pb == 0)
								props.handleState('paperlessBill', 'NO');
							else
								props.handleState('paperlessBill', 'YES');
							props.goToStep(15);
							return;
						}
						setState(prevState=> ({
							...prevState,
							response: data
						}));
					});
				}, 1300)
			})
			.catch(ex => {

			});
  }

  const countString = function (str, strToCount)
  {
    var result = 0, i = 0;
		for(i; i<str.length; i++)
		{
			if(str[i] == strToCount)
				result++;
		}
  
		return result;
  }

  return (
    <>
    <div className="container">
					<div className="row">
						<div className="col-4">
							<label>Primary ID/Secondary IDs</label>
						</div>
						<div className="col-8">
							<input type="file" accept="image/*" className="file-max-width"
								onChange={(e) => {
									props.handleState('document1', e.target.files[0]);
								}}
							/>
						</div>
					</div>
                    <div className="row">
						<div className="col-4">
							<label>Authorization Letter</label>
						</div>
						<div className="col-8">
							<input type="file" accept="image/*" className="file-max-width" 
								onChange={(e) => {
									props.handleState('document2', e.target.files[0]);
								}}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-lg-4 col-xl-4">
							<label>Last Name</label>
						</div>
						<div className="col-sm-12 col-12 col-md-8 col-lg-8 col-xl-8">
							<input type="text" className="form-control form-control-sm" maxLength='100' placeholder="Last Name" required
								onBlur={(e) => {
									e.target.value = e.target.value.toUpperCase();
									props.handleState('authLname', e.target.value);
								}}
							/>
						</div>
					</div>
                    <div className="row">
						<div className="col-md-4 col-lg-4 col-xl-4">
							<label>First Name</label>
						</div>
						<div className="col-sm-12 col-12 col-md-8 col-lg-8 col-xl-8">
							<input type="text" className="form-control form-control-sm" maxLength='100' placeholder="First Name" required
								onBlur={(e) => {
									e.target.value = e.target.value.toUpperCase();
									props.handleState('authFname', e.target.value);
								}}
							/>
						</div>
					</div>
                    <div className="row">
						<div className="col-md-4 col-lg-4 col-xl-4">
							<label>Middle Name</label>
						</div>
						<div className="col-sm-12 col-12 col-md-8 col-lg-8 col-xl-8">
							<input type="text" className="form-control form-control-sm" maxLength='100' placeholder="Middle Name" required
								onBlur={(e) => {
									e.target.value = e.target.value.toUpperCase();
									props.handleState('authMname', e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-lg-4 col-xl-4">
							<label>Total kWh Consumption</label>
						</div>
						<div className="col-sm-12 col-12 col-md-8 col-lg-8 col-xl-8">
							<CurrencyFormat 
								className="form-control form-control-sm"
								format="###########"
								required
								placeholder="Total kWh"
								onBlur={(e) => {
									const kwh = e.target.value.replace(/\s/g, '');
									if(kwh.length < 11 && kwh.length !== 0)
										props.handleState('kwh', kwh);
									else
										props.handleState('kwh', '');
								}}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-lg-4 col-xl-4">
							<label>Current Due Date</label>
						</div>
						<div className="col-sm-12 col-12 col-md-8 col-lg-8 col-xl-8">
							<CurrencyFormat 
								className="form-control form-control-sm"
								format="##/##/####"
								placeholder="MM/DD/YYYY" 
								mask={['M', 'M', 'D', 'D', 'Y' , 'Y',  'Y', 'Y']}
								required
								onBlur={(e) => {
									let date = moment(e.target.value);
									if(date.isValid())
										props.handleState('duedate', date.format("YYYY-MM-DD"));
									else
										props.handleState('duedate', '');
								}}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-lg-4 col-xl-4">
							<label>Email Address</label>
						</div>
						<div className="col-sm-12 col-12 col-md-8 col-lg-8 col-xl-8">
							<input type="text" className="form-control form-control-sm" defaultValue={props.getState('em')} maxLength='200' placeholder="Email Address" required
								onBlur={(e) => {
									e.target.value = e.target.value.toLowerCase();
									props.handleState('email', e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-lg-4 col-xl-4">
							<label>Mobile Number</label>
						</div>
						<div className="col-sm-12 col-12 col-md-8 col-lg-8 col-xl-8">
							<CurrencyFormat 
								className="form-control form-control-sm"
								format="###########" 
								mask="_"
								placeholder="11-Digit Mobile Number" 
								required
								onBlur={(e) => {
									const mobileNo = e.target.value;
									if(mobileNo.length == 11 && countString(mobileNo, "_") == 0)
										props.handleState('phone', mobileNo);
									else
										props.handleState('phone', mobileNo);
								}}
							/>
						</div>
					</div>
					<i className="mt-3">You can only upload .jpg/.png/.jpeg files with maximum size of 20MB each file</i><br />
					<i className="text-center">(Uploading of documents is for verification purposes only)</i>
					<div className="text-center font-weight-bold">
						<span>{state.response.Message}</span>
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12">
							<div className="form-check">
								<input type="checkbox" className="form-check-input" id="pbl" ref={pbl} defaultChecked={true}
									onChange={(e) => {
										setState(prevState => ({
											...prevState,
											showterms: pbl.current.checked
										}));
									}} 
								/>
								<label htmlFor="pbl" className="form-check-label">Subscribe to CELCOR Paperless Billing once my registration is approved.</label>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12">
							<div className="form-check">
								<input type="checkbox" className="form-check-input" id="tncl" ref={tncl} />
								<label htmlFor="tncl" className="form-check-label">I have read and agree to <Link to="/terms-and-conditions" target="_blank">Terms and Conditions</Link> {state.showterms === true ? (<React.Fragment><br />and <Link to="/paperless-billing-terms-and-conditions" target="_blank">Paperless Billing Terms and Conditions</Link></React.Fragment>) : ""}</label>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<button className="btn btn-registration" onClick={prevClicked}>
								{props.prevIcon} BACK
							</button>
						</div>
						<div className="col-md-6 col-lg-6 col-xl-6 mt-3"></div>
						<div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<button className="btn btn-registration" onClick={submitClicked}>
								SUBMIT
							</button>
						</div>
					</div>
				</div>
    </>
  )
}

export default LC02