import React, { useState, useEffect } from "react";
import axios from "axios";
import '../AboutComponents/css/contentstyle.css';
import "./css/InfomercialsComponents.css";
import config from 'react-global-configuration';
import loader from "./../../loader.svg";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

const loaderStyle = {
	height: "150px",
	width: "150px"
};

function InfomercialsComponents(props) {

    const [state, setState] = useState ({
			list: [],
			loader: true
    });

    useEffect (() => {
		axios
			.get(`${config.get('apiUrl')}/Infomercials`, {
				params: {
					tag: props.code
				}
			})
			.then((response) => {
				setState(prevState => ({
					...prevState,
					list: response.data,
					loader: false
				}));
			});
    }, []);

    return (
        <React.Fragment>
				<div className="container-fluid bodystyle-small-scr relate">
				<Particles className="particlesdiv"
				id="tsparticles"
				init={ParticlesInit}
				options={ParticlesSetup}
				/>
				<div className="container px-0 pt-5 pb-5">
					<div className="row">
						<div className="col-md-2 col-lg-2 col-xl-2"></div>
						<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							{state.list && Object.keys(state.list).length > 0 ? (
								<React.Fragment>
								{state.list.map((item, idx) => {
									return (
										<div className="infomercial-item card relate box-shadow card-contact">
											<div className="card-header infomercial-title header-bg">{item.Title}</div>
											<div className="card-body">
												{item.VideoType === 'Facebook' ? (
													<div className="fb-video"
														data-href={item.Link}
														data-width="auto"
														data-show-captions="false">
													</div>
												) : (
													<div className="youtube-vid">
														<div className="container-fluid">
															<iframe src={item.Link.replace('watch?v=', 'embed/')}
																frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
															</iframe>
														</div>
													</div>
												)}
											</div>
										</div>
									)
								})}
								</React.Fragment>
							) : state.loader === true ? "" : <h3 className="gradient-text text-center">NO AVAILABLE DATA</h3>}
							<div className="row">
								<div className="col-12 text-center">
									{state.loader && state.loader === true ? (
										<img
											src={loader}
											style={loaderStyle}
										/> 
									) : ""}
								</div>
							</div>
						</div>
						<div className="col-md-2 col-lg-2 col-xl-2"></div>
					</div>
				</div>
			</div>
        </React.Fragment>
    );
}

export default InfomercialsComponents;