import React from "react";
import './css/AboutComponents.css';
import './css/contentstyle.css';
// import ParticlesSetup from './js/particles.json';

function Opportunities() {
    return (
        <React.Fragment>
			<div className="container">
                <div className="row bodystyle-small-scr">
                    <div className="col-md-2 col-lg-2 col-xl-2"></div>
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        {/* <h3 id="nodata" className="gradient-text text-center">JOB VACANCIES</h3>
                        We are encouraging graduates of <strong className="hyperlink-nocursor">Mass Communication, 
                        Development Communication, Communication Arts,</strong> or any 
                        related degrees to apply for job vacancy here at CELCOR.
                        <br /><br />
                        Applicants may send their cover letter and resume at <a href="mailto:opportunities@celcor.com.ph" className="hyperlink">opportunities@celcor.com.ph</a><br /><br /> */}
                        <h3 id="nodata" className="gradient-text text-center">NO AVAILABLE DATA</h3>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xl-2"></div>
                </div>
            </div>
		</React.Fragment>
    );
}

export default Opportunities;