import React, { Component } from "react";
import {Link} from 'react-router-dom';
import img1 from './image/LIFELINE-1.png';
import img2 from './image/LIFELINE-2.png';
import img3 from './image/LIFELINE-3.png';
import './css/ServicesComponents.css';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

function LifelineRate() {

    return (
        <React.Fragment>
			<div className="container-fluid relate">
				<Particles className="particlesdiv "
				id="tsparticles"
				init={ParticlesInit}
				options={ParticlesSetup}
				/>
				<div className="container px-0 pt-5 pb-5 lifeline-bodystyle">
					<div className="card relate box-shadow card-contact">
						<h4 className="p-3 font-weight-bold gradient-text">LIFELINE RATE FAQs</h4>
						<div className="accordion" id="accLifeline">
							{/**ANO ANG LIFELINE RATE SUBSIDY */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-one">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-one" aria-expanded="false" aria-controls="lifeline-faq-one"> 
										<h5>ANO ANG LIFELINE RATE SUBSIDY</h5>
									</button>
								</div>
								<div id="lifeline-faq-one" class="collapse" aria-labelledby="hlifeline-faq-one" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										Ayon sa <strong>Section 4</strong> ng <strong>Republic Act No. 9136</strong> o <strong>EPIRA Law</strong>, ang Lifeline Rate ay isang 
										<strong>subsidized rate</strong> o <strong>karagdagang tulong</strong> para sa mga Marginalized o Low Income Captive Market End-Users 
										o mga konsyumer na mababa ang konsumo ayon sa antas na tinukoy ng Energy Regulatory Commission (ERC) at kapos sa kakayanang magbayad nito.
										<br />
										<br />
										Ang bawat benepisyaryo nito ay may nakalaang 5-100% na Lifeline Rate Subsidy base sa 
										konsumo na hindi tataas sa 75kWh. Narito ang talaan ng kasalukuyang Lifeline Rate Policy ng 
										CELCOR na aprubado ng ERC:
										<br />
										<img src={img1} className="about-new-img" />
										Ayon naman sa paglilinaw at bagong guidelines ng <strong>Republic Act No. 11552</strong>, HINDI NA 
										AUTOMATIC o kusang ia-apply ang Lifeline Rate Subsidy sa monthly electric bills ng Residential 
										Consumers na may konsumong 75kWh pababa. DAPAT NA ITONG IPAREHISTRO ng mga Qualified Beneficiaries.
									</div>
								</div>
							</div>

							{/**SINO ANG MGA BENEPISYARYO NITO? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-two">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-two" aria-expanded="false" aria-controls="lifeline-faq-two"> 
										<h5>SINO ANG MGA BENEPISYARYO NITO?</h5>
									</button>
								</div>
								<div id="lifeline-faq-two" class="collapse" aria-labelledby="hlifeline-faq-two" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										Lahat ng <strong>RESIDENTIAL CONSUMERS na may konsumong 75kWh pababa</strong> kada buwan na:
										<ul>
											<li>Miyembro ng <strong>4P's</strong> o <strong>Pantawid Pamilyang Pilipino Program</strong> na matutukoy 
												sa pamamagitan ng Certified List of 4P's Beneficiaries mula sa DSWD</li>
											<li>o mga <strong>Marginalized End-users</strong> o mga konsyumer na kabilang sa Poverty Threshold ayon 
												sa Philippine Statistics Authority (PSA) at certified ng Cabanatuan City Social Welfare and Development Office (CSWDO)</li>
										</ul>
									</div>
								</div>
							</div>

							{/**SINO ANG HINDI KWALIPIKADO O NON-QUALIFIED BENEFICIARY? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-three">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-three" aria-expanded="false" aria-controls="lifeline-faq-three"> 
										<h5>SINO ANG HINDI KWALIPIKADO O NON-QUALIFIED BENEFICIARY?</h5>
									</button>
								</div>
								<div id="lifeline-faq-three" class="collapse" aria-labelledby="hlifeline-faq-three" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										Hindi itinuturing na benepisyaryo ang lahat ng mga Marginalized End-users na:
										<ul>
											<li>Mga konsyumer na may 75kWh pababa ang konsumo ngunit hindi kabilang sa 
												Marginalized End-user ayon sa pamantayan na itinalaga ng Philippine Statistics Authority 
												(PSA).</li>
											<li>Mga konsyumer na nakatira sa condominiums o subdivisions maliban sa mga government 
												housing projects at iba pang kahalintulad nito.</li>
											<li>Mga konsyumer na nag-avail ng Net Metering Services na hindi kwalipikado base sa 
												konsumo at antas ng pamumuhay ayon sa Section 15 ng ERC Resolution No.06 Series 
												of 2019 o Amended Net Metering Rules</li>
											<li>Mga konsyumer na nakasuhan ng paglabag sa Anti-pilferage of Electricity and Theft of 
												Electric Transmission Lines/Materials Act of 1994.</li>
										</ul>
									</div>
								</div>
							</div>

							{/**ANU-ANO ANG MGA REQUIREMENTS? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-four">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-four" aria-expanded="false" aria-controls="lifeline-faq-four"> 
										<h5>ANU-ANO ANG MGA REQUIREMENTS?</h5>
									</button>
								</div>
								<div id="lifeline-faq-four" class="collapse" aria-labelledby="hlifeline-faq-four" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										Isumite lamang ang mga sumusunod na requirements sa CELCOR Main Office base sa bawat uri ng benepisyaryo:
										<br />
										<img src={img2} className="about-new-img" />
									</div>
								</div>
							</div>

							{/**ANO ANG VALIDITY PERIOD NITO? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-five">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-five" aria-expanded="false" aria-controls="lifeline-faq-five"> 
										<h5>ANO ANG VALIDITY PERIOD NITO?</h5>
									</button>
								</div>
								<div id="lifeline-faq-five" class="collapse" aria-labelledby="hlifeline-faq-five" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<strong>Miyembro ng 4P's o Pantawid Pamilyang Pilipino Program</strong>
										<ul>
											<li>Ito ay ibabase sa taunang listahan ng 4P's Beneficiaries na ibibigay ng ERC sa CELCOR mula naman sa DSWD.</li>
										</ul>
										<strong>Mga Non-4P's o Marginalized End-users o mga konsyumer na kabilang sa Poverty Threshold</strong>
										<ul>
											<li>Tatlong (3) taon simula sa Date of Issuance ng Certification mula sa CSWDO.</li>
											<li>Ang Validity Period ay nakasaad din sa Certificate of Lifeline Rate Coverage na ibibigay ng CELCOR.</li>
										</ul>
									</div>
								</div>
							</div>

							{/**KAILANGAN BA ITONG I-RENEW? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-six">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-six" aria-expanded="false" aria-controls="lifeline-faq-six"> 
										<h5>KAILANGAN BA ITONG I-RENEW?</h5>
									</button>
								</div>
								<div id="lifeline-faq-six" class="collapse" aria-labelledby="hlifeline-faq-six" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<strong>Miyembro ng 4P's o Pantawid Pamilyang Pilipino Program</strong>
										<ul>
											<li>HINDI kailangang mag-apply for renewal at mananatiling Lifeline Rate Beneficiary 
												ang isang 4P's member hangga't siya ay kabilang sa taunang listahan ng Qualified 
												Beneficiaries na ibibigay ng ERC sa CELCOR mula sa DSWD.</li>
											<li>Sakali namang ang 4P's member ay ma-delist o matanggal bilang 4P's member, maaari 
												siyang mag-apply bilang isang Non-4P's o Marginalized End-user. Mag-sumite lamang 
												ng mga kinakailangang dokumento para sa nasabing uri ng beneficiary.</li>
										</ul>
										<strong>Mga Non-4P's o Marginalized End-users o mga konsyumer na kabilang sa Poverty Threshold</strong>
										<ul>
											<li>Dalawang (2) buwan bago mag-expire ang Certificate of Lifeline Rate Coverage, 
												kinakailangang magtungo sa CELCOR Main Office sa Barangay Bitas ang isang non-4P's 
												o Marginzalized End-user o upang magrenew ng kaniyang application.</li>
											<li>Hindi na kailangan magsumite ng bagong certification mula sa CSWDO ngunit 
												ang kaniyang application for renewal ay dadaan muli sa proseso ng validation 
												ng CELCOR upang malaman kung ang nasabing beneficiary ay eligible pa o hindi na.</li>
										</ul>
									</div>
								</div>
							</div>

							{/**PAANO ANG PROSESO NG APPLICATION? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-seven">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-seven" aria-expanded="false" aria-controls="lifeline-faq-seven"> 
										<h5>PAANO ANG PROSESO NG APPLICATION?</h5>
									</button>
								</div>
								<div id="lifeline-faq-seven" class="collapse" aria-labelledby="hlifeline-faq-seven" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<ol>
											<li>Base sa uri ng benepisyaryo, kumpletuhin ang requirements at isumite ang lahat ng ito sa CELCOR Main Office sa Barangay Bitas.</li>
											<li>Sa paunang taon ng implementasyon nito, ang bawat aplikasyon ay ipo-proseso sa loob ng ten (10) working days.</li>
											<li>Ang bawat aplikante ay makakatanggap ng mensahe patungkol sa status ng kaniyang aplikasyon, kung ito ay aprubado o hindi.</li>
											<li>Kapag aprubado na, maaari na kayong bumisita sa CELCOR Main Office at kunin ang kopya ng Certification of Lifeline Rate 
												Grant na kung saan nakasaad ang detalye ng inyong Lifeline Rate.</li>
										</ol>
									</div>
								</div>
							</div>
							
							{/**ANO ANG MGA KARAGDAGANG REQUIREMENTS PARA SA PAG-AAPPLY NG BAGONG LIFELINE RATE POLICY NA ITO? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-eight">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-eight" aria-expanded="false" aria-controls="lifeline-faq-eight"> 
										<h5>ANO ANG MGA KARAGDAGANG REQUIREMENTS PARA SA PAG-AAPPLY NG BAGONG LIFELINE RATE POLICY NA ITO?</h5>
									</button>
								</div>
								<div id="lifeline-faq-eight" class="collapse" aria-labelledby="hlifeline-faq-eight" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<strong>Para sa mga Representative ng aplikante:</strong>
										<ul>
											<li>Letter of Authority (typewritten or handwritten)  na nakasaad ang dahilan sa hindi personal na pag-aapply ng qualified 
												beneficiary. Ito rin ay dapat pirmado ng authorized representative at aplikanteng nirerepresenta nito.</li>
											<li>Valid Government Issued ID ng aplikante at authorized representative.</li>
										</ul>
										<strong>Para sa mga Qualified Beneficiaries ngunit hindi nila pangalang ang naka-rehistro sa CELCOR Account:</strong>
										<ul>
											<li>Katunayan na sila ay naninirahan o ang gumagamit sa nasabing CELCOR account tulad ng Valid Government Issued ID 
												(na may kaparehong address sa CELCOR Account), Barangay Certificate o Contract of Lease.</li>
										</ul>
									</div>
								</div>
							</div>

							{/**TRANSFER OF RESIDENCE OR ADDRESS */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-nine">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-nine" aria-expanded="false" aria-controls="lifeline-faq-nine"> 
										<h5>TRANSFER OF RESIDENCE OR ADDRESS</h5>
									</button>
								</div>
								<div id="lifeline-faq-nine" class="collapse" aria-labelledby="hlifeline-faq-nine" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<ul>
											<li>Sakaling magpalit ng address ang benepisyaryo at ito ay mananatili sa serbisyo ng CELCOR, kailangan lamang magsumite 
												muli ng panibagong Certification bilang isang Qualified Marginalized End-user mula sa Cabanatuan City Social Welfare and 
												Development Office (CSWDO) na nakasaad ang address na siyang paglilipatan ng benepisyaryo. Magbibigay naman ang CELCOR ng Certificate 
												of Un-tagging para sa Re-issuance ng Certificate mula sa CSWDO.</li>
											<li>Sakali namang magpalit ng address ang benepisyaryo ngunit ang bagong address niya ay sa ibang Distribution Utility na at hindi sa CELCOR, 
												ikakansela ng CELCOR ang Lifeline Rate Grant. Maaari namang mag-apply muli ang benepisyaryo sa bagong Distribution Utility.</li>
										</ul>
									</div>
								</div>
							</div>

							{/**PAANO KUNG MADAMI KAMING QUALIFIED BENEFICIARIES SA ISANG KABAHAYAN? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-ten">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-ten" aria-expanded="false" aria-controls="lifeline-faq-ten"> 
										<h5>PAANO KUNG MADAMI KAMING QUALIFIED BENEFICIARIES SA ISANG KABAHAYAN?</h5>
									</button>
								</div>
								<div id="lifeline-faq-ten" class="collapse" aria-labelledby="hlifeline-faq-ten" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<ul>
											<li>Ang Lifeline Rate Subsidy ay maaari lamang i-apply sa ISANG CELCOR ACCOUNT.</li>
										</ul>
									</div>
								</div>
							</div>

							{/**VALIDATION PROCESS */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-eleven">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-eleven" aria-expanded="false" aria-controls="lifeline-faq-eleven"> 
										<h5>VALIDATION PROCESS</h5>
									</button>
								</div>
								<div id="lifeline-faq-eleven" class="collapse" aria-labelledby="hlifeline-faq-eleven" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<strong>Submission of requirements:</strong>
										<ul>
											<li>Sa pagsusumite ng requirements, susuriin ng processor bawat dokumento at ie-endorse sa CELCOR Technical Services Department 
												para sa Schedule of Inspection. Upang mapabilis ang proseso ng bawat aplikasyon, siguraduhin lamang na kumpleto at tama ang 
												lahat ng isusumiteng requirements bago magtungo sa tanggapan ng CELCOR.</li>
										</ul>
										<strong>Inspection</strong>
										<ul>
											<li>Layunin ng inspeksyon na masigurong ang aplikante para sa Lifeline Rate ay nakatira sa address kung saan ia-apply ang subsidy 
												at makita kung siya ba ay eligible o hindi base sa pamantayan ng isang Qualified Beneficiary.</li>
										</ul>
										<strong>Approval and Issuance of Certificate of Lifeline Rate Grant</strong>
										<ul>
											<li>Kapag aprubado na ang aplikasyon, ang CELCOR ay makikipag-ugnayan sa aplikante upang siya ay pumunta sa aming Main Office upang 
												i-claim ang kaniyang Certificate of Lifeline Rate Grant na kung saan nakasaad ang detalye ng inyong Lifeline Rate.</li>
										</ul>
									</div>
								</div>
							</div>

							{/**ISSUANCE OF CERTIFICATE OF LIFELINE RATE COVERAGE */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-twelve">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-twelve" aria-expanded="false" aria-controls="lifeline-faq-twelve"> 
										<h5>ISSUANCE OF CERTIFICATE OF LIFELINE RATE COVERAGE</h5>
									</button>
								</div>
								<div id="lifeline-faq-twelve" class="collapse" aria-labelledby="hlifeline-faq-twelve" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<ul>
											<li>Ang Certificate of Lifeline Rate Coverage ay ang dokumento na nagpapatunay na naaprubahan ng CELCOR ang aplikasyon ng isang Qualified Beneficiary 
												para sa Lifeline Rate. Nilalaman nito ang validity period o kung hanggang kailan ang bisa ng nasabing benepisyo.</li>
										</ul>
									</div>
								</div>
							</div>

							{/**SAANG BAHAGI NG BILL MAKIKITA ANG LIFELINE RATE SUBSIDY? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-thirteen">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-thirteen" aria-expanded="false" aria-controls="lifeline-faq-thirteen"> 
										<h5>SAANG BAHAGI NG BILL MAKIKITA ANG LIFELINE RATE SUBSIDY?</h5>
									</button>
								</div>
								<div id="lifeline-faq-thirteen" class="collapse" aria-labelledby="hlifeline-faq-thirteen" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<img src={img3} className="about-new-img" />
									</div>
								</div>
							</div>

							{/**PAANO KUNG TUMAAS NG HIGIT SA 75KWH ANG KONSUMO NG ISANG LIFELINE RATE BENEFICIARY? */}
							<div className="card">
								<div className="card-header lifeline-faqs" id="hlifeline-faq-fourteen">
									<button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#lifeline-faq-fourteen" aria-expanded="false" aria-controls="lifeline-faq-fourteen"> 
										<h5>PAANO KUNG TUMAAS NG HIGIT SA 75KWH ANG KONSUMO NG ISANG LIFELINE RATE BENEFICIARY?</h5>
									</button>
								</div>
								<div id="lifeline-faq-fourteen" class="collapse" aria-labelledby="hlifeline-faq-fourteen" data-parent="#accLifeline">
									<div className="card-body lifeline-faq-padding">
										<ul>
											<li>HINDI muna makaka-avail ng Lifeline Rate ang nasabing beneficiary para sa billing month kung saan ang kaniyang konsumo ay mataas sa threshold o mataas sa 75kWh.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
    );
}

export default LifelineRate;