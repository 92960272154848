import React, {useEffect, Suspense, lazy} from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import Info from "../../components/NewsInfo/NewsInfo";

function NewsInfo () {

    useEffect (() => {
        document.title = "CELCOR | News";
    }, []);

    return (
        <div className="Home">
            
            <Navbar />
            <HeroNews title="News" />
            <MainMenu />
            <Info />    
        </div>
    );
}
export default NewsInfo;
