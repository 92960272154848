import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/TASK FORCE ODETTE 1.jpg";
import Pic2 from "./image/TASK FORCE ODETTE 1.jpg";
import Pic3 from "./image/TASK FORCE ODETTE 1.jpg";
import Pic4 from "./image/TASK FORCE ODETTE 4.jpg";
import Pic5 from "./image/TASK FORCE ODETTE 5.jpg";

function Content() {
    return(
        <React.Fragment>
            <div className="text-justify">
                A crew of linemen from Cabanatuan Electric Corporation (CELCOR) were deployed in Cebu Province to
                assist in restoring the electricity supply after the devastation brought about by Typhoon Odette.
                <br /><br />
                Departing from their hometown, Cabanatuan City on December 29, 2021 and returning after 43 days,
                our group of CABayan 'Light Warriors' worked along with other electric utilities who provided aide to
                more than 400,000 households affected in the Southern Part of the country including Cebu Province.
                <br /><br />
                According to the Department of Energy (DOE), numerous electrical structures such as poles and
                distribution lines are still needed to be repaired or replaced to fully bring back the power supply for the
                whole province.
                <br /><br />
                Photo courtesy: Melvin Giron, Bertrand Kintanar and Viper Shots from Facebook Posts.
            </div>
            <div className="row">
                <div className="col-12">
                    <img className="img-thumbnail img-fluid image" src={Pic1} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <img className="img-thumbnail img-fluid image" src={Pic2} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <img className="img-thumbnail img-fluid image" src={Pic3} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <img className="img-thumbnail img-fluid image" src={Pic4} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <img className="img-thumbnail img-fluid image" src={Pic5} />
                </div>
            </div>
        </React.Fragment>
    )
}   

export default Content;