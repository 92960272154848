import React, {useState, useEffect } from 'react';
import "./css/AddAccount.css";
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { encrypt } from '../../../Common';
import config from 'react-global-configuration';
import Particles from "react-tsparticles";
import ParticlesSetup from '../../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "../../AboutComponents/js/particles.init";
import { useRef } from 'react';

const BodyStyle = {
	paddingTop: "96px",
  marginBottom: "11px"
};

function AddAccount() {

  const [state, setState] = useState ({
    accountno: '',
		response: {
			Message: '',
			Type: ''
		}
  })

  const submitRef = useRef(null);

  const setaccountno = (event) =>
  {
    const accountnumber = event.target.value;
    if(accountnumber.length == 7 && countString(accountnumber, "_") == 0)
    {
      setState(prevState => ({
        ...prevState,
        accountno: accountnumber
      }));
    }
    else
    setState(prevState => ({
      ...prevState,
      accountno: ''
    }));
  }

  const submitClicked = (e) => 
  {
    add_account(state.accountno)
  }

  const add_account = (accountno) =>
  {
    if(submitRef.current.disabled == true) return;

    if(accountno.length !== 7 || countString(accountno, "_") !== 0)
    {
      setState(prevState => ({
        ...prevState,
        accountno: '',
        response: {
          Message: 'Account number must be 7-Digit',
          Type: 'Error'
        }
      }));
      return;
    }

    submitRef.current.disabled = true;
    
    setState(prevState => ({
      ...prevState,
      response: {
        Message: "Submitting account number, please wait...",
        Type: 'Info'
      }
    }));

    const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
    const token = encrypt(localStorage.getItem("Token"));
    const formData = new FormData();
    formData.append("cust_id", userData[0].Id);
    formData.append("token", token);
    formData.append("accountno", accountno);

    axios
      .post(config.get('apiUrl') + '/Home/add_account', formData)
      .then(response => {
        if(response.data == '0')
        {
          setState(prevState => ({
            ...prevState,
            response: {
              Message: 'Authentication Failed, please log out then log in again',
              Type: 'Error'
            }
          }));
        }
        else
        {
          setTimeout(() => {
            setState(prevState => ({
              ...prevState,
              response: response.data
            }));
          }, 1500);
        }
      })
      .catch (ex => {

      });
      submitRef.current.disabled = false;
  }

  const countString = (str, strToCount) =>
  {
	  var result = 0, i = 0;
	  for(i; i<str.length; i++)
	  {
		  if(str[i] == strToCount)
			  result++;
	  }

	  return result;
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!localStorage.getItem("idx"))
    {
      setState(prevState => ({
        ...prevState,
        redirect: true
      }));
      return;
    }
  }, []);

  if(state.redirect === true)
  {
    return <Navigate to={"/change-account"} />
  }

  return (
    <>
    <div className="bodyStyle bodyStyleOverview bodystyle-new-addaccount relate" style={BodyStyle}>
    <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit}	options={ParticlesSetup}/>
		<React.Fragment>
			<div className="row mb-2">
				<div className="col-md-3 col-lg-3 col-xl-3"></div>
				<div className="col-sm-12 col-md-12 col-md-6 col-lg-6 col-xl-6">
					<div className="card gradient-bg shadow mb-2">
						<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between consumption-header">
							<div className="text-primary h5">Add Electric Account</div>
						</div>
						<div className="card-body margin-left-h4-caller">
							<h6 className="text-center">Enter 7-digit account number that you want to add then click Submit to continue</h6>
							<CurrencyFormat 
								className="form-control text-center"
								format="#######" 
								type="tel"
								mask="_"
								required
								onBlur={setaccountno}
								onKeyDown={(e) => {
									setState(prevState => ({
                    ...prevState,
										response: {
											Message: '',
											Type: ''
										}
									}));
									if(e.keyCode === 13)
									{
										e.target.blur();
										add_account(e.target.value);
									}
								}}
								/>
							<i className="h6-subtitle">Please note that ALL APPLICATION will be subject for approval by the Management. Thank you</i>
							<div className="text-center font-weight-bold">{state.response.Message}</div>
							<button type="submit" className="btn btn-cta  d-block mx-auto mt-3" ref={submitRef} onClick={submitClicked}>
								Submit
							</button>
						</div>
					</div>
				</div>
				<div className="col-md-3 col-lg-3 col-xl-3"></div>
			</div>
			</React.Fragment>
	  </div>
    </>
  )
}

export default AddAccount