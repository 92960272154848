import React, {useEffect} from "react";
import missionvision from './image/MISSION VISION.jpg';
import './css/AboutComponents.css';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

function MissionVision() {

		useEffect(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		},[],)

    return (
        <div className="container-fluid relate ">
				<Particles className="particlesdiv "
				id="tsparticles"
				init={ParticlesInit}
				options={ParticlesSetup}
				/>
				<div className="container px-0 pt-5 pb-5">
					<div className="card relate box-shadow card-contact">
						<div className="row">
							{/* <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div> */}
							<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<img src={missionvision} className="about-new-img" />
							</div>
							{/* <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>   */}
						</div>
					</div>
				</div>
			</div>
    );
}

export default MissionVision;