import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Particles from "react-tsparticles";
import ParticlesSetup from '../../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "../../AboutComponents/js/particles.init";
import axios from 'axios';
import "./css/PaperlessBilling.css";
import "../../AboutComponents/css/contentstyle.css";
import header from "./image/PAPERLESS BILL HEADER.png";
import accountno from "./image/accountno.jpg";
import swal from 'sweetalert';
import config from 'react-global-configuration';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { encrypt } from '../../../Common';
import CurrencyFormat from 'react-currency-format';

const imgHint = <FontAwesomeIcon icon={faInfoCircle} />
const imgAccountno = <img src={accountno} alt="accountno" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} />;

const loaderStyle = {
	height: "150px",
	width: "150px"
};

const smallSize = {
	fontSize: "11px",
}

const tooltipStyle = {
	padding: ".375rem .75rem", 
	color: "#6c757d"
}

const BodyStyle = {
	/* minHeight: "491px", */
	paddingTop: "96px",
	marginBottom: "75px"
  };

function AccountPaperlessBilling(props) {

  const [state, setState] = useState ({
    serverResponse: {
      type: null,
      Message: ''
    },
    isLoader: false,
    loaderMsg: '',
    redirect: false
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  const emailRef = useRef(null);
  const noticeRef = useRef(null);
  const tncRef = useRef(null);
  const btnsubscribeRef = useRef(null);

  const subscribeClick = (e) =>
  {
		e.preventDefault();
    setState(prevState => ({
      ...prevState,
      serverResponse:{
				type: 'Error',
				Message: ''
			}
    }));
    if(true)
    {
      if(tncRef.current.checked == false)
      {
        setState(prevState => ({
          ...prevState,
          serverResponse:{
            type: 'Error',
            Message: 'You must agree to Paperless Billing Terms and Conditions first'
          }
        }));
        return;
      }

      swal({
        text: "Do you want to subscribe to CELCOR's Paperless Billing System?",
				icon: "info",
				closeOnClickOutside: false,
				buttons: {
					No: {
						text: "No",
						closeModal: true,
						value: false,
						className:'noButton'
					},
					Yes: {
						text: "Yes",
						value: true,
						className:'yesButton'
					}
				}
      })
      .then(isDelete => {
        if(isDelete === null || isDelete === false)
        return;
				
				swal({
					title: '',
					text: 'Subscribed!',
					closeOnClickOutside: false,
					icon: 'success',
					timer: 2500,
					buttons: false,
				});

        setState(prevState => ({
          ...prevState,
          isLoader: true,
					loaderMsg: '',
					serverResponse: {
						type: 'Error',
						Message: 'Subscribing to CELCOR\'s Paperless Billing System, please wait...'
					}
        }));
        
        let notice = noticeRef.current.checked == false ? 0 : 1;
				const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
				const token = encrypt(localStorage.getItem("Token"));
				const formData = new FormData();
				formData.append("cust_id", userData[0].Id);
				formData.append("token", token);
				formData.append("idx", localStorage.getItem("idx"));
				formData.append("out", 0);
				formData.append("notice", notice);

        axios
          .post(config.get('apiUrl') + '/Home/update_paperless_billing', formData)
          .then(response => {
            if(response.data === '0')
            {
              setState(prevState => ({
                ...prevState,
                isLoader: false,
								serverResponse: {
									type: 'Error',
									Message: 'Authentication Failed, please log out then log in again'
								}
              }));
            }
            else
            {
              setState(prevState => ({
                ...prevState,
                isLoader: false,
								loaderMsg: '',
								serverResponse: response.data
              }));
							
							setTimeout(() => {
								setState(prevState => ({
									...prevState,
									redirect: true
								}));
							}, 5000);
            }
          })
          .catch(ex => {

          });
      });
      return;
    }
    setState(prevState => ({
      ...prevState,
      serverResponse:{
				type: 'Error',
				Message: 'You must agree to Website use of cookies first'
			}
    }));
  }

  if(state.redirect)
  {
    return <Navigate to="/dashboard" />
  }

  return (
    <>
    <div className="bodyStyle text-justify" style={BodyStyle}>
			<div className="container-fluid relate">
			<Particles className="particlesdiv" id="tsparticles" init={ParticlesInit}	options={ParticlesSetup}/>
			<div className="row">
				<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
				<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
					<img src={header} style={{width: '100%'}} />
				</div>
				<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
			</div>
			<br />
			<div className="row">
				<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
				<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
					{state.serverResponse.type === null || state.serverResponse.type === 'Error' ? (
					<div className="card gradient-bg shadow h-100">
						<div className="card-header">
							<div className="text-primary h5">Account Details</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<div className="form-group">
										<label>Delivery Email Address</label>
										<input
											type="text"
											className="form-control"
											ref={emailRef}
											placeholder="Enter Email Address"
											defaultValue={props.userData.email}
											disabled
										/>
									</div>
								</div>
								<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<div className="form-group">
										<label htmlFor="">Account Number</label>
										<div className="row">
											<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12 input-group">
												<CurrencyFormat 
													placeholder="Enter Account Number" 
													className="form-control"
													format="#######" 
													mask="_"
													value={props.userData.accountNo}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="notice" ref={noticeRef} />
										<label htmlFor="notice" className="form-check-label">&nbsp;&nbsp;I would also like to receive Disconnection Notices thru my delivery email address.</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12 text-center color-red">{state.serverResponse.type === null ? '' : state.serverResponse.Message}</div>
							</div>
						</div>
					</div>
					) : (
					<div className="card gradient-bg shadow h-100">
						<div className="card-header">
							<div className="text-primary h5">Notification</div>
						</div>
						<div className="card-body text-center">
							{state.serverResponse.Message}
						</div>
					</div>
					)}
				</div>
			</div>
			{state.serverResponse.type === null || state.serverResponse.type === 'Error' ? (
			<React.Fragment>
				<div className="row">
					<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
					<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12">
								<div className="form-check">
									<input type="checkbox" className="form-check-input" id="tnc" ref={tncRef} />
									<label htmlFor="tnc" className="form-check-label">&nbsp;&nbsp;I have read and agree to <Link target="_blank" to="/paperless-billing-terms-and-conditions">Paperless Billing Terms and Conditions</Link></label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"></div>
					<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
						<button 
							type="button" 
							className="btn btn-cta d-block mx-auto mt-3"
							ref={btnsubscribeRef}
							onClick={subscribeClick}
							disabled={state.isLoader}
						>
						Subscribe Now</button>
					</div>
					<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"></div>
				</div>
			</React.Fragment>
			) : (
			<React.Fragment>
				
			</React.Fragment>
			)}
			</div>
		</div>
    </>
  )
}

export default AccountPaperlessBilling