import React, {useState, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import Navbar from '../../../components/Navbar/Navbar';
import axios from 'axios';
import config from 'react-global-configuration';
import loader from "../../../loader.svg";
import "../../../components/AboutComponents/css/contentstyle.css";
import { encrypt } from '../../../Common';
import PaperlessBillingOptOut from '../../../components/Account/PaperlessBillingOptOut/PaperlessBillingOptOut';
import PaperlessBillingOptIn from '../../../components/Account/AccountPaperlessBilling/PaperlessBilling';
import Particles from "react-tsparticles";
import ParticlesSetup from '../../../components/AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "../../../components/AboutComponents/js/particles.init";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const loaderStyle = {
	height: "150px",
	width: "150px"
};

const BodyStyle = {
	/* minHeight: "493px",
	paddingTop: "96px",
	marginBottom: "75px" */
};

function AccountPaperlessBilling() {

  const [state, setState] = useStateWithCallbackLazy({
    redirect: false,
		status: '',
		errorMessage: '',
		isLoader: true,
		hideUpload: false,
		userData: {
			accountNo: '',
			email: ''
		}
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.title = "CELCOR | Paperless Billing";

    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
      const token = encrypt(localStorage.getItem("Token"));
      const formData = new FormData();
      formData.append("cust_id", userData[0].Id);
      formData.append("token", token);
      formData.append("idx", localStorage.getItem("idx"));

      axios
        .post(config.get('apiUrl') + '/Home/get_paperless_data', formData)
        .then(response => {
          if(response.data == '0')
          {
            setState(prevState => ({
              ...prevState,
              errorMessage: 'Authentication Failed, please log out then log in again',
					    isLoader: false
            }));
          }
          else
          {
            setState(prevState => ({
              ...prevState,
              status: response.data.Message,
				      isLoader: false,
              userData: {
                accountNo: response.data.AccountNo,
                email: userData[0].Email
              }
            }), (currentState) => {
              if(currentState.status !== 'Subscribe' && currentState.status !== 'Unsubscribe')
              setState(prevState => ({
                ...prevState,
                errorMessage: currentState.status
              }));
            });
          }
        })
        .catch(ex => {

        });
    }
    else
    {
      setState(prevState => ({
        ...prevState,
        redirect: true
      }));
    }
  },[])

  if (state.redirect)
  {
    return <Navigate to={"/login"} />
  }

  return (
    <>
    <Navbar />
		<div className="container-fluid h-100">
		  <div className="row h-100">
			<main className="col bodystyle-min-height">
				{state.status === '' || state.errorMessage === 'Invalid account for paperless billing' || state.errorMessage === 'Authentication Failed, please log out then log in again' ? 
				<div className="bodyStyle text-justify" style={BodyStyle}>
					{state.isLoader === true ? (
						<div className="text-center">
							<img
								src={loader}
								style={loaderStyle}
								alt="Please wait loader from loader.io"
							/> 
							<br />
							Please wait...
						</div>
					) : <p className='text-center text-danger font-weight-bold'>{state.errorMessage}</p>}
				</div>
				: state.status === 'Subscribe' ? <PaperlessBillingOptIn userData={state.userData} /> 
				: <PaperlessBillingOptOut userData={state.userData} />}
			</main>
		  </div>
		</div>
    </>
  )
}

export default AccountPaperlessBilling