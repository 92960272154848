import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroAbout from '../../components/Hero/HeroAbout/HeroAbout';
import ContentCompononet from '../../components/AboutComponents/ContactUs';

function ContactUs() {

    useEffect(() => {
        document.title = "CELCOR | Contact Us";
    },[],);
    
    return (
        <div className="ContactUs">
            <Navbar />
            <HeroAbout title="Contact Us"/>
            <MainMenu />
            <ContentCompononet />
        </div>
    );

}
export default ContactUs;