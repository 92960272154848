import React, {useState, useEffect, useRef} from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import loader from '../../loader.svg';
import config from 'react-global-configuration';
import CurrencyFormat from 'react-currency-format';
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../LoginForm/css/LoginForm.css';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import particlesInit from "../AboutComponents/js/particles.init";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const hide = <FontAwesomeIcon icon={faEyeSlash} />
const show = <FontAwesomeIcon icon={faEye} />

const loaderStyle = {
    height: "150px",
    width: "150px"
};

function ForgotPassword() {

  const [state, setState] = useStateWithCallbackLazy ({
    loader: false,
			phase: 1,
			response: {
				Message: null,
				Type: null
			},
			showphase2: false,
			showphase3: false,
			code: '',
			buttontext: 'Get New Password',
			isfinished: false,
			navigate: false,
			passwordType: 'password'
  })

  const emailRef = useRef(null);
  const passRef = useRef(null);

  useEffect (() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const codechanged = (e) =>
  {
    const code = e.target.value;
		if(code.length === 6 && countString(code, '_') === 0)
			return code;
		return '';
  }

  const countString = (str, strToCount) =>
  {
    var result = 0, i = 0;
		for(i; i<str.length; i++)
		{
			if(str[i] == strToCount)
				result++;
		}
  
		return result;
  }

  const buttonclicked = (currentState) =>
  {    
    let form;
    switch(state.phase)
    {
      case 1:
        setState(prevState => ({
          ...prevState,
          loader: true,
          response: {
            Message: null,
            Type: null
          }
        }), () => {
          form = new FormData();
          form.append("email", emailRef.current.value)
          axios
            .post(`${config.get('apiUrl')}/Main/request_password_reset`, form)
            .then(response => {
                let data = response.data;
                setState(prevState => ({
                  ...prevState,
                  response: data,
                  loader: false
                }), () => {
                  if (data.Type === 'Success')
                {
                  setState(prevState => ({
                    ...prevState,
                    loader: false,
                    phase: 2,
                    showphase2: true,
                    buttontext: 'Verify Code'
                  }));
                }
                });
            })
            .catch(ex => {
              
            });
        });
        
        break;

      case 2:
        setState(prevState => ({
          ...prevState,
          loader: true,
          response: {
            Message: null,
            Type: null
          }
        }));
        form = new FormData();
        form.append("email", emailRef.current.value);
        form.append("code", currentState.code);
        axios
          .post(`${config.get('apiUrl')}/Main/test_password_reset`, form)
          .then(response => {
              let data = response.data;
              setState(prevState => ({
                ...prevState,
                response: data,
                loader: false
              }), () => {
                if(data.Type === 'Success')
                {
                  setState(prevState => ({
                    ...prevState,
                    loader: false,
                    phase: 3,
                    showphase3: true,
                    buttontext: 'Update Password'
                  }));
                }
              });
          })
          .catch(ex => {

            });
        
        break;

      case 3:
        setState(prevState => ({
          ...prevState,
          loader: true,
          response: {
            Message: null,
            Type: null
          }
        }), () => {
          form = new FormData();
          form.append("email", emailRef.current.value);
          form.append("code", state.code);
          form.append("password", passRef.current.value);
          axios
            .post(`${config.get('apiUrl')}/Main/do_password_reset`, form)
            .then(response => {
                let data = response.data;
                setState(prevState => ({
                  ...prevState,
                  response: data,
                  loader: false
                }), () => {
                  if(data.Type === 'Success')
                {
                  setState(prevState => ({
                    ...prevState,
                    phase: 4,
                    isfinished: true
                  }));
                }
                });
            })
            .catch(ex => {

            });
        });
        
        break;

      case 4:
        setState(prevState => ({
          ...prevState,
          navigate: true
        }));
        break;
    }
  }

  const buttonclicked2 = () =>
  {
    let form;
    switch(state.phase)
    {
      case 1:
        setState(prevState => ({
          ...prevState,
          loader: true,
          response: {
            Message: null,
            Type: null
          }
        }), () => {
          form = new FormData();
          form.append("email", emailRef.current.value)
          axios
            .post(`${config.get('apiUrl')}/Main/request_password_reset`, form)
            .then(response => {
                let data = response.data;
                setState(prevState => ({
                  ...prevState,
                  response: data,
                  loader: false
                }), () => {
                  if (data.Type === 'Success')
                {
                  setState(prevState => ({
                    ...prevState,
                    loader: false,
                    phase: 2,
                    showphase2: true,
                    buttontext: 'Verify Code'
                  }));
                }
                });
            })
            .catch(ex => {
              
            });
        });
        
        break;

      case 2:
        setState(prevState => ({
          ...prevState,
          loader: true,
          response: {
            Message: null,
            Type: null
          }
        }), () => {
          form = new FormData();
          form.append("email", emailRef.current.value);
          form.append("code", state.code);
          axios
            .post(`${config.get('apiUrl')}/Main/test_password_reset`, form)
            .then(response => {
                let data = response.data;
                setState(prevState => ({
                  ...prevState,
                  response: data,
                  loader: false
                }), () => {
                  if(data.Type === 'Success')
                  {
                    setState(prevState => ({
                      ...prevState,
                      loader: false,
                      phase: 3,
                      showphase3: true,
                      buttontext: 'Update Password'
                    }));
                  }
                });
            })
            .catch(ex => {
  
              });
        });
      break;

      case 3:
        setState(prevState => ({
          ...prevState,
          loader: true,
          response: {
            Message: null,
            Type: null
          }
        }), () => {
          form = new FormData();
          form.append("email", emailRef.current.value);
          form.append("code", state.code);
          form.append("password", passRef.current.value);
          axios
            .post(`${config.get('apiUrl')}/Main/do_password_reset`, form)
            .then(response => {
                let data = response.data;
                setState(prevState => ({
                  ...prevState,
                  response: data,
                  loader: false
                }), () => {
                  if(data.Type === 'Success')
                {
                  setState(prevState => ({
                    ...prevState,
                    phase: 4,
                    isfinished: true
                  }));
                }
                });
            })
            .catch(ex => {

            });
        });
        
        break;

      case 4:
        setState(prevState => ({
          ...prevState,
          navigate: true
        }));
        break;
    }
  }

  if(state.navigate)
  {
    return <Navigate to="/login" />
  }

  return (
    <>
    <div className='container-fluid relate bodystyle-new'>
    <Particles className="particlesdiv" id="tsparticles" init={particlesInit} options={ParticlesSetup}/>
      <div className='container px-0 pt-5 pb-5'>
        <div className='row'>
          <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
            <div className='card gradient-bg shadow'>
              <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
                <div className="text-primary h5">FORGOT PASSWORD</div>
              </div>
              <div className='card-body pb-5'>
                <div className='container'>
                  {state.isfinished === false ? (
                    <React.Fragment>
                      <div className='form-group'>
                        <label htmlFor="email" className="rem-125">Enter your registered email address, you will receive a reset password code via email</label>
                        <input disabled={state.showphase2} type="email" className="form-control" id="email" ref={emailRef} required
                          onKeyDown={(e) => {
                            if(e.keyCode === 13)
                              buttonclicked();
                          }}
                        />
                      </div>
                      {state.showphase2 === true ? (
                        <div className='form-group'>
                          <label htmlFor="email">6-Digit Code</label>
                          <CurrencyFormat
                            className='form-control text-center'
                            type='tel'
                            format="######"
                            mask="_"
                            required
                            onBlur={(e) => {
                              let code = codechanged(e);
                              setState(prevState => ({
                                ...prevState,
                                code: code
                              }));
                            }}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13)
                              {
                                let code = codechanged(e);
                                setState(prevState => ({
                                  ...prevState,
                                  code: code
                                }), (currentState) => {
                                  buttonclicked(currentState);
                                });
                              }
                            }}
                            disabled={state.showphase3}
                          />
                        </div>
                      ) : ""}
                      {state.showphase3 === true ? (
                        <div className='form-group'>
                          <label>New Password</label>
                          <div className="input-group">
                            <input type={state.passwordType} className='form-control' id='password' ref={passRef} required
                              onKeyDown={(e) => {
                                if(e.keyCode === 13)
                                  buttonclicked();
                              }}
                            />
                            <div className='input-group-append'>
                              <label className='btn btn-outline-secondary' onClick={(e) => {
                                if(state.passwordType == 'text')
                                  setState(prevState => ({...prevState, passwordType: "password"}));
                                else
                                setState(prevState => ({...prevState, passwordType: "text"}));
                              }}
                              title={state.passwordType ? (state.passwordType == "text" ? "Hide Password" : "Show Password") : ""}>
                              {state.passwordType ? (state.passwordType == "password" ? show : hide) : ""}
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : ""}
                      <button disabled={state.loader} type='submit' className='btn btn-cta d-block mx-auto mt-3' onClick={(e) => {
                        if (true)
                        {
                          buttonclicked2(state.code);
                        }
                      }}>
                        {state.buttontext}
                      </button>
                      {state.response.Message !== null ? (
                        <div className='row mt-2'>
                          <div className='col-12 text-center color-red'>
                            {state.response.Message}
                          </div>
                        </div>
                      ) : ""}
                      <div className='row'>
                        <div className='col-12 text-center'>
                          {state.loader === true ? (
                            <img src={loader} style={loaderStyle}/>
                          ) : ""}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='row mt-2'>
                        <div className='col-12 text-center'>
                          {state.response.Message}
                        </div>
                      </div>
                      <button type='submit' className='btn btn-cta d-block mx-auto mt-3' onClick={buttonclicked}>
                        Login
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
        </div>
      </div>
    </div>
    </>
  )
}

export default ForgotPassword