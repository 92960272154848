import React from 'react';
import { Link } from "react-router-dom";
import Assistance from "./Assistance";

function R02(props) {

    const prevClicked = (e) =>
    {
        props.goToStep(2);
    }

  return (
    <>
    <div className="container">
        If the account you wish to register for our online service is not under your name,
        we encourage you to apply for a <Link to="/change-of-name" className="hyperlink">Change of Name Request</Link>. <br /><br />
        <Assistance />
        <div className="row">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <button className="btn btn-registration" onClick={prevClicked}>
                    {props.prevIcon} BACK
                </button>
            </div>
            <div className="col-md-9 col-lg-9 col-xl-9">
            </div>
        </div>
    </div>
    </>
  )
}

export default R02