const Cryptr = require('cryptr');
const cryptr = new Cryptr('secret key 123', { pbkdf2Iterations: 10000 });	

export function encrypt(val, x = 0)
{
	let temp = '';
	if(x === 0)
	{
		for (var i = 0 ; i < val.length ; i++)
			temp += String.fromCharCode(val.charCodeAt(i) ^ val.length);		
	}
	else
	{
		if(x === 1)
			temp = cryptr.encrypt(val);
		else
			temp = cryptr.decrypt(val);
	}
	return temp;
}