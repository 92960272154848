import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Pic from "./image/404.png";
import "./css/Error404.css";
import "../AboutComponents/css/contentstyle.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import ParticlesSetup from "../AboutComponents/js/particlesjs-config.json";

const size = {
  height: "93px",
  width: "273px",
  marginLeft: "auto",
  marginRight: "auto",
  display: "block",
};

const particlesInit = async (main) => {
  console.log(main);
  await loadFull(main);
};

function Error404() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="bodystyle-new">
		<Particles
          className="particlesdiv"
          id="tsparticles"
          init={particlesInit}
          options={ParticlesSetup}
        />
      <div className="container-fluid relate row">
        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
        <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
          <div className="bodyStyle" style={{ paddingTop: "96px" }}>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-12 col-lg-3 col-xl-3"></div>
              <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                <img src={Pic} style={size} />
              </div>
              <div className="col-sm-12 col-md-3 col-12 col-lg-3 col-xl-3"></div>
            </div>
            <div className="row text-justify">
              <div className="col-sm-12 col-md-3 col-12 col-lg-3 col-xl-3"></div>
              <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                Sorry but the page{" "}
                <strong style={{ fontVariant: "none" }}>
                  {window.location.href}
                </strong>{" "}
                does not exist, have been removed, name changed or is temporary
                unavailable. If you think this page should work, try clearing
                your browser's cache and try again.
              </div>
              <div className="col-sm-12 col-md-3 col-12 col-lg-3 col-xl-3"></div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-12 col-lg-3 col-xl-3"></div>
              <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                <br />
                <span>You may try to access the following:</span>
                <ul>
                  <li className="text-list">
                    <Link className="error-link" to="/">
                      Homepage
                    </Link>
                  </li>
                  <li className="text-list">
                    <Link className="error-link" to="/news">
                      News
                    </Link>
                  </li>
                  <li className="text-list">
                    <Link className="error-link" to="/advisories">
                      Advisories
                    </Link>
                  </li>
                  <li className="text-list">
                    <Link className="error-link" to="/rate-archives">
                      Rate Archives
                    </Link>
                  </li>
                  <li className="text-list">
                    <Link className="error-link" to="/register">
                      Registration
                    </Link>
                  </li>
                  <li className="text-list">
                    <Link className="error-link" to="/collection-offices">
                      Bills Payment
                    </Link>
                  </li>
                  <li className="text-list">
                    <Link className="error-link" to="/terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-3 col-12 col-lg-3 col-xl-3"></div>
            </div>
          </div>
        </div>
        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
      </div>
    </div>
  );
}

export default Error404;
