import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/BillsPaymentComponents.css";
import paymaya from './image/PAYMAYA.png';
import gcash from './image/GCASH.png';
import acash from './image/ACASH.png';
import onlineinfo from './image/ONLINE-INFO.png';
import clickhere from './image/CLICK HERE.png';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

function CollectionOffices () {

	return (
		<div className="container-fluid relate">
				<Particles className="particlesdiv"
				id="tsparticles"
				init={ParticlesInit}
				options={ParticlesSetup}
				/>
			<div className="container px-0 pt-5 pb-5">
			<div className="card relate box-shadow card-contact">
					<div className="row">
					<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<img src={paymaya} className="payment-options-img" />
						<img src={gcash} className="payment-options-img" />
						<img src={acash} className="payment-options-img" />
						<img src={onlineinfo} className="payment-options-img" />
						<Link to="/contact-us"><img src={clickhere} className="payment-options-img" /></Link>
					</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CollectionOffices;
