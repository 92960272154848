import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import config from 'react-global-configuration';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import CurrencyFormat from "react-currency-format";
import Tooltip from "react-tooltip-lite";
import accountno from "../../RegisterForm/image/accountno.jpg";
import Assistance from "./Assistance";
import "../../RegisterForm/css/RegisterForm.css";
import { useStateWithCallbackLazy } from "use-state-with-callback";

const imgHint = <FontAwesomeIcon icon={faInfoCircle} />;
const imgAccountno = <img src={accountno} alt="accountno" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} />;

const tooltipStyle = {
	padding: ".375rem .75rem", 
	color: "#6c757d"
};

const smallSize = {
	fontSize: "11px",
};

function MainPage (props) {

	const [state, setState] = useStateWithCallbackLazy({
		accountno: '',
		isBusy: false,
		response: {
			Message: '',
			Type: ''
		}
	});

	const nextClicked = (e) =>
	{
		check_account(state.accountno);
	}

	const check_account = (accountno) =>
	{
		if(true)
		{
			if(state.isBusy === true) return;
			if(accountno.length !== 7 || countString(accountno, "_") !== 0)
			{
				setState(prevState => ({
					...prevState,
					accountno: '',
					response: {
						Message: 'Account number must be 7-Digit',
						Type: 'Error'
					}
				}));
				return;
			}
			setState(prevState => ({
				...prevState,
				isBusy: true,
				response: {
					Message: "Validating account number, please wait...",
					Type: 'Info'
				}
			}));
			const formData = new FormData();
			formData.append("accountno", accountno);
			axios
				.post(config.get('apiUrl') + '/Main/check_account_number', formData)
				.then(response => {
					let data = response.data;
					setTimeout(() => {
						setState(prevState => ({
							...prevState,
							isBusy: false,
							response: {
								Message: "",
								Type: ''
							}
						}), () => {
							if(data.Type === 'Success')
							{
								let rate = data.Rate;
								props.handleState('accountNo', data.AccountNo);
								props.handleState('name', data.Name);
								props.handleState('rate', rate);
								props.handleState('em', data.EM);
								rate = rate.replace(" ", "");
								if(rate === 'R')
									props.goToStep(2);
								else
								{
									if (rate === 'SC')
										props.goToStep(6);
									else
										props.goToStep(13);
								}
								return;
							}
							setState(prevState => ({
								...prevState,
								response: data
							}));
						});
					}, 1300)
					
					
				})
				.catch(ex => {
	
				});
		}
		else
			setState(prevState => ({
				...prevState,
				response: {
					Message: "You must agree to Website use of cookies first",
					Type: ''
				}
				}));
	}
  
	const setaccountno = (event) =>
	{
	  const accountnumber = event.target.value;
	  if(accountnumber.length == 7 && countString(accountnumber, "_") == 0)
	  {
			setState(prevState => ({
				...prevState,
				accountno: accountnumber
				}));
	  }
	  else
		setState(prevState => ({
			...prevState,
			accountno: ''
			}));
	}

	const countString = (str, strToCount) =>
	{
		var result = 0, i = 0;
		for(i; i<str.length; i++)
		{
			if(str[i] == strToCount)
				result++;
		}
  
		return result;
	}

		return(
			<React.Fragment>
				<div className="relate bodystyle-register">
					<h6 className="rem-125">Enter your 7-digit CELCOR Account Number and click NEXT to continue</h6>
					<div className="input-group">
						<CurrencyFormat 
							className="form-control text-center"
							type="tel"
							format="#######" 
							mask="_"
							required
							onBlur={setaccountno}
							onKeyDown={(e) => {
								setState(prevState => ({
									...prevState,
									response: {
										Message: '',
										Type: ''
									}
									}));
								if(e.keyCode === 13)
								{
									e.target.blur();
									check_account(e.target.value);
								}
							}}
						/>
						<Tooltip
							direction="left"
							content={(
								<div style={{maxWidth: "100%", width: "280px"}}>
									<p style={smallSize}>Your account number is the 7-digit number found on your bill.</p>
									{imgAccountno}
								</div>
							)}>
							<label style={tooltipStyle}>{imgHint}</label>	
						</Tooltip>
					</div>
					<div className="text-center font-weight-bold">
						<span>{state.response.Message}</span>
					</div>
					{state.response.Type === 'Error' ? (
						<Assistance />
					) : ""}
					<div className="row pt-3">
						<div className="col-md-9 col-lg-9 col-xl-9"></div>
						<div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<button className="btn btn-registration" onClick={nextClicked}>
							NEXT {props.nextIcon}
						</button>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
}
export default MainPage;