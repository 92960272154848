import React from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-tooltip-lite";
import "../../RegisterForm/css/RegisterForm.css";
import kwh from "../../RegisterForm/image/kwh.jpg";
import duedate from "../../RegisterForm/image/duedate.jpg";

const next = <FontAwesomeIcon icon={faArrowRight} />;
const imgHint = <FontAwesomeIcon icon={faInfoCircle} />;
const imgkwh = <img src={kwh} alt="kwh" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} />;
const imgduedate = <img src={duedate} alt="duedate" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} />;

const tooltipStyle = {
	padding: ".375rem .75rem", 
	color: "#6c757d"
};

const smallSize = {
	fontSize: "11px",
};

function R03(props) {

  const prevClicked = (e) =>
  {
    props.goToStep(2);
  }

  const nextClicked = (e) =>
  {
    props.goToStep(5);
  }

  return (
		<>
		<div className='container'>
			Provide the necessary requirements in order to complete the registration
			<ul className='circle'>
				<li>One (1) <Link className="hyperlink" target="_blank" to="/files/Primary IDs in the Philippines.pdf" download>Primary ID</Link> or two (2) <Link className="hyperlink" target="_blank" to="/files/Secondary IDs and Supporting Documents in the Philippines.pdf" download>Secondary IDs</Link> of the account holder</li>
				<li>
					Enter the <span className="hyperlink-nocursor">Total kWh Consumption</span> from the past two (2) months as stated on your Statement of Account or Electric Bill. 
					<Tooltip
						direction="left"
						className="tooltip-inline"
						content={(
						<div style={{maxWidth: "100%", width: "195px"}}>
							<p style={smallSize}>Your kWh can be found on your bill</p>
							{imgkwh}
						</div>
						)}>
						<label style={tooltipStyle}>{imgHint}</label>	
					</Tooltip>
				</li>
				<li >
					Enter the <span className="hyperlink-nocursor">Current Due Date</span> as stated on your Statement of Account or Electric Bill. 
					<Tooltip
						direction="left"
						className="tooltip-inline"
						content={(
						<div style={{maxWidth: "100%", width: "200px"}}>
							<p style={smallSize}>Your due date can be found on your bill</p>
							{imgduedate}
						</div>
						)}>
						<label style={tooltipStyle}>{imgHint}</label>	
					</Tooltip>
				</li>
				<li > Email Address </li>
				<li> Mobile Number </li>
			</ul>
			<div className="row">
				<div className="col-12">
					<i>Note: Once completed, click NEXT to continue the registration</i>
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<button className="btn btn-registration" onClick={prevClicked}>
						{props.prevIcon} BACK
					</button>
				</div>
				<div className="col-md-6 col-lg-6 col-xl-6 mt-3"></div>
				<div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<button className="btn btn-registration" onClick={nextClicked}>
						NEXT {props.nextIcon}
					</button>
				</div>
			</div>
		</div>
		</>
  )
}

export default R03