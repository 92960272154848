import React,{ useState, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import Navbar from '../../../components/Navbar/Navbar';
import ChangeAccount from "../../../components/Account/AccountChange/AccountChange";

function AccountChange() {

  const [state,setState] = useState ({
    redirect: false
  });

  useEffect (() => {
    document.title = "CELCOR | Change Account";

    if (localStorage.getItem("userData"))
    {
    }
    else
    {
      setState({ redirect: true });
    }
  }, []);

  if (state.redirect) {
    return <Navigate to={"/login"} />;
  }

  return (
    <>
    <Navbar />
    <div className="container-fluid h-100">
      <div className="row h-100">
        <main className="col">
        <ChangeAccount />
        </main>
      </div>
    </div>
    </>
  )
}

export default AccountChange