import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../../AboutComponents/css/contentstyle.css";
import "./css/AccountChange.css";
import { Navigate } from 'react-router-dom';
import loader from "../../../loader.svg";
import config from "react-global-configuration";
import { encrypt } from '../../../Common';
import Particles from "react-tsparticles";
import ParticlesSetup from '../../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from '../../AboutComponents/js/particles.init';

const loaderStyle = {
	height: "150px",
	width: "150px"
};

const BodyStyle = {
  paddingTop: "96px"
};

function AccountChange() {

  const [state, setState] = useState ({
    serverValidationError: null,
    identification: [],
    width: 484,
    redirect: false,
    redirectTo: ""
  });

  useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		
    if (localStorage.getItem("userData"))
		{
			setState(prevState => ({
				...prevState,
				width: localStorage.getItem("x")
			}));
			const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));

			if(userData[0].TradeId != 3)
			{
				localStorage.setItem("idx", 0);
				setState(prevState => ({
					...prevState,
					redirect: true,
					redirectTo: "/dashboard"
				}));
				return;
			}

			const token = encrypt(localStorage.getItem("Token"));
			const formData = new FormData();
			formData.append("cust_id", userData[0].Id);
			formData.append("token", token);
			
			setState(prevState => ({
				...prevState,
				serverValidationError: "Please wait..."
			}));
			
			axios
				.post(config.get('apiUrl') + "/Home/accountIdentification", formData)
				.then(response => {
					if(response.data == "0")
					{
						setState({
							serverValidationError:
							  "Authentication Failed, please log out and log in again."
						  });
					}
					else
					{
						if(response.data == "-1")
						{

						}
						else
						{
							setState(prevState => ({
								...prevState,
								identification: response.data,
								serverValidationError: null
							}));
						}
					}
				});
		}
  }, []);

  const handleClick = (e) =>
	{
		localStorage.setItem("idx", e.target.id.replace("account-", ""));
		setState(prevState => ({
			...prevState,
			redirect: true,
			redirectTo: "/dashboard"
		}));
	}

	if (state.redirect === true)
	{
		return <Navigate to={state.redirectTo} />
	}

  return (
    <>
    <div className="bodyStyle relate change-account-body bodyStyleIdentification bodystyle-min-height" style={BodyStyle}>
        <Particles className="particlesdiv"	id="tsparticles" init={ParticlesInit}	options={ParticlesSetup}/>
				{state.serverValidationError == null ? (
					<React.Fragment>
						<div className="row">
							<div className="col-md-2 col-lg-2 col-xl-2"></div>
							<div className="col-sm-12 col-md-12 col-md-8 col-lg-8 col-xl-8">
								<div className="card gradient-bg shadow mb-3">
									<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between consumption-header">
										<div className="text-primary h5">Select an account below that you want to view</div>
									</div>
									<div className="card-body" style={{fontFamily: 'Chivo'}}>
										<div className="list-group">
										{state.identification && state.identification
											.map(item => {
												return(
													<div className={`account-item list-group-item list-group-item-action`} id={`account-${item.X}`} onClick={handleClick}>{item.AccountNo} — {item.Name}</div>
												)
											})
										}
										</div>		
									</div>
								</div>
							</div>
							<div className="col-md-2 col-lg-2 col-xl-2"></div>
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className="row">
							<div className="col-12 text-center h5">
								{state.serverValidationError !== 'Authentication Failed, please log out and log in again.' ? (
										<React.Fragment>
											<img src={loader} style={loaderStyle} alt="Please wait loader from loader.io" /> 
											<br />
											{state.serverValidationError}
										</React.Fragment>
									) : (<React.Fragment>{state.serverValidationError}</React.Fragment>
								)}
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
    </>
  )
}

export default AccountChange