import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./css/TermsConditions.css";

function TermsConditions () {
    return (
		<div className="row">
			<div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
			<div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
				<div className="bodyStyle" style={{paddingTop: "96px"}}>
					<h1 className="fo-second font-weight-bold text-center text-success terms-header">
						Terms and Conditions
					</h1><br />
					<div className="row">
						{/*<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="card box-shadow" id="cardboxshadow">
								<div className="card-body">
									//put content here
								</div>
							</div>
						</div>*/}
						<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-justify">
							<p><strong>WELCOME TO THE CELCOR Website and The Service</strong></p>
							<p>
								Please read these <strong>Terms and Conditions</strong> carefully before accessing and using the <a className="terms-link" href="#">https://www.celcor.com.ph</a> operated by Cabanatuan Electric Corporation (CELCOR)
							</p>
							<p>
								Your use of and access to the Service is subject to your acceptance if and compliance with these Terms
								and Conditions which shall apply to all visitors, users and others who access or use the Service. Once
								you access and use the CELCOR Website and the Service, you shall deemed to have agreed to be have agreed to be
								bound by these Terms and Conditions. If you do not accept any part of these Terms and Conditions,
								then you may not access and/or use the Service.
							</p>
							<p>
								The term “Registered Customer” refers to the person under whose name an electric account is
								registered. On the other hand, the term “Actual User” refers to the person authorized by the
								Registered Customer to occupy the premises covered by his/her electric account and enjoy the electric
								service and can refer to the Registered Customer if he/she is the one who actually occupying said premises
								and enjoying the electric service.
							</p>
							<p><strong>GENERAL TERMS AND CONDITIONS:</strong></p>
							<ol>
								<li>
									CELCOR expressly reserves its right to amend or modify these Terms and Conditions
									withour prior notice and to modify, suspend or permanently remove any aspect or
									feature of the CELCOR Website and the Service. If a revision is material, CELCOR will
									try to provide at least 30 days' notice prior to any new terms taking effect. The
									determination as to which revision constitutes a material change is left solely to the
									discretion of CELCOR.
								</li>
                                <br/>
								<li>
									The Service allows you to post, link, share and otherwise make available certain
									information, text, graphics, videos or other material (your content). By uploading
									your content in the CELCOR Website and/or the Service, you shall deemed to
									have given CELCOR a non-exclusive, royalty-free, and irrevocable licese to use and
									reproduce it for its legitimate business purposes subject to its compliance with the
									applicable laws, rules, and regulations, particularly thosese relating to Data Privacy.
								</li>
                                <br/>
								<li>
									CELCOR owns all the rights to the intellectual properties and materials (CELCOR
									Content) contained in the CELCOR Website and the Service, including, but not
									limited to, the design, layout, and graphics. The CELCOR Content are protected by
									copyright, trademark and other laws relating to intellectual property rights and
									cannot be used without the prior consent of CELCOR.
								</li>
                                <br/>
								<li>
									You expressly commit to be fully responsible in the access and/or use of the CELCOR
									Website and the Service. Accordingly, you shall not use the same in a manner that
									damages the reputation and goodwill of CELCOR such as by uploading obscene
									vulgar, pornographic materials and those that are offensive to moral, religious and
									racial sensibilities and/or cause any harm or damage to any third party. You shall
									also not, thought the use of CELCOR Website and the Service, engage in
									advertising and/or marketing or any activity that is prohibited under the <a className="terms-link" href="https://www.privacy.gov.ph/data-privacy-act" rel="noopener noreferrer" target="_blank">Data
									Privacy Act of 2012 (Rep. Act No. 10173)</a>.
								</li>
                                <br/>
								<li>
									You shall not perform any act that can damage the CELCOR Website and the Service
									or cause the same to “crash” or malfunction such as by uploading files or posting
									links with malicious content (e.g. malware) or by uploading very large files or
									multiple files.
								</li>
                                <br/>
								<li>
									You shall not use any device or software other than the third-party internet
									browsers that are generally available and the search engine that may be made
									available by CELCOR.
								</li>
                                <br/>
								<li>
									CELCOR reserves the right to restrict access of any person to the CELCOR
									Website and the Service or any part hereof, at any time and in its sole discretion.
								</li>
                                <br/>
								<li>
									CELCOR does not make any warranty and/or representation or any kind related to
									the CELCOR Website and the Service and all Contents displayed and contained
									therein, including any information and data related thereto that are made available
									through the Online Services. You acknowledge that the CELCOR Content may
									contain inaccuracies or errors in some cases and CELCOR expressly disclaims any
									liability for such inaccuracies or errors to the fullest extent allowed by law.
								</li>
                                <br/>
								<li>
									The Service may contain links to third-party websites or services that are not owned
									or controlled by CELCOR, which are provided only for your convenience and further
									information. The latter has no control over, and assumes no responsibilty for the
									content, privacy policies, or practices of any third-party websites or services. You
									further acknowledge and agree that CELCOR shall not be liable, directly or indirectly,
									for any damage or loss caused or alleged to be caused by or in connection with the
									use of or reliance on any such content, goods or services available on or through any
									such websites or services.
								</li>
                                <br/>
								<li>
									You acknowledge CELCOR, its directors, stockholders, officers, personnel, and
									employees shall not be liable to you in any manner for any damage or loss that you
									may incur in the course of your use and/or access of the CELCOR Website and the
									Service.
								</li>
                                <br/>
								<li>
									By viewing, accessing and using any data relating to the electric service account of
									the Registered Customer, you shall be deemed to have warranted that you have
									secured the express consent of the Registered Customer to access said account and
									to perform any transaction int the CELCOR Website and the Service relating to
									his/her electric service account in his/her behalf.
								</li>
                                <br/>
								<li>
									CELCOR shall not assume any responsibilty for any damages to, or any viruses that
									may damage your Computer or mobile or access device as a result of your access to
									and/or use of the CELCOR Website and the Service.
								</li>
                                <br/>
								<li>
									Any violation of any of these Terms and Conditions, which is subject to Philippine
									law, shall entitle CELCOR the right to terminate your right to access and use the
									CELCOR Website and the Service.
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
		</div>
    );
}

export default TermsConditions;