import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/CELCOR Engineers with LGU 1.jpg";
import Pic2 from "./image/CELCOR Engineers with LGU 2.jpg";
import Pic3 from "./image/CELCOR Engineers with LGU 3.jpg";
import Pic4 from "./image/CELCOR Engineers with LGU 4.jpg";

function Content () {
    return(
        <React.Fragment>
            <div className="text-justify">
                    April 25, 2023 - CELCOR Engineers held a meeting with LGU-Cabanatuan City Building Officials to
                    strengthen their coordination regarding the implementation of the Republic Act No. 11361 or the Anti-
                    Obstruction of Powerlines Act.
                    <br /><br />
                    CELCOR Regulatory Officer and Engineering & Design Services Department Head, Engr. Bernie Laguni
                    along with his personnel, Engr. Eric Ramirez and Engr. Ibrahim Ayezuddin led the discussion on the
                    implementing rules and regulations (IRR) of the R.A. 11361 particularly the follwing sections:
                    <ol type="a">
                        <li> Prescribed minimum safety clearance between powerlines and structures/buildings,</li>
                        <li> Responsibilities and obligations of CELCOR being the Powerline Operator/Owner (PLO) and the
                              Office of the City Building Officials (OCBO) performing its mandate to enforce the National Building Code and,
                        </li>
                        <li> Current process undertaken by both parties with respect to their function based on R.A. 11361</li>
                    </ol>
                    LGU- Cabanatuan City Officials - Engr. Nory Garcia, Mr. Luisito Palomo and Marjorie Pascual, tackled their
                    team's process of inspection and issuance of permit in accordance to RA 11361 as well.
                    <br /><br />
                    Pursuant to Section 14 of the R.A. 11361, the building owner who will be undertaking construction or
                    maintenance activities surrounding, adjacent with or proximate to the Powerline Corridor shall give notice
                    to and coordinate with the PLO for the issuance of the PLO Certificate prior to the application for the
                    issuance of a building permit.
                    <br /><br />
					(Photo taken: April 25, 2023 by J. Legaspi)
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic1} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic2} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic3} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic4} />
					</div>
				</div>
        </React.Fragment>
    )
	
}

export default Content;