import React from "react";
import "./css/HeroOfficeSchedule.css";

function HeroOfficeSchedule () {
    return (
        <>
        <div className="div-nothing"></div>
			<div>
				<div className="container-fluid container-hero-office text-center d-flex align-items-center justify-content-center">
				<p className="hero-title text-raised mt-5 hero-title">Office Schedule</p>
				</div>
			</div>
        </>
    );
}

export default HeroOfficeSchedule;