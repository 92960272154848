import React from "react";
import { Link } from "react-router-dom";
import './css/LatestNews.css';
import pic1 from '../../pages/NewsAdvisories/NewsContents/CELCORJoinsCabanatuanCityBarangayCaptains/image/CELCOR joins Cabanatuan City Association of Barangay Captains Meeting to discuss Lifeline Rate - 1.JPG';
import pic2 from '../../pages/NewsAdvisories/NewsContents/CELCORSeeksAssistanceFromBarangay/image/CELCOR seeks assistance from Barangay for Lifeline Rate Subsidy Application 2.jpg';
import pic3 from '../../pages/NewsAdvisories/NewsContents/CELCORFeedingProgram2023Accfa/image/CELCOR joins Feeding Program for underweight children in Barangay San Juan Accfa 2.jpg';
import pic4 from '../../pages/NewsAdvisories/NewsContents/CELCOREngineersWithLGU/image/CELCOR Engineers with LGU 3.jpg';
import pic5 from '../../pages/NewsAdvisories/NewsContents/CELCORSignsDataSharing/image/CELCOR Signs Data Sharing 1.png';
import pic6 from '../../pages/NewsAdvisories/NewsContents/CELCOREngineersWithLGU/image/CELCOR Engineers with LGU 1.jpg';
import pic7 from '../../pages/NewsAdvisories/NewsContents/NGCPConductMaintenance2023/image/NGCP Conducts Maintenance 1.jpg';

const news = {
	links: {
		link1: `/news/celcor-joins-cabanatuan-city-association-of-barangay-captains-meeting-to-discuss-lifeline-rate`,
		link2: `/news/celcor-seeks-assistance-program-from-barangays-for-lifeline-rate-subsidy-application`,
		link3: `/news/celcor-joins-feeding-program-for-underweight-children-in-barangay-san-juan-accfa`,
		link4: `/news/celcor-prepares-for-lifeline-rate-implementation`,
		link5: `/news/celcor-signs-data-sharing-agreement-with-erc`,
		link6: `/news/celcor-engineers-with-lgu-cabanatuan-city-building-officials`,
		link7: `/news/ngcp-conducts-annual-maintenance-program-in-cabanatuan-city`
	},
	images: {
		image1:  <img className="img-fluid" src={pic1} />,
		image2:  <img className="img-fluid" src={pic2} />,
		image3:  <img className="img-fluid" src={pic3} />,
		image4:  <img className="img-fluid" src={pic4} />,
		image5:  <img className="img-fluid" src={pic5} />,
		image6:  <img className="img-fluid" src={pic6} />,
		image7:  <img className="img-fluid" src={pic7} />,
	}
}

function LatestNews() {
    return(
        <React.Fragment>
            <div className="blog_card">
							<div id="newsCarousel" className="newsCarousel carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
								<div className="tmp">
									<div className="carousel-inner carousel-inner-new">
									<div className="carousel-item carousel-item-new active">
											<Link style={{textDecoration: 'none'}} to={news.links.link1}>
												{news.images.image1}
												<h3 className="text-overlay line-count-3 line-count-4">CELCOR JOINS CABANATUAN CITY ASSOCIATION OF BARANGAY CAPTAINS' MEETING TO DISCUSS LIFELINE RATE</h3>
											</Link>
										</div>
										<div className="carousel-item carousel-item-new">
											<Link style={{textDecoration: 'none'}} to={news.links.link2}>
												{news.images.image2}
												<h3 className="text-overlay line-count-3 line-count-4">CELCOR SEEKS ASSISTANCE FROM BARANGAYS FOR LIFELINE RATE SUBSIDY APPLICATION</h3>
											</Link>
										</div>
										<div className="carousel-item carousel-item-new">
											<Link style={{textDecoration: 'none'}} to={news.links.link3}>
												{news.images.image3}
												<h3 className="text-overlay line-count-3 line-count-4">CELCOR JOINS FEEDING PROGRAM FOR UNDERWEIGHT CHILDREN IN BARANGAY SAN JUAN ACCFA</h3>
											</Link>
										</div>
										<div className="carousel-item carousel-item-new">
											<Link style={{textDecoration: 'none'}} to={news.links.link4}>
												{news.images.image4}
												<h3 className="text-overlay line-count-2">CELCOR PREPARES FOR LIFELINE RATE IMPLEMENTATION</h3>
											</Link>
										</div>
										<div className="carousel-item carousel-item-new">
											<Link style={{textDecoration: 'none'}} to={news.links.link5}>
												{news.images.image5}
												<h3 className="text-overlay line-count-2">CELCOR SIGNS DATA SHARING AGREEMENT (DSA) WITH ERC</h3>
											</Link>
										</div>
										<div className="carousel-item carousel-item-new">
											<Link style={{textDecoration: 'none'}} to={news.links.link6}>
												{news.images.image6}
												<h3 className="text-overlay line-count-3 line-count-4">CELCOR ENGINEERS MET WITH LGU BUILDING OFFICIALS TO STRENGTHEN R.A 11361 IMPLEMENTATION</h3>
											</Link>
										</div>
										<div className="carousel-item carousel-item-new">
											<Link style={{textDecoration: 'none'}} to={news.links.link7}>
												{news.images.image7}
												<h3 className="text-overlay line-count-2 line-count-3">NGCP CONDUCTS ANNUAL MAINTENANCE PROGRAM IN CABANATUAN CITY</h3>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
        </React.Fragment>
    );
}

export default LatestNews;