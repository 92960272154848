import React, { useEffect } from "react";
import Navbar from "./../../components/Navbar/Navbar";
import MainMenu from "./../../components/MainMenu/MainMenu";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import ContentComponent from "../../components/NewsAdvisoriesComponents/OfficeAdvisories";

function OfficeAdvisories () {

  useEffect(() => {
    document.title = "CELCOR | Office Advisories";
  });

  return (
    <div className="Home">
      <Navbar />
      <HeroNews title="Office Advisories" />
      <MainMenu />
      <ContentComponent />
    </div>
  );
}

export default OfficeAdvisories;
