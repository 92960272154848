import React, {useState, useEffect} from 'react';
import './css/AccountDashboard.css';
import './../../AboutComponents/css/contentstyle.css';
import '../../NewsAdvisoriesComponents/css/NewsAdvisoriesComponents.css';
import axios from "axios";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faMoneyBillAlt, faList, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {Link, Navigate} from 'react-router-dom';
import { encrypt } from '../../../Common';
import loader from "./../../../loader.svg";
import config from 'react-global-configuration';
import Particles from "react-tsparticles";
import ParticlesSetup from '../../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from '../../AboutComponents/js/particles.init';
import billsPayment from "./image/BILLS PAYMENT.png";
import consumptionHistory from "./image/CONSUMPTION HISTORY.png";
import paperlessBilling from "./image/PAPERLESS BILLING.png";
import averia from "./image/AVERIA.png";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const iconHistory = <FontAwesomeIcon icon={faHistory} />;
const iconMoney = <FontAwesomeIcon icon={faMoneyBillAlt} />;
const iconList = <FontAwesomeIcon icon={faList} />;
const iconInfo = <FontAwesomeIcon icon={faInfoCircle} />;

const images = {
	bills: <img className="overview-button-img img-fluid" src={billsPayment} />,
	consumption: <img className="overview-button-img img-fluid" src={consumptionHistory} />,
	paperless: <img className="overview-button-img img-fluid" src={paperlessBilling} />,
	averia: <img className="overview-button-img img-fluid" src={averia} />	
}

const loaderStyle = {
	height: "150px",
	width: "150px"
};

const BodyStyle = {
  /* minHeight: "448px", */
  paddingTop: "96px",
};

const smallSize = {
	fontSize: "14.5px",
}

const fieldSet = {
	fontSize: "18px"
}

const midSize = {
	fontSize: "18px",
	color: "black"
}

const bigSize = {
	fontSize: "1.75em",
	color: "black",
	marginBottom: "20px"
}

const detailedHeader = {
	/* fontWeight: "bold", */
	marginBottom: "0px"
}

const detailedItem = {
	fontSize: "1.5em",
	color: "black"
}

const balanceDetailsHeader = {
	/* fontWeight: "bold", */
	marginBottom: "0px"
}

function Status(props) {
	return <span>{props.status}</span>
		if(props.status === "Active") return <span style={{color: "#017E49"}}>{props.status}</span>
		else return <span style={{color: "#FF5151"}}>{props.status}</span>
}

function AccountDashboard() {

  const [state, setState] = useStateWithCallbackLazy ({
    redirect: false,
	  serverValidationError: null,
	  overview: [],
	  latestconsumption: {},
	  latestpayment: {},
	  width: 484,
	  total: 0,
	  current: 0,
	  previous: 0
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if(!localStorage.getItem('idx'))
    {
      setState(prevState => ({...prevState, redirect: true}));
      return;
    }

		if(localStorage.getItem("userData"))
		{
			setState(prevState => ({...prevState, width: localStorage.getItem("x")}));
			const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
			const token = encrypt(localStorage.getItem("Token"));
			const formData = new FormData();
			formData.append("cust_id", userData[0].Id);
			formData.append("token", token);
			formData.append("idx", localStorage.getItem("idx"));
			formData.append("limit", 1);
			formData.append("other", 1);
			setState(prevState => ({...prevState, serverValidationError: "Please wait..."}));
			
			axios
				.all([
					axios.post(config.get('apiUrl') + "/Home/paymentHistory", formData),
					axios.post(config.get('apiUrl') + "/Home/accountOverview", formData)
				])
				.then(
					axios.spread((...responses) => {
						setState(prevState => ({
							...prevState,
							latestpayment: responses[0].data[0]
						}));
						let response = responses[1];
						if(response.data == "0")
						{
							setState(prevState => ({
								...prevState,
								serverValidationError: "Authentication Failed, please log out and log in again."								
							}));
						}
						else
						{
							if(response.data == "-1" || response.data[0].Status === null)
							{
								setState(prevState => ({
									...prevState,
									serverValidationError: "Updating current balance. This might take some time, refresh your browser to check if your balance is already updated"								
								}));
							}
							else
							{
								setState(prevState => ({
									...prevState,
									overview: response.data,
									serverValidationError: null
								}), (currentState) => {
									let data = currentState.overview[0];
									let	total = Number(data.Balance).toFixed(2)
									let current = Number(data.Current).toFixed(2);
									let previous = Number(data.Previous).toFixed(2);

									setState(prevState => ({
										...prevState,
										total: total,
										current: current,
										previous: previous
									}))
								});
							}
						}
					})
				)
				.catch (error => {
					setState(prevState => ({
						...prevState,
						serverValidationError:
						"Error getting Dashboard! Please Contact Technical Support."								
					}));
				})
		}
  }, []);

  const classType = (type) =>
  {
    switch(type)
	  {
		  case "R":
			  return "Residential";
		  case "SC":
			  return "Small Commercial";
		  case "LC":
			  return "Large Commercial";
		  case "VL":
			  return "Very-Large Commercial";
		  case "I":
			  return "Semi-Industrial";
		  case "SI":
			  return "Small Industrial";
		  case "LI":
			  return "Large Industrial";
	 	  case "CC":
			  return "Hospital";
		  default:
			  return "";
	  }
  }

  if (state.redirect === true)
  {
    return <Navigate to="/change-account"/>
  }

  return (
    <>
		<div className="bodyStyle bodyStyleOverview bodystyle-new" style={BodyStyle}>
    <div className="container-fluid relate">
		<Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
		<React.Fragment>
		{state.serverValidationError == null ? (
			<React.Fragment>
			{Object.keys(state.overview).length > 0 && state.overview[0].Status === "Active" ? (
			<React.Fragment>
				<div className="row">
					<div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
					<div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-2">
						<div className="card gradient-bg shadow h-100">
							<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between overview-header">
								<div className="text-primary h5">Account Information</div>
							</div>
							<div className="card-body margin-left-h4-caller">
								<h4 className="h4-title">{state.overview[0].Name == undefined ? "Please wait..." : state.overview[0].Name}</h4>
								<h6 className="h6-subtitle">Account Name</h6>
								<h4 className="h4-title">{state.overview[0].AccountNo}</h4>
								<h6 className="h6-subtitle">Account Number</h6>
								<h4 className="h4-title">{state.overview[0].Address}</h4>
								<h6 className="h6-subtitle">Address</h6>
								<h4 className="h4-title">{classType(state.overview[0].Rate == null ? "" : state.overview[0].Rate.replace(" ", ""))}</h4>
								<h6 className="h6-subtitle">Account Classification</h6>
								<h4 className="h4-title">{state.overview[0].dist == null ? "N/A" : state.overview[0].dist}</h4>
								<h6 className="h6-subtitle">District</h6>
								<h4 className="h4-title">{state.overview[0].Status}</h4>
								<h6 className="h6-subtitle">Status</h6>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-2">
						<div className="card gradient-bg shadow h-100">
							<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between overview-header">
								<div className="text-primary h5">Balance Details</div>
							</div>
							<div className="card-body margin-left-h4-caller">
								<h4 className="h4-balance">
									{state.overview[0].Balance != null ? (
										<React.Fragment>
											{Number(state.total) < 0 ? (
												<React.Fragment>
													{/*New Version*/}
													<CurrencyFormat
														value={(Number(state.total) * -1).toFixed(2)}
														displayType={'text'}
														thousandSeparator={true} 
														prefix={'\u20B1-'} 
														decimalScale={2}
													/>
												</React.Fragment>
											) : (
												<React.Fragment>
													{/*Old Version*/}
													<CurrencyFormat 
														value={Number(state.total).toFixed(2)}
														displayType={'text'}
														thousandSeparator={true} 
														prefix={'\u20B1'} 
														decimalScale={2}
													/>
												</React.Fragment>
											)}
										</React.Fragment>
									) : (
										<React.Fragment>
											Please wait...
										</React.Fragment>
									)}
								</h4>
								<h6 className="h6-subtitle">Total Amount Due</h6>

								<h4 className="h4-balance">
									{state.overview[0].CurrentDueDate != null ? (
										<span style={{color: state.overview[0].Color}}>{moment(state.overview[0].CurrentDueDate).format("DD-MMM-YYYY")}</span>
									) : ""}
								</h4>
								<h6 className="h6-subtitle">Due Date</h6>

								<div className="row">
									<div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
										<h4 className="h4-balance">
											{state.overview[0].Current != null ? (
												<React.Fragment>
													<CurrencyFormat 
														value={Number(state.current).toFixed(2)}
														displayType={'text'}
														thousandSeparator={true} 
														prefix={'\u20B1'} 
														decimalScale={2}
													/>
												</React.Fragment>
											) : (
												""
											)}
										</h4>
										<h6 className="h6-subtitle">Current</h6>
									</div>
									
									<div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
										<h4 className="h4-balance">
											{state.overview[0].Previous != null ? (
												<React.Fragment>
													<CurrencyFormat 
														value={Number(state.previous).toFixed(2)}
														displayType={'text'}
														thousandSeparator={true} 
														prefix={'\u20B1'} 
														decimalScale={2}
													/>
												</React.Fragment>
											) : (
												""
											)}
										</h4>
										<h6 className="h6-subtitle">Arrears</h6>
									</div>
								</div>
								<br />
								<div className="row">
									<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={smallSize}>
										{state.overview[0].AsOf != null ? (
											<ul>
												<li>Outstanding balance as of {moment(state.overview[0].AsOf).format("MMM DD, YYYY hh:mm:ss a")}</li>
												<li>Pay your current balance on or before the stated due date above to avoid penalty or the disconnection of your electric service</li>
												<li>Outstanding balance will be updated on the next business day</li>
											</ul>
										) : ""}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
					</div>
				</div>
				<div className="row">
					<div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
					<div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-2 h-100 overview-buttons">
						<div className="row h-100">
							<Link to="/collection-offices" className="col-6">
								{images.bills}
							</Link>
							<Link to="/consumption-history" className="col-6">
								{images.consumption}
							</Link>
						</div>
						<div className="row h-100">
							<Link to="/paperless-billing" className="col-6">
								{images.paperless}
							</Link>
							<Link to="/averia" className="col-6">
								{images.averia}
							</Link>
						</div>
					</div>
					<div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-3">
						<div className="card gradient-bg shadow h-100">
							<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between overview-header">
								<div className="text-primary h5">Latest Payment Transaction</div>
							</div>
							<div className="card-body margin-left-h4-caller">
								<h4 className="h4-payment">{state.latestpayment.PaymentDate == 'N/A' ? 'N/A' : moment(state.latestpayment.PaymentDate).format("MMMM DD, YYYY hh:mm:ss A")}</h4>
								<h6 className="h6-subtitle">Payment Date and Time</h6>
								<h4 className="h4-payment">{state.latestpayment.ORNo}</h4>
								<h6 className="h6-subtitle">O.R. Number</h6>
								<h4 className="h4-payment">{state.latestpayment.Amount == 'N/A' ? 'N/A' : <CurrencyFormat value={Number(state.latestpayment.Amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20B1'} decimalScale={2} />}</h4>
								<h6 className="h6-subtitle">Amount Paid</h6>
								<h4 className="h4-payment">{state.latestpayment.TellerName}</h4>
								<h6 className="h6-subtitle">Teller Name</h6>
							</div>
						</div>
					</div>
					<div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
					</div>
				</div>
				{Number(state.overview[0].Interruption) === 1 ? (
					<div className="row">
						<div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
						<div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"></div>
						<div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-2">
							<div className="card gradient-bg shadow">
								<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
									<div className="text-primary h5">Notification</div>
								</div>
								<div className="card-body pb-5">
									Please be advised that there will be a SCHEDULED POWER INTERRUPTION on {moment(state.overview[0].Date).format('MMMM DD, YYYY')} (from {state.overview[0].Duration}) including your account.
								</div>
								<div className="bottom-link blog_card_content">
									<Link className="hyperlink" to={`/advisories`}><h6>Read More &gt;&gt;</h6></Link>
								</div>
							</div>
						</div>
						<div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
						</div>
					</div>
				) : ""}
			</React.Fragment>
			) : <div className="col-12 text-center h5">Your account is currently Inactive, go to our Main Office at Bitas, Cabanatuan City. Thank you</div>}
			</React.Fragment>
		) : (
			<React.Fragment>
				<div className="row">
					<div className="col-12 text-center h5">
						{state.serverValidationError !== 'Authentication Failed, please log out and log in again.' ? (
								<React.Fragment>
									<img
										src={loader}
										style={loaderStyle}
										alt="Please wait loader from loader.io"
									/> 
									<br />
									{state.serverValidationError}
								</React.Fragment>
							) : (<React.Fragment>{state.serverValidationError}</React.Fragment>
						)}
					</div>
				</div>
			</React.Fragment>
		)}
		</React.Fragment>
		</div>
	  </div>
    </>
  )
}

export default AccountDashboard