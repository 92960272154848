import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroInfomercials from "../../components/Hero/HeroInfomercials/HeroInfomercials";
import ContentComponent from '../../components/InfomercialsComponents/InfomercialsComponents';

function GuessWatt() {

    useEffect(() => {
        document.title = "CELCOR | Guess Watt";
    },[]);
    
    return (
        <div className="GuessWatt">
            <Navbar />
            <HeroInfomercials title="Guess Watt"/>
            <MainMenu />
            <ContentComponent code="gw" />
        </div>
    );

}
export default GuessWatt;