import React, { useState, useEffect, useRef } from "react";
import {Link, Navigate} from 'react-router-dom';
import axios from "axios";
import swal from 'sweetalert';
import "../../AboutComponents/css/contentstyle.css";
import "./css/PaperlessBillingOptOut.css";
import { encrypt } from "../../../Common";
import config from 'react-global-configuration';
import Particles from "react-tsparticles";
import ParticlesSetup from '../../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "../../AboutComponents/js/particles.init";
import { useStateWithCallbackLazy } from "use-state-with-callback";

const loaderStyle = {
	height: "150px",
	width: "150px"
};

const BodyStyle = {
	minHeight: "445px",
	paddingTop: "96px",
	marginBottom: "75px"
};

function PaperlessBillingOptOut(props) {

  const [state, setState] = useStateWithCallbackLazy({
    serverResponse: {
      type: null,
      Message: ''
    },
    redirect: false,
    isLoader: false,
    loaderMsg: '',
  });

  useEffect (() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[]);

  const emailRef = useRef(null);
  const accnoRef = useRef(null);
  const btnsubmitRef = useRef(null);

  const unsubscribeClick = (e) =>
  {
    e.preventDefault();
		if(true)
		{
			swal({
				text: "Do you want to unsubscribe to CELCOR's Paperless Billing System?",
				icon: "warning",
				dangerMode: true,
				buttons: ["No", "Yes"]
			  })
			  .then(isDelete => {
				// if(isDelete === null || isDelete === false) return;
				if(isDelete === null || isDelete === false) return;
					swal({
						title: '',
						text: 'Unsubscribed!',
						closeOnClickOutside: false,
						icon: 'success',
						timer: 2500,
						buttons: false,
					});
				setState(prevState => ({
					...prevState,
					isLoader: true,
					loaderMsg: '',
					serverResponse: {
						type: 'Error',
						Message: 'Unsubscribing to CELCOR\'s Paperless Billing System, please wait...'
					}
				}));
				const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
				const token = encrypt(localStorage.getItem("Token"));
				const formData = new FormData();
				formData.append("cust_id", userData[0].Id);
				formData.append("token", token);
				formData.append("idx", localStorage.getItem("idx"));
				formData.append("out", 1);
				axios
					.post(config.get('apiUrl') + '/Home/update_paperless_billing', formData)
					.then(response => {
						if(response.data == '0')
						{
							setState(prevState => ({
								...prevState,
								isLoader: false,
								serverResponse: {
									type: 'Error',
									Message: 'Authentication Failed, please log out then log in again'
								}
							}))
						}
						else
						{
							setState(prevState => ({
								...prevState,
								isLoader: false,
								loaderMsg: '',
								serverResponse: response.data
							}));

							setTimeout(() => {
								setState(prevState => ({
									...prevState,
									redirect: true
								}));
							}, 5000);
						}
					})
					.catch(ex => {
			
					});
			  });
			return;
		}
		setState(prevState => ({
			...prevState,
			serverResponse:{
				type: 'Error',
				Message: 'You must agree to Website use of cookies first'
			}
		}));
  }

  if(state.redirect)
  {
    return <Navigate to="/dashboard" />
  }

  return (
    <>
    <div className="bodyStyle text-justify relate" style={BodyStyle}>
				<Particles className="particlesdiv" id="tsparticles" init={ParticlesInit}	options={ParticlesSetup}/>
        <div className="row">
					<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
				   	<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 text-justify">
				   		<h5>You are currently subscribed to CELCOR's Paperless Billing System</h5><br />
						<span>If you want to unsubscribe to CELCOR's Paperless Billing System, review the following information and click Unsubscribe button below.</span><br /><br />
						<span>You can review CELCOR Paperless Billing System Terms and Conditions by clicking <Link target="_blank" to="/paperless-billing-terms-and-conditions">here</Link>.</span><br /><br />
				   </div>
				   <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
				</div>
				<div className="row">
					<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
					<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
						<div className="card gradient-bg shadow h-100">
							<div className="card-header">
								<div className="text-primary h5">Account Details</div>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<div className="form-group">
											<label>Delivery Email Address</label>
											<input
												type="text"
												className="form-control"
												ref={emailRef}
												defaultValue={props.userData.email}
												readOnly
											/>
										</div>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<div className="form-group">
											<label>Account Number</label>
											<input
												type="text"
												className="form-control"
												ref={accnoRef}
												defaultValue={props.userData.accountNo}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 text-center">{state.serverResponse.type === null ? '' : state.serverResponse.Message}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
				</div>
				<div className="row">
					<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"></div>
					<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
						<button 
							type="button" 
							className="btn btn-cta d-block mx-auto mt-3 unsubscribeButton"
							onClick={unsubscribeClick}
							ref={btnsubmitRef}
						>
						Unsubscribe Now</button>
					</div>
					<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"></div>
				</div>
			</div>
    </>
  )
}

export default PaperlessBillingOptOut