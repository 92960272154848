import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroInfomercials from "../../components/Hero/HeroInfomercials/HeroInfomercials";
import ContentComponent from '../../components/InfomercialsComponents/InfomercialsComponents';

function CelcorLive() {

    useEffect(() => {
        document.title = "CELCOR | CELCOR Live";
    },[]);
    
    return (
        <div className="CelcorLive">
            <Navbar />
            <HeroInfomercials title="Celcor Live"/>
            <MainMenu />
            <ContentComponent code="cl" />
        </div>
    );

}
export default CelcorLive;