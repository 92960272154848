import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroAbout from '../../components/Hero/HeroAbout/HeroAbout';
import ContentComponent from '../../components/AboutComponents/Milestones';

function Milestones() {

    useEffect(() => {
        document.title = "CELCOR | Milestones";
    },[],);
    
    return (
        <div className="Milestones">
            <Navbar />
            <HeroAbout title="Milestones"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default Milestones;