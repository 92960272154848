import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/NGCP Conducts Maintenance 1.jpg";
import Pic2 from "./image/NGCP Conducts Maintenance 2.jpg";
import Pic3 from "./image/NGCP Conducts Maintenance 3.jpg";

function Content () {
    return(
        <React.Fragment>
            <div className="text-justify">
                    May 04, 2023 - The National Grid Corporation of the Philippines has conducted its
                    Annual Maintenance Program in Nueva Ecija-Aurora Substation which resulted in a
                    City-Wide Power Interruption in all areas served by Cabanatuan Electric Corporation
                    from 6:00AM to 6:00PM.
                    <br /><br />
                    At the same time, CELCOR took advantage of the time to facilitate its maintenance,
                    repairs, and rehabilitation works.
					<br /><br />
					(Photo taken: May 04, 2023 by M. Giron)
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image-portrait" src={Pic1} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image-portrait" src={Pic2} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image-portrait" src={Pic3} />
					</div>
				</div>
        </React.Fragment>
    )
	
}

export default Content;