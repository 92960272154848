import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css/NewsAdvisoriesComponents.css";
import loader from "../../loader.svg";
import config from 'react-global-configuration';
import AdvisorySidebar from "./AdvisorySidebar";
import { useStateWithCallbackLazy } from "use-state-with-callback";

const loaderStyle = {
	height: "150px",
	width: "150px"
};

const type = 'office-advisories';

function OfficeAdvisories () {

    const [state, setState] = useStateWithCallbackLazy ({
			latestNews: [],
			limit: 3,
			loader: true,
			maxlimit: 0,
			ismaxed: false
    });

    useEffect (() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });

			axios
				.all([
					axios.get(config.get('apiUrl') + "/Advisories/getotheradvisories", {
						params: {
							limit: 3,
							type: type
						}
					}),
					axios.get(config.get('apiUrl') + "/Advisories/otheradvisoriescount", {
						params: {
							type: type
						}
					})
				])
				.then(
					axios.spread((...responses) => {
						setState(prevState => ({
							...prevState,
							latestNews: responses[0].data,
							maxlimit: parseInt(responses[1].data),
							loader: false
							}), (currentState) => {
								if(Object.keys(currentState.latestNews).length === currentState.maxlimit)
								setState(prevState => ({
									...prevState,
									ismaxed: true
								}));
							});
					})
				)
				.catch(ex => {

				});
    },[],);

    const filterlist = (currentState) =>
    {
        axios
			.get(config.get('apiUrl') + "/Advisories/getotheradvisories", {
				params: {
					limit: currentState.limit,
					type: type
				}
			})
			.then(response => {
				setState(prevState => ({
					...prevState,
					latestNews: response.data,
					loader: false
				}), (currentState) => {
					if(Object.keys(currentState.latestNews).length === currentState.maxlimit)
					setState(prevState => ({
						...prevState,
						ismaxed: true
					}));
				});
			})
			.catch(ex => {
				
			})
    }
	
	return (
		<div className="container-fluid container-newsList pt-5 bg-grey" style={{backgroundImage: 'none'}}>
		<div className="container px-0 pt-2">
			<div className="row">
			<div className="col-sm-12 col-md-4">
				<AdvisorySidebar />
			</div>
			<div className="col-sm-12 col-md-8 mb-3">
				{state.latestNews && Object.keys(state.latestNews).length > 0 ? (
					<React.Fragment>
						<h3 className="gradient-text mb-3 text-center">OFFICE ADVISORIES</h3>
						{state.latestNews.map((item, idx) => {
							return (
								<div className="row">
									<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
										<span>{item.Content.split("\n").map(news => {
											return (<p className="text-justify">{news}<br /></p>)
										})}
										</span>
									</div>
								</div>
							)
						})}
						{state.ismaxed === false ? (
					 	<div className="row mb-3">
							<div className="col-md-4 col-lg4"></div>
							<div className="col-sm-12 col-12 col-md-4 col-lg-4 col-xl-4">
							<button style={{visibility: 'visible'}} type="button" className="btn btn-download-pdf" onClick={(e) => {
								setState(prevState => (
									{
										...prevState,
										limit: prevState.limit + 3,
										loader: true
									}
								), (currentState) => {
									filterlist(currentState);
								});
							}}>Show More</button>
							</div>
							<div className="col-md-4 col-lg4"></div>
						</div>
						) : (
						<React.Fragment />
						)}
					</React.Fragment>
				) : state.loader === true ? "" : <h3 className="gradient-text text-center">NO AVAILABLE DATA</h3>}
				
				<div className="row">
					<div className="col-12 text-center">
						{state.loader && state.loader === true ? (
							<img
								src={loader}
								style={loaderStyle}
							/> 
						) : ""}
					</div>
				</div>
			</div>
			</div>
		</div>
		</div>
	);
}
export default OfficeAdvisories;