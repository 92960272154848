import React from "react";
import { Link } from "react-router-dom";
import "./css/NewsAdvisoriesComponents.css";

function AdvisorySidebarArchived () {
    return(
        <div className="advisories-bodystyle">
            <div className="card gradient-bg shadow mb-2">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between consumption-header">
                    <div className="text-primary h5">Service Advisories Archive</div>
                </div>
                <div className="list-group">
                    <Link className="hyperlink list-group-item archive-item list-group-item-action text-scheduled-power-interruption" to="/scheduled-power-interruption">Scheduled Power Interruption</Link>
                    <Link className="hyperlink list-group-item archive-item list-group-item-action text-emergency-power-interruption" to="/emergency-power-interruption">Emergency Power Interruption</Link>
                    <Link className="hyperlink list-group-item archive-item list-group-item-action text-city-wide-power-interruption" to="/city-wide-power-interruption">City-wide Power Interruption</Link>
                    <Link className="hyperlink list-group-item archive-item list-group-item-action text-ngcp-alerts" to="/ngcp-alerts">NGCP Alerts</Link>
                </div>
            </div>
        </div>
    )
}
export default AdvisorySidebarArchived;