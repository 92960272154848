import React, {useEffect} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Error from "../../components/Error404/Error404";

function Error404 () {

    useEffect (() => {
        document.title = "CELCOR | Error 404";	
    },[])
    
    return (
        <div className="Contact">
            <Navbar />
                <div className="container-fluid h-100">
                <div className="row h-100">
                <main className="col bg-grey">
                    <Error />
                </main>
            </div>
            </div>
        </div>
    );
}

export default Error404;