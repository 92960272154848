import React, {useState, useEffect} from "react";
import './css/contentstyle.css';
import axios from "axios";
import config from 'react-global-configuration';
import moment from "moment";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from '../AboutComponents/js/particles.init';

function Profile(props) {
    const [state, setState] = useState ({
        value: {
            d: '2023-01-11',
            link: ''
        }
    }); 

    useEffect (() => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        axios
            .get(config.get('apiUrl') + "/Advisories/annivvideo")
            .then(response => {
                setState(prevState => ({...prevState, value: response.data }));
            })
    }, []);

    return(
        <div className="container relate">
            <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
            <div className="row bodystyle-small-scr">
                <div className="col-md-2 col-lg-2 col-xl-2"></div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <h4 className="faqs-header hyperlink-nocursor text-center">Profile</h4><br />
                    <p>Cabanatuan Electric Corporation (CELCOR) is a {moment(state.value.d).diff('1910-01-16', 'years', false)}-year old company engaged in the generation, distribution, and
                    transmission of electricity.</p>
                    <p>Formerly known as Samahang Magsasaka, Inc., CELCOR currently serves over 85,000 households including large
                    establishments all throughout the City of Cabanatuan and some portions of its neighboring town, the Municipality of
                    Santa Rosa, Nueva Ecija.</p>
                    <div id="profile-vid" style={{marginBottom: '1rem'}}>
                        <div className="container-fluid">
							<iframe src={state.value.link}
                                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
							</iframe>
						</div>
                    </div>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2"></div>
            </div>
        </div>
    );
}

export default Profile;