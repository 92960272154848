import React from "react";
import "./css/HeroFAQs.css";

function HeroFAQs() {
    return (
        <React.Fragment>
            <div className="div-nothing"></div>
			<div>
				<div className="container-fluid container-hero-faqs text-center d-flex align-items-center justify-content-center">
				</div>
			</div>
        </React.Fragment>
    );
}

export default HeroFAQs;