import React, { Component } from "react";
import { Link } from 'react-router-dom';

function Assistance () {
    return(
        <p>
            For further assistance, you may send us a message at  
            <span className="hyperlink"> ask.celcor@gmail.com</span>; 
            call our <Link to="/other-services" className="hyperlink" target="_blank">business hotlines</Link>
            ; or visit our <Link to="/office-schedule" className="hyperlink" target="_blank">Main Office</Link>.
        </p>
    );
}
export default Assistance;