import React, { useEffect } from "react";
import HeroRates from "../../components/Hero/HeroRates/HeroRates";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import ContentComponent from '../../components/NewsAdvisoriesComponents/RatesArchives';
import ContentComponent2 from '../../components/NewsAdvisoriesComponents/Testt';

function RatesArchives() {

    useEffect(() => {
        document.title = "CELCOR | Rates Archives";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    
    return (
        <div className="RatesArchives">
            <Navbar />
            <HeroRates/>
            <MainMenu />
            <ContentComponent />
        </div>
    );
}

export default RatesArchives;