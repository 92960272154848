import React, {useState,useEffect} from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import PaperlessBillingTNC from "../../../components/Account/AccountPaperlessBillingTNC/AccountPaperlessBillingTNC";
import ParticlesSetup from '../../../components/AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "../../../components/AboutComponents/js/particles.init";
import Particles from 'react-tsparticles';

function AccountPaperlessBillingTNC() {

  const [redirect, setRedirect] = useState(false);

  useEffect (() => {
    document.title = "CELCOR | Paperless Billing Terms and Conditions";
  });

  return (
    <>
    <Navbar />
        <div className="container-fluid h-100">
          <div className="row h-100">
            <main className="col">
            <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup} />
			      <PaperlessBillingTNC />
            </main>
          </div>
        </div>
    </>
  )
}

export default AccountPaperlessBillingTNC