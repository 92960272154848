import React from "react";
import './css/HeroHome.css';
import { Link } from "react-router-dom";
import averia from "./image/AVERIA APP.jpg";
import billspayment from "./image/BILLS PAYMENT.jpg";
import officeschedule from "./image/OFFICE SCHEDULE.jpg";
import register from "./image/REGISTER ONLINE.jpg";
import sms from "./image/SMS PROGRAM.jpg";
import paperless from "./image/PAPERLESS BILL.jpg";

const styles = {
	image: {
		//  height: "580px",
		// width: "1480px" 
	}
}

const images = {
	averia: <img src={averia} className="img-fluid carousel-img-big" style={styles.image} />,
	billspayment: <img src={billspayment} className="img-fluid carousel-img-big" style={styles.image} />,
	officeschedule: <img src={officeschedule} className="img-fluid carousel-img-big" style={styles.image} />,
	register: <img src={register} className="img-fluid carousel-img-big" style={styles.image} />,
	sms: <img src={sms} className="img-fluid carousel-img-big" style={styles.image} />,
	paperless: <img src={paperless} className="img-fluid carousel-img-big" style={styles.image} />
}

function HeroHome() {
    
    return (
        <React.Fragment>
            <div className="container-fluid container-hero-home text-center">
                <div className="row">
                    <div className="col-md-2 col-lg-2 col-xl-2"></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div id="myCarousel" className="carousel hero-title text-raised hero-title slide" data-bs-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-bs-target="#myCarousel" data-bs-slide-to="0" className="active"></li>
                                <li data-bs-target="#myCarousel" data-bs-slide-to="1"></li>
                                <li data-bs-target="#myCarousel" data-bs-slide-to="2"></li>
                                <li data-bs-target="#myCarousel" data-bs-slide-to="3"></li>
                                <li data-bs-target="#myCarousel" data-bs-slide-to="4"></li>
                                <li data-bs-target="#myCarousel" data-bs-slide-to="5"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <Link to="/register" className="carousel-link active">
                                        {images.register}
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to="/register" className="carousel-link">
                                        {images.paperless}
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to="/sms-program" className="carousel-link">
                                        {images.sms}
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to="/office-schedule" className="carousel-link">
                                        {images.officeschedule}
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to="/averia" className="carousel-link">
                                        {images.averia}
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to="/collection-offices" className="carousel-link">
                                        {images.billspayment}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xl-2"></div>
                </div>
		    </div>
        </React.Fragment>
    );

}
export default HeroHome;