import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import SectionTitle from "../SectionTitle/SectionTitle";
import config from 'react-global-configuration';
import "react-sharingbuttons/dist/main.css";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import particlesInit from "../AboutComponents/js/particles.init";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

// news
import CELCORTaskForceOdette from "../../components/NewsAdvisoriesComponents/NewsContents/CELCORTaskForceOdette/Content";
import CELCORAnnualCSR2021 from "../../components/NewsAdvisoriesComponents/NewsContents/CELCORAnnualSCR2021/Content";
import TyphoonKarding2022 from "../../components/NewsAdvisoriesComponents/NewsContents/TyphoonKarding2022/Content";
import SparksOfJoy2022 from "../../components/NewsAdvisoriesComponents/NewsContents/SparksOfJoy2022/Content";
import CELCORAnniversary113th from "../../components/NewsAdvisoriesComponents/NewsContents/CELCORAnniversary113th/Content";
import CELCORSignsDataSharing from "../../components/NewsAdvisoriesComponents/NewsContents/CELCORSignsDataSharing/Content";
import CELCOREngineersWithLGU from "../../components/NewsAdvisoriesComponents/NewsContents/CELCOREngineersWithLGU/Content";
import NGCPConductsMaintenance2023 from "../../components/NewsAdvisoriesComponents/NewsContents/NGCPConductsMaintenance2023/Content";
import CELCORLifelineRateSubsidy from "../../components/NewsAdvisoriesComponents/NewsContents/CELCORLifelineRateSubsidy/Content";
import CELCORFeedingProgram2023Accfa from "../../components/NewsAdvisoriesComponents/NewsContents/CELCORFeedingProgram2023Accfa/Content";
import CELCORSeeksAssistanceFromBarangay from "../../components/NewsAdvisoriesComponents/NewsContents/CELCORSeeksAssistanceFromBarangay/Content";
import CELCORJoinsCabanatuanCityBarangayCaptains from "../../components/NewsAdvisoriesComponents/NewsContents/CELCORJoinsCabanatuanCityBarangayCaptains/Content";

function NewsInfo () {

    const[state, setState] = useStateWithCallbackLazy ({
        archiveName: [],
        latestNews: [],
        staticNews: [],
        redirect: false
    });

    useEffect (() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });

			const url_string = window.location.href;
			const spl = url_string.split("/");
			var x = 0;
			for(x = 0 ; x<spl.length ; x++)
			{
				if(spl[x] === "news")
				{
				x++;
				break;
				}
			}

			const link = spl[x];

			axios
				.all([
					axios.get(config.get('apiUrl') + "/News/getIndividualNews", {
						params: {
							link: link
						}
					}),
					axios.get(config.get('apiUrl') + "/News/archiveName")
				])
				.then(
					axios.spread((...responses) => {
						if(responses[0].data != 0)
						{
						setState(prevState => ({
							...prevState,
							latestNews: responses[0].data,
							archiveName: responses[1].data
						}), (currentState) => {
							axios
								.get(config.get('apiUrl') + "/News/getStaticNews", {
									params: {
										news_id: currentState.latestNews[0].Id
									}
								})
								.then(response => {
									setState(prevState => ({
										...prevState,
										static_news: response.data == "0" ? "" : response.data[0].Link
									}));
								});
						});
							
						}
						else
						{
							setState(prevState => ({
								...prevState,
								redirect: true
							}));
						}
					})
				)
    },[],);

    if (state.redirect)
    {
        return <Navigate to = "/" />
    }

    return (
			<>
			<div className="container-fluid container-newsList pt-5" style={{backgroundImage: 'none'}}>
			<Particles className="particlesdiv" id="tsparticles" init={particlesInit}	options={ParticlesSetup}/>
		  <div className="container px-0 pt-5">
		  	{state.latestNews && Object.keys(state.latestNews).length > 0 ? (
				<React.Fragment>
					<SectionTitle title={state.latestNews[0].Category + " Information"} />
					<div className="row">
					<div className="col-sm-12 col-md-3">
						<div className="card gradient-bg shadow mb-2">
							<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between consumption-header">
								<div className="text-primary h5">News archive</div>
							</div>
							<div className="list-group">
								{state.archiveName ? (
									state.archiveName
									.slice(0, Object.keys(state.archiveName).length)
									.map(item => {
										return <Link className="hyperlink list-group-item archive-item list-group-item-action" to={"/news-archive/" +item.buwan}>{item.buwan.replace(/-/g, ' ')}</Link>
									})
								) : ""}
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-8 box-shadow newsCardMain">
						{state.latestNews ? (
						<div className="row">
							<div className="col-sm-12 col-sm-12">
								<h4 title={state.latestNews[0].Category} className="hyperlink-nocursor">{state.latestNews[0].Title}</h4>
								{state.static_news === 'CELCORTaskForceOdette' ? <CELCORTaskForceOdette /> : ''}
								{state.static_news === 'CELCORAnnualCSR2021' ? <CELCORAnnualCSR2021 /> : ''}
								{state.static_news === 'TyphoonKarding2022' ? <TyphoonKarding2022 /> : ''}
								{state.static_news === 'SparksOfJoy2022' ? <SparksOfJoy2022 /> : ''}
								{state.static_news === 'CELCORAnniversary113th' ? <CELCORAnniversary113th /> : ''}
								{state.static_news === 'CELCORSignsDataSharing' ? <CELCORSignsDataSharing /> : ''}
								{state.static_news === 'CELCOREngineersWithLGU' ? <CELCOREngineersWithLGU /> : ''}
								{state.static_news === 'NGCPConductsMaintenance2023' ? <NGCPConductsMaintenance2023 /> : ''}
								{state.static_news === 'CELCORLifelineRateSubsidy' ? <CELCORLifelineRateSubsidy /> : ''}
								{state.static_news === 'CELCORFeedingProgram2023Accfa' ? <CELCORFeedingProgram2023Accfa /> : ''}
								{state.static_news === 'CELCORSeeksAssistanceFromBarangay' ? <CELCORSeeksAssistanceFromBarangay /> : ''}
								{state.static_news === 'CELCORJoinsCabanatuanCityBarangayCaptains' ? <CELCORJoinsCabanatuanCityBarangayCaptains /> : ''}
							</div>
						</div>
						) : ""}
					</div>
					</div>
				</React.Fragment>
			) : ""}
		  </div>
		</div>
			</>
    );
}
export default NewsInfo;