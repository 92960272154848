import React, { useState, useEffect } from "react";
import officeschedule from "./image/OFFICE SCHEDULE.png";
import "./css/OfficeSchedule.css";
import { faMapMarkerAlt, faCopy, faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from '../AboutComponents/js/particles.init';

function OfficeSchedule () {

    const [state, setState] = useState ({
        bitas: '',
        smi: '',
        selected: '',
        email: '',
        onlinepayment: '',
        frame: <React.Fragment />
    });

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });    
    }, []);

    const itemClicked = (e) =>
    {
        // if(e === state.selected) return;
		// setState({selected: e}, () => {
			switch(e)
			{
				case "bitas":
				default:
					setState(prevState => ({
                        ...prevState,
						bitas: ' active',
						smi: '',
						email: '',
						frame: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.181653680585!2d120.9767181385981!3d15.49913059923555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397293137d96a9d%3A0x64e59995e7136092!2sCabanatuan%20Electric%20Corporation%2C%20AH26%2C%20Cabanatuan%20City%2C%203100%20Nueva%20Ecija!5e0!3m2!1sen!2sph!4v1649119073237!5m2!1sen!2sph" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="frame"></iframe>
					}));
					break;
	
				case "smi":
					setState(prevState => ({
                        ...prevState,
						bitas: '',
						smi: ' active',
						onlinepayment: '',
						frame: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.229497246308!2d120.9618273291503!3d15.488842891727007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397291f0bb978cb%3A0x5277f139291dac48!2sCELCOR%20Payment%20Center!5e0!3m2!1sen!2sph!4v1649119225817!5m2!1sen!2sph" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="frame"></iframe>
					}));
					break;
			}
		// });
    }

    const bitasclicked = () => 
    {
        itemClicked('bitas');
    }

    const smiclicked = () =>
    {
        itemClicked('smi');
    }

    return (
        <>
        <div className="container-fluid relate">
        <Particles className="particlesdiv"
        id="tsparticles"
        init={ParticlesInit}
        options={ParticlesSetup}
        />
            <div className="container">
                <div className="card relate box-shadow my-5 card-contact" id="officeschedule">
                    <img src={officeschedule} className="office-schedule-img" />
                    <div className="container container-sched">
                        <div className="row">
                            <div className="col-md-1 col-lg-1 col-xl-1"></div>
                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                <div className="list-group" id="sched-list">
                                    <span className={`schedule-item list-group-item list-group-item-action${state.bitas}`} onClick={bitasclicked}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>CELCOR MAIN OFFICE</strong><br />
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="empty-icon" /> Along Maharlika Highway, Barangay Bitas,<br />
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="empty-icon" /> Cabanatuan City, Nueva Ecija — 3100
                                    </span>
                                    <span className={`schedule-item list-group-item list-group-item-action${state.smi}`} onClick={smiclicked}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>SMI COLLECTION OFFICE</strong><br />
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="empty-icon" /> Del Pilar Street, SMI Building,<br />
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="empty-icon" /> Cabanatuan City, Nueva Ecija — 3100
                                    </span>
                                    <span className={`list-group-item list-group-item-action`}>
                                        <FontAwesomeIcon icon={faAt} /> <strong>EMAIL US AT</strong><br />
                                        <FontAwesomeIcon icon={faAt} className="empty-icon" /> ask.celcor@gmail.com &nbsp;
                                        <button className="copy-button" title="Copy to Clipboard" onClick={(e) => navigator.clipboard.writeText('ask.celcor@gmail.com') }>
                                            <FontAwesomeIcon icon={faCopy} className="copy-icon" size="lg"/>
                                        </button>
                                        
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                            {state.frame}
                            </div>
                            <div className="col-md-1 col-lg-1 col-xl-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default OfficeSchedule;