import React, {Component } from "react";
import "./css/HeroBillsPayment.css"

function HeroBillsPayment(props) {
	return (
		<div>
			<div className="div-nothing"></div>
			<div>
				<div className="container-fluid container-hero-billspayment text-center d-flex align-items-center justify-content-center">
				<p className="hero-title text-raised mt-5 hero-title">{props.title}</p>
				</div>
			</div>
		</div>
	);
}

export default HeroBillsPayment;