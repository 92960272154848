import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroInfomercials from "../../components/Hero/HeroInfomercials/HeroInfomercials";
import ContentComponent from '../../components/InfomercialsComponents/InfomercialsComponents';

function WattsUp() {
    
    useEffect(() => {
        document.title = "CELCOR | Watt's Up";
    },[]);
    
    return (
        <div className="WattsUp">
            <Navbar />
            <HeroInfomercials title="Watt's Up"/>
            <MainMenu />
            <ContentComponent code="wu"/> 
        </div>
    );

}
export default WattsUp;