import React, {useEffect} from "react";
import './css/AboutComponents.css';
import './css/contentstyle.css';
import history1 from "./image/HISTORY 1.png";
import history2 from './image/HISTORY 2.png'
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

function Milestones() {

		useEffect(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}, [])

    return (
        <React.Fragment>
			<div className="container-fluid relate">
				<Particles className="particlesdiv "
				id="tsparticles"
				init={ParticlesInit}
				options={ParticlesSetup}
				/>
				<div className="container px-0 pt-5 pb-5">
					<div className="card relate box-shadow card-contact">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<img src={history1} className="about-new-img" />
								<img src={history2} className="about-new-img" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
    );
}

export default Milestones;