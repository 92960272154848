import React, { useEffect } from "react";
import MainMenu from "../../components/MainMenu/MainMenu";
import Navbar from "../../components/Navbar/Navbar";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import ContentComponent from '../../components/NewsAdvisoriesComponents/ERCAdvisories';

function ERCAdvisories (props) {
	
  useEffect (() => {
    document.title = "CELCOR | ERC Advisories";
  });
  
    return (
      <div className="Home">
        <Navbar />
        <HeroNews title="ERC Advisories" />
        <MainMenu />
        <ContentComponent />
      </div>
    );
}

export default ERCAdvisories;
