import React from "react";
import "./css/NewsAdvisoriesComponents.css";
import AnnouncementSidebar from "./AnnouncementSidebar";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

const pdf = {
	jointResolutionNo01SeriesOf2022 : 
		'Joint_Resolution_No_01_Series_of_2022.pdf'
}

function ERCAdvisories() {
    return (
        <div className="container-fluid relate container-newsList pt-5 pb-5 advisories-bodystyle" style={{backgroundImage: 'none'}}>
        <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup} />
            <div className="container px-0 pt-2">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <AnnouncementSidebar />
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div className="row">
                            <div className="col-sm-12 col-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'justify'}}>
                                Dated 08-October-2022, the Energy Regulatory Commission (ERC), the Department of Energy (DOE)
                                and the Department of Social and Welfare Development (DSWD), in consultation with the Philippine
                                Statistics Authority (PSA) and the other public and private stakeholders, and with the
                                approval of Joint Congressional Energy Commission, issue, adopt and promulgate a;
                                <br /><br />
                                Joint Resolution, No. 01, Series of 2022, entitled A Joint Resolution for Implementing Republic
                                Act No. 11552 Entitled “An Act Extending and Enhancing the Implementation of the Lifeline
                                Rate, Amending for the Purpose, Section 73 of Republic Act No. 9136, Otherwise Known as the
                                'Electric Power Industry Report Act of 2001', as Amended by Republic Act No. 10150”
                                <div className="row mt-1 mb-3">
                                    <div className="col-sm-12 col-12 col-md-5 col-lg-5 col-xl-5"><a href={`https://restapi.celcor.com.ph/files/announcements/${pdf.jointResolutionNo01SeriesOf2022}`} className="download-pdf-link" target="_blank"><button type="button" className="btn btn-download-pdf">Download PDF</button></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ERCAdvisories;