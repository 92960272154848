import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./css/NewsAdvisoriesComponents.css";
import SectionTitle from "../SectionTitle/SectionTitle";
import loader from "../../loader.svg";	
import config from 'react-global-configuration';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import particlesInit from "../AboutComponents/js/particles.init";

//NEWS CONTENT
import CELCORTaskForceOdette from "./NewsContents/CELCORTaskForceOdette/Content";
import CELCORAnnualCSR2021 from "./NewsContents/CELCORAnnualSCR2021/Content";
import TyphoonKarding2022 from "./NewsContents/TyphoonKarding2022/Content";
import SparksOfJoy2022 from "./NewsContents/SparksOfJoy2022/Content";
import CELCORAnniversary113th from "./NewsContents/CELCORAnniversary113th/Content";
import CELCORSignsDataSharing from "./NewsContents/CELCORSignsDataSharing/Content";
import CELCOREngineersWithLGU from "./NewsContents/CELCOREngineersWithLGU/Content";
import NGCPConductsMaintenance2023 from "./NewsContents/NGCPConductsMaintenance2023/Content";
import CELCORLifelineRateSubsidy from "./NewsContents/CELCORLifelineRateSubsidy/Content";
import CELCORFeedingProgram2023Accfa from "./NewsContents/CELCORFeedingProgram2023Accfa/Content";
import CELCORSeeksAssistanceFromBarangay from "./NewsContents/CELCORSeeksAssistanceFromBarangay/Content";
import CELCORJoinsCabanatuanCityBarangayCaptains from "./NewsContents/CELCORJoinsCabanatuanCityBarangayCaptains/Content";
import { useStateWithCallbackLazy } from "use-state-with-callback";

const loaderStyle = {
	height: "150px",
	width: "150px"
};

function News() {

	const [state, setState] = useStateWithCallbackLazy ({
		archiveName: [],
		latestNews: [],
		staticNews: [],
		limit: 3,
		loader: true,
		maxlimit: 0,
		ismaxed: false
	});

	useEffect (() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		axios
			.all([
				axios.get(config.get('apiUrl') + "/News/getLatestews", {
					params: {
						limit: 3,
						tmp: 1
					}
				}),
				axios.get(config.get('apiUrl') + "/News/archiveName"),
				axios.get(config.get('apiUrl') + "/News/getAllStaticNews"),
				axios.get(config.get('apiUrl') + "/News/newsadvisoriescount")
			])
			.then(
				axios.spread((...responses) => {
					setState (prevState => ({
					...prevState,
					latestNews: responses[0].data,
					archiveName: responses[1].data,
					staticNews: responses[2].data,
					maxlimit: parseInt(responses[3].data),
					loader: false
					}), (currentState) => {
						if(Object.keys(currentState.latestNews).length === currentState.maxlimit)
						setState (prevState => ({
							...prevState,
							ismaxed: true
							}));
					});
				})
			)
			.catch(ex => {

			});
	}, []);

	const filterlist = (currentState) =>
	{
		axios
		.get(config.get('apiUrl') + "/News/getLatestews", {
			params: {
				limit: currentState.limit
			}
		})
		.then(response => {
			setState (prevState => ({
				...prevState,
				latestNews: response.data,
				loader: false
				}), (currentState) => {
					if(Object.keys(currentState.latestNews).length === currentState.maxlimit)
					setState (prevState => ({
						...prevState,
						ismaxed: true
					}));
				});
		})
		.catch(ex => {
			
		})
	}

	return (
		<>
		<div className="container-fluid container-newsList pt-5" style={{backgroundImage: 'none'}}>
		<Particles className="particlesdiv" id="tsparticles" init={particlesInit}	options={ParticlesSetup}/>
		<div className="container px-0 pt-5">
			<SectionTitle title="Latest News" />
			<div className="row">
			<div className="col-sm-12 col-md-3">
				<div className="card gradient-bg shadow mb-2">
					<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between consumption-header">
						<div className="text-primary h5">News Archive</div>
					</div>
					<div className="list-group">
						{state.archiveName ? (
							state.archiveName
							.slice(0, Object.keys(state.archiveName).length)
							.map(item => {
								return <Link className="hyperlink list-group-item archive-item list-group-item-action" to={"/news-archive/" + item.buwan}>{item.buwan.replace(/-/g, ' ')}</Link>
							})
						) : ""}
					</div>
				</div>
			</div>
			<div className="col-sm-12 col-md-8 box-shadow newsCardMain newscardarchive">
				{state.latestNews && Object.keys(state.latestNews).length > 0 ? (
					<React.Fragment>
						{state.staticNews.map(stat => {
							return(
								<React.Fragment>
								{state.latestNews.map(item => {
									if (Number(stat.NewsId) === Number(item.Id))
									{
										return (
											<div className="row">
												<div className="col-sm-12 col-md-12">
													{stat.Link === 'CELCORTaskForceOdette' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCORTaskForceOdette />
															{/* <hr className="hr-news"  /> */}
														</React.Fragment>
													) : ''}

													{stat.Link === 'CELCORAnnualCSR2021' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCORAnnualCSR2021 />
															<hr className="hr-news" />
														</React.Fragment>
													) : ''}

													{stat.Link === 'TyphoonKarding2022' ? (
														<React.Fragment>
															<hr className="hr-news" />
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<TyphoonKarding2022 />
															<hr className="hr-news" />
														</React.Fragment>
													) : ''}

													{stat.Link === 'SparksOfJoy2022' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<SparksOfJoy2022 />
														</React.Fragment>
													) : ''}

													{stat.Link === 'CELCORAnniversary113th' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCORAnniversary113th />
															<hr className="hr-news" />
														</React.Fragment>
													) : ''}

													{stat.Link === 'CELCORSignsDataSharing' ? (
														<React.Fragment>
															<hr className="hr-news" />
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCORSignsDataSharing />
															<hr className="hr-news" />
														</React.Fragment>
													) : ''}

													{stat.Link === 'CELCOREngineersWithLGU' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCOREngineersWithLGU />
															{/* <hr className="hr-news" /> */}
														</React.Fragment>
													) : ''}

													{stat.Link === 'NGCPConductsMaintenance2023' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<NGCPConductsMaintenance2023 />
															<hr className="hr-news" />
														</React.Fragment>
													) : ''}

													{stat.Link === 'CELCORLifelineRateSubsidy' ? (
														<React.Fragment>
															<hr className="hr-news" />
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCORLifelineRateSubsidy />
															<hr className="hr-news" />
														</React.Fragment>
													) : ''}

													{stat.Link === 'CELCORFeedingProgram2023Accfa' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCORFeedingProgram2023Accfa />
															{/* <hr className="hr-news" /> */}
														</React.Fragment>
													) : ''}

													{stat.Link === 'CELCORSeeksAssistanceFromBarangay' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCORSeeksAssistanceFromBarangay />
															<hr className="hr-news" />
														</React.Fragment>
													) : ''}

													{stat.Link === 'CELCORJoinsCabanatuanCityBarangayCaptains' ? (
														<React.Fragment>
															<h4 title={item.Category} className="hyperlink-nocursor">{item.Title}</h4>
															<CELCORJoinsCabanatuanCityBarangayCaptains />
														</React.Fragment>
													) : ''}
												</div>
											</div>
										)
									}
								})}
								</React.Fragment>
							)
						})}
						{state.ismaxed === false ? (
					 	<div className="row mb-3">
							<div className="col-md-4 col-lg4"></div>
							<div className="col-sm-12 col-12 col-md-4 col-lg-4 col-xl-4">
							<button style={{visibility: 'visible'}} type="button" className="btn btn-download-pdf" onClick={(e) => {
								setState (prevState => ({
									...prevState,
									limit: prevState.limit + 3,
									loader: true
									}), (currentState) => {
										filterlist(currentState);
									});
									
							}}>Show More</button>
							</div>
							<div className="col-md-4 col-lg4"></div>
						</div>
						) : (
						<React.Fragment />
						)}
					</React.Fragment>
				) : ""}
					<div className="row">
					<div className="col-12 text-center">
						{state.loader && state.loader === true ? (
							<img
								src={loader}
								style={loaderStyle}
							/> 
						) : ""}
					</div>
				</div>
			</div>
			</div>
		</div>
		</div>
		</>
	);
}

export default News;