import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroServices from "../../components/Hero/HeroServices/HeroServices";
import ContentComponent from '../../components/ServicesComponents/SMSProgram';

function SMSProgram() {
    useEffect(() => {

        document.title = "CELCOR | SMS Program";
    }, []);
    
    return (
        <div className="SMSProgram">
            <Navbar />
            <HeroServices title="SMS Program"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default SMSProgram;