import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faCopy } from "@fortawesome/free-solid-svg-icons";
import './css/Footer.css';
import img1 from "./image/FOOTER 1.png";
import img2 from "./image/FOOTER FB.png";
import img3 from "./image/FOOTER MAIL.png";
import swal from "sweetalert";

function Footer() {

    const copy = () => {
        navigator.clipboard.writeText('ask.celcor@gmail.com')
        swal({
            title: '',
            text: 'Copied!',
            icon: 'success',
            timer: 1000,
            buttons: false,
        })
    }

    return (
        <React.Fragment>
            <div className="container-fluid container-footer no-print" id="defaultfooter">
                <div className="container">
                    <img src={img1} className="img-footer" />
                    <div className="row">
                        <div className="col-6">
                            <a href="https://www.facebook.com/celcorofficialpage" target="_blank" className="footer-link footer-link-right"><img src={img2} className="footer-link footer-link-right" /></a>
                            {/* <span className="tooltip">@celcorofficialpage</span> */}
                        </div>
                        <div className="col-6">
                            <div className="dropdown no-arrow footer-link footer-link-left">
                                <a href="#" role="button" id="dropdownMenuLink1"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">   
                                    <img title="Email Address" src={img3} className="footer-link footer-link-left" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink1">
                                    <div className="dropdown-header">Email Address<br /><small>(Click the email address below to copy)</small></div>
                                    <span className="dropdown-item action-print action-email" title="Copy to clipboard" onClick={(e) => copy() }><FontAwesomeIcon icon={faAt} /> ask.celcor@gmail.com</span>                                                                                                                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
	  </React.Fragment>
    );
}
export default Footer;