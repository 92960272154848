import React, {Component } from "react";
import "./css/HeroRates.css"

function HeroRates() {
	return (
		<div>
			<div className="div-nothing"></div>
			<div>
				<div className="container-fluid container-hero-rates text-center d-flex align-items-center justify-content-center">
				<p className="hero-title text-raised mt-5 hero-title"></p>
				</div>
			</div>
		</div>
	);
}

export default HeroRates;