import React from 'react';
import Averia1 from "./image/AVERIA 1.jpg";
import Averia2 from "./image/AVERIA 2.png";
import Averia3 from "./image/AVERIA 3.png";
import Averia4 from "./image/AVERIA 4.png";
import "./css/Averia.css";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

const images = {
	intro: <img src={Averia1} className="averia-img" />,
	averiaApp: <img src={Averia2} className="averia-img" />,
	faster: <img src={Averia3} className="averia-img" />,
	androidApp: <img src={Averia4} className="averia-img" />,
}

function Averia() {
  return (
    <>
    <div className="averia-body text-justify">
      <div className="container-fluid relate">
        <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
          <div className="container px-0 pt-5 pb-5">
          <div className="card gradient-bg shadow h-100">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between overview-header">
              <div className="text-primary h5">Averia APP</div>
            </div>
            <div className="card-body">
              {images.intro}
              <a href="http://averia.celcor.com.ph" target="_blank">{images.averiaApp}</a>
              {images.faster}
              <a href="https://restapi.celcor.com.ph/files/CELCOR-Averia.apk" target="_blank" download>{images.androidApp}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Averia