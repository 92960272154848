import React, {useEffect} from "react";
import './css/contentstyle.css';
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from '../AboutComponents/js/particles.init';

function ContactUs() {

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	},[],)

	return (
		<div className="relate">
		<Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
		<div className="row">
			<div className="col-md-2 col-lg-2 col-xl-2"></div>
			<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
				<h4 className="faqs-header hyperlink-nocursor"></h4>
				<br></br>
				<strong className="faqs-list hyperlink-nocursor">You may visit us in our offices with addresses and business schedule as follows:</strong><br />
				<ul className="faqs-list">
					<li><strong>CELCOR Main Office</strong><br />Barangay Bitas, along Maharlika Highway, Cabanatuan City, Nueva Ecija</li>
					<li><strong>Samahang Magsasaka Incorporated (SMI) Collection Office</strong><br />Del Pilar St., Cabanatuan City, Nueva Ecija</li>
				</ul>
				<span className="faqs-list ml-75px">Business Schedule Weekly: </span><br />
				<span className="faqs-list ml-75px">Monday-Friday 08:00AM – 05:00PM,</span><br />
				<span className="faqs-list ml-75px">Saturday 08:00AM – 12:00NN</span><br />
				<br />

				<strong className="faqs-list hyperlink-nocursor">You may also contact us thru the following contact numbers:</strong><br />
				<ul className="faqs-list hotlines">
					<li><span>LANDLINE</span><span>(044) 463-0505; (044) 463-0408; (044) 463-0811; (044) 463-7398; (044) 464-1797 and (044) 600-2414</span></li>
					<li><span>MOBILE</span><span>SMART (0968) 246 0131<br /><span>GLOBE (0966) 980 2280</span></span></li>
				</ul>
				<br />

				<strong className="faqs-list hyperlink-nocursor">Or send us a message via email at:</strong><br />
				<ul className="faqs-list">
					<li>
						<span className="hyperlink">ask.celcor@gmail.com</span>
						<button className="copy-button" title="Copy to Clipboard" onClick={(e) => navigator.clipboard.writeText('ask.celcor@gmail.com') }>
								<FontAwesomeIcon icon={faCopy} className="copy-icon" size="lg"/>
						</button>
					</li>
				</ul>
				<br />
			</div>
			<div className="col-md-2 col-lg-2 col-xl-2"></div>
		</div>
	</div>
	);
}

export default ContactUs;