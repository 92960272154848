import React from "react";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const hide = <FontAwesomeIcon icon={faEyeSlash} />
const show = <FontAwesomeIcon icon={faEye} />

const BodyStyle = {
	paddingTop: "96px",
	marginBottom: "75px"
  };

function AccountPaperlessBillingTNC() {
  return (
    <>
    <div className="bodyStyle text-justify" style={BodyStyle}>
      <h1 className="fo-second font-weight-bold text-center hyperlink-nocursor">Paperless Billing Terms and Conditions</h1>
      <hr />
      <div className="row">
				<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
				   <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 text-justify">
					   <strong>IMPORTANT, Please Read.</strong>
						<br /><br />
						Upon registration at CELCOR's paperless billing system, you shall deemed to have acknowledged
						and represented that you have read, understood, and accepted CELCOR's Terms and Conditions as
						stated below. Once registered, Bill Notification is automatically set-up, so that you will receive bill
						notifications at your registered email address. Only registered CELCOR account holders are
						authorized to access and utilize this paperless billing system.
						<br /><br />
						By accepting the Terms and Conditions stated below, you represent that you are the customer of
						record for the account:
						<ul style={{listStyleType: "disc"}}>
							<li>
								These Terms and Conditions do not alter your liability or obligations that currently exist
								between you and CELCOR concerning your utility usage, obligation to pay, and other
								product and services.
							</li>
							<li>
								You agree not to use CELCOR's Paperless Billing System for illegal purpose or for the
								transmission of materials that is unlawful, harassing, libelous (untrue and damaging to
								others), invasive of another's privacy, abusive, threatening, obscene, or that violate of the
								rights of others.
							</li>
							<li>
								By registering to CELCOR's Paperless Billing System, you voluntarily agree to the
								processing of the personal information that your submit online.
							</li>
							<li>
								After your application had been verified, you will continue to receive your Statement of
								Account for two (2) months billing period with the Electronic billing PDF format.
								Thereafter, you will start to receive electronic/paperless bill at your registered email address
							</li>
							<li>
								You will no longer receive a paper bill from CELCOR. However, you may produce a 
								printed copy of your Paperless Bill, at your own cost and initiative. In so
								doing, you are prohibited from tampering or altering the contents of your
								electronic/paperless bill. Should you violate this provision, CELCOR will automatically
								cancel your registration in its CELCOR's Paperless Billing system, subject to the right of CELCOR to
								forever blacklist your account in the system.
							</li>
							<li>
								CELCOR shall attempt to send your electric bill in email or text format to the email
								address that you registered in CELCOR's Paperless Billing system.
							</li>
							<li>
								You agree to indemnify, defend, and hold CELCOR free and harmless for any delay or
								failure to deliver or receive the Paperless Bill Notice.
							</li>
							<li>
								It is your sole responsibility to ensure that email address(es) are accurate and secure.
								CELCOR shall, in no case, be liable for any undue or unauthorized disclosure of the
								contents of CELCOR's Paperless Bill Notice to third parties, as the handling and 
								safekeeping of such information is left entirely in the hands of the registered customer.
							</li>
							<li>
								It is your responsibility to contact CELCOR directly and immediately if you do not receive your
								Paperless Bill Notice. You agree that you shall be liable for any and all charges related to electric
								service, regardless of whether you receive your Paperless Bill Notice. Non-receipt of the
								Paperless Bill Notice cannot be used as a valid defense in any litigation arising
								from the supply of electricity by CELCOR. 
							</li>
							<li>
								In the event you do not receive notification, it is your responsibility to log into your Account
								and check your account balance on <i>Dashboard</i> section.
							</li>
							<li>
								You may terminate CELCOR's Paperless Billing system at any time by logging into your Account and following the
								appropriate instructions on <i>Paperless Billing</i> section.
							</li>
							<li>
								After you terminate CELCOR's Paperless Billing system, future bills shall be mailed to your current address
								indicated in your Account Information. It is your responsibility to update the said 
								address. Thus, the transmittal of the bills at your registered address shall be binding on
								both parties.
							</li>
							<li>
								If emails are returned as undeliverable, CELCOR reserves the right to terminate you from
								CELCOR's Paperless Billing system and return to sending you a paper bill. You may subscribe again at any time
								after correcting and changing your email address through <i>Manage Account</i>.
							</li>
							<li>
								The email address you provided to receive electronic bills shall be subject for confirmation yearly. If no update
								was made by the customer, the existing subscription details shall be deemed correct and accurate by CELCOR.
							</li>
							{localStorage.getItem("userData") !== null ? (
								<li>
									Should you wish to receive the notice of disconnection through your designated email address in addition to the
									paper notice of disconnection, kindly inform CELCOR of your availment of such option by checking the “I would also 
									like to receive Disconnection Notices thru my delivery address” on <i>Paperless Billing</i> when you subscribe. 
									Actual disconnection shall be effected by CELCOR from the date of receipt of the paper notice of disconnection.
								</li>
							) : ""}
							<li>
								When receiving bill notification, standard message and data rates may apply. You
								acknowledge and agree that you are liable for any and all standard message and data
								rates and fees related to your receipt of electronic/paperless bill. You agree that you
								indemnify, defend, and hold CELCOR free and harmless from any and all standard
								message and data rates and fees related to your receipt of electronic/paperless bills.
							</li>
						</ul>
						<br />
						These Terms and Conditions may be modified by CELCOR at any time. In such an event, CELCOR
						shall provide a notice of change for approximately 30 days from the date of effectivity of
						such change/s, which may be accomplished by posting such change on the CELCOR's update alerts.
						The usage of CELCOR website pages after such notice is posted shall be construed as your
						agreement to such change(s). You may stop using and/or unsubscribe from CELCOR's Paperless
						Billing System if you do not agree withany modifications.
						<br /><br />
						CELCOR reserves the right to terminate the use of Paperless Billing System, in its sole discretion, at
						any time.
					</div>
				<div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
			</div>
    </div>
    </>
  )
}

export default AccountPaperlessBillingTNC