import React, {useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import AveriaView from "../../components/Averia/Averia";

function Averia() {
  
  useEffect (() => {
    document.title = "CELCOR | Averia";
  });

  return (
    <>
    <Navbar />
    <div className="container-fluid h-100">
		  <div className="row h-100">
			<main className="col">
				<AveriaView />
			</main>
		  </div>
		</div>
    </>
  )
}

export default Averia