import React, {useEffect} from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroLogin from "../../components/Hero/HeroLogin/HeroLogin";
import ContentComponent from "../../components/ForgotPassword/ForgotPassword";

function ForgotPassword () {

    useEffect (() =>{
        document.title = "CELCOR | Forgot Password";
    });

    return (
    <>
    <Navbar />
    <HeroLogin title="Forgot Password" />
    <MainMenu />
    <ContentComponent />
    </>
    );
}
export default ForgotPassword;