import React from "react";
import './css/MainMenu.css';
import { Link } from "react-router-dom";

function MainMenu() {

    return (
    	<div className="mainNavPosition">
			<div className="container container-mainNav">
				<ul className="nav mainNav justify-content-around">
					<li className="nav-item">
						<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About CELCOR</a>
						<div className="dropdown-menu menuParent" aria-labelledby="navbarDropdown2">
							<Link className="dropdown-item menuitem" to="/profile">Profile</Link>
							<Link className="dropdown-item menuitem" to="/mission-and-vision">Mission and Vision</Link>
							<Link className="dropdown-item menuitem" to="/history">History</Link>
							<Link className="dropdown-item menuitem" to="/milestones">Milestones</Link>
							<Link className="dropdown-item menuitem" to="/opportunities">Opportunities</Link>
							<Link className="dropdown-item menuitem" to="/contact-us">Contact Us</Link>
						</div>
					</li>
					<li className="nav-item">
					<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services</a>
					<div className="dropdown-menu menuParent" aria-labelledby="navbarDropdown">
						<Link className="dropdown-item menuitem" to="/application-for-meter">Meter Application</Link>
						<Link className="dropdown-item menuitem" to="/senior-citizen-discount">Senior Citizen Discount</Link>
						<Link className="dropdown-item menuitem" to="/sms-program">SMS Program</Link>
						<Link className="dropdown-item menuitem" to="/change-of-name">Change of Name</Link>
						<Link className="dropdown-item menuitem" to="/paperless-billing-service">Paperless Billing Service</Link>
						<Link className="dropdown-item menuitem" to="/services/lifeline-rate">Lifeline Rate</Link>
						<Link className="dropdown-item menuitem" to="/other-services">Other Services</Link>
					</div>
					</li>
					<li className="nav-item">
						<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Bills Payment</a>
						<div className="dropdown-menu menuParent" aria-labelledby="navbarDropdown4">
							<Link className="dropdown-item menuitem" to="/collection-offices">Collection Offices</Link>
							<Link className="dropdown-item menuitem" to="/online">Online</Link>
							<Link className="dropdown-item menuitem" to="/local-banks-and-establishments">Local Banks and Establishments</Link>
						</div>
					</li>
					<li className="nav-item">
						<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">News & Advisories</a>
						<div className="dropdown-menu menuParent" aria-labelledby="navbarDropdown3">
							<Link className="dropdown-item menuitem" to="/news">News</Link>
							<Link className="dropdown-item menuitem" to="/advisories">Advisories</Link>
							<Link className="dropdown-item menuitem" to="/rate-archives">Rates Archives</Link>
							<Link className="dropdown-item menuitem" to="/announcements">Announcements</Link>
						</div>
					</li>
					<li className="nav-item">
						<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown5" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Infomercials</a>
						<div className="dropdown-menu menuParent" aria-labelledby="navbarDropdown5">
							<Link className="dropdown-item menuitem" to="/celcor-live">CELCOR Live</Link>
							<Link className="dropdown-item menuitem" to="/customer-guide">CELCOR Customer Guide</Link>
							<Link className="dropdown-item menuitem" to="/watts-up">Watt's Up</Link>
							<Link className="dropdown-item menuitem" to="/guess-watt">Guess Watt</Link>
						</div>
					</li>
				</ul>
			</div>
		</div>
        
    );

}

export default MainMenu;