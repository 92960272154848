import React, { useEffect } from "react";
import MainMenu from "../../components/MainMenu/MainMenu";
import Navbar from "../../components/Navbar/Navbar";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import ContentComponent from '../../components/NewsAdvisoriesComponents/DOEAdvisories';

function DOEAdvisories (props) {
	
  useEffect (() => {
    document.title = "CELCOR | DOE Advisories";
  });
  
    return (
      <div className="Home">
        <Navbar />
        <HeroNews title="DOE Advisories" />
        <MainMenu />
        <ContentComponent />
      </div>
    );
}

export default DOEAdvisories;
