import React, { useState, useEffect } from "react";
import "../AboutComponents/css/contentstyle.css";
import "./css/Rates.css";
import loader from "../../loader.svg";
import axios from "axios";
import moment from "moment";
import config from 'react-global-configuration';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { parse } from "url";

const BodyStyle = {
  paddingTop: "40px"
};

const loaderStyle = {
	height: "150px",
	width: "150px"
};

function RatesArchives() {

	const [state, setState] = useStateWithCallbackLazy ({
		year: [],
		rates: [],
		filtercategory: 0,
		filteryear: 0,
		limit: 6,
		loader: true,
		maxfile: 0,
		ismaxed: false
	});

	const categorychanged = (e) =>
	{
		setState(prevState => ({
			...prevState,
			filtercategory: parseInt(e),
			limit: 6,
			ismaxed: false
		}), (currentState) => {			
			if(currentState.filtercategory === 0 && currentState.filteryear === 0)
			{
				fulllist();
			}
			else
			{
				filterlist(6, currentState)
			}
		});
	}

	const yearchanged = (e) =>
	{
		setState(prevState => ({
			...prevState,
			filteryear: parseInt(e),
			limit: 6,
			ismaxed: false
		}), (currentState) => {
			if(currentState.filtercategory === 0 && currentState.filteryear === 0)
				fulllist();
			else
				filterlist(6, currentState);
		});
	}	

	const filterlist = (limit, currentState) =>
	{		
		axios
			.all([
				axios.get(config.get('apiUrl') + "/Home/filterratescount", {
					params: {
						category: currentState.filtercategory,
						year: currentState.filteryear
					}
				}),
				axios.get(config.get('apiUrl') + "/Home/filterrates", {
					params: {
						category: currentState.filtercategory,
						year: currentState.filteryear,
						limit: currentState.limit
					}
				})
			])
			.then(
				axios.spread((...responses) => {
					setState(prevState => ({
						...prevState,
						maxfile: parseInt(responses[0].data),
						rates: responses[1].data,
						loader: false
					}), (currentState) => {
						if(Object.keys(currentState.rates).length === currentState.maxfile)
							setState(prevState => ({
								...prevState,
								ismaxed: true
						}));
					});
				})
			)
			.catch(ex => {

			})
	}

	const fulllist = () =>
	{
		axios
		.all([
			axios.get(config.get('apiUrl') + "/Home/ratesarchivescount"),
			axios.get(config.get('apiUrl') + "/Home/ratesarchives", {
				params: {
					limit: state.limit
				}
			})
		])
		.then(
			axios.spread((...responses) => {
				setState(prevState => ({
					...prevState,
					maxfile: parseInt(responses[0].data),
					rates: responses[1].data,
					loader: false
				}), (currentState) => {
					if(Object.keys(currentState.rates).length === currentState.maxfile)
						setState(prevState => ({
					 ...prevState,
					 ismaxed: true
					}))
				});
				// if(Object.keys(state.rates).length === state.maxfile)
				// 		setState(prevState => ({
				// 			...prevState,
				// 			ismaxed: true
				// 		}));
			})
		)
		.catch(ex => {

		});
	}

	useEffect (() => {
		const oldest = 2022;
		let items = [];
		let yr = parseInt(moment().format('YYYY'));
		let m = parseInt(moment().format('M'));
		if(m === 1)
			yr--;
		else
		{
			let d = parseInt(moment().format('D'));
			if(m === 2 && d < 3)
				yr--;
		}
		for(let x = yr; x >= oldest; x--)
			items.push(x);
		setState (prevState => ({
			...prevState,
			year: items
		}));
		fulllist();
	}, [])
	
	return (
		<>
		<div className="container-fluid relate">
		<Particles className="particlesdiv"	id="tsparticles" init={ParticlesInit}	options={ParticlesSetup}/>
				<div className="bodystyle-new" style={BodyStyle}>
					<div className="container">
						<div className="row mb-2">
							<div className="col-md-1 col-lg-1 col-xl-1"></div>
							<div className="col-sm-12 col-12 col-md-10 col-lg-10 col-xl-10 rate-text">
								<h5>Comparative Studies on Rates</h5>
								<span className="rate-text">The International Energy Consultants (IEC) recently released the results of their study entitled Regional/Global Comparison of Electricity Tariffs. In 2012, IEC presented a detailed survey and analysis of retail electricity tariffs and costs in 44 markets from across the globe. IEC likewise identified the factors driving differences between Meralco’s tariffs and those in other countries. The 2016 and 2018 studies update the rankings and examine tariff changes in these markets over the intervening periods.</span>
							</div>
							<div className="col-md-1 col-lg-1 col-xl-1"></div>
						</div>
						<div className="row mb-2">
							<div className="col-md-5 col-lg-5 col-xl-5"></div>
							<div className="col-sm-12 col-12 col-md-4 col-lg-4 col-xl-4">
								<form className="form-group">
									<label>Category</label>
									<select className="form-control form-control-sm " onChange={(e) => categorychanged(e.target.value)}>
										<option value="0">All</option>
										<option value="1">Generation</option>
										<option value="2">Residential Bill at Typical Consumption</option>
										<option value="3">Schedule of Rates</option>
									</select>
								</form>
							</div>
							<div className="col-sm-12 col-12 col-md-2 col-lg-2 col-xl-2">
								<form className="form-group">
									<label>Year</label>
									<select className="form-control form-control-sm" onChange={(e) => yearchanged(e.target.value)}>
										<option value="0">All</option>
										{state.year ? (
											<React.Fragment>
												{state.year.map((item, idx) => {
													return <option key={idx} value={item}>{item}</option>
												})}
											</React.Fragment>
										) : ""}
									</select>
								</form>
							</div>
							<div className="col-md-1 col-lg-1 col-xl-1"></div>
						</div>
						<div id="ratelist">
							{state.rates && Object.keys(state.rates).length > 0 ? (
								<React.Fragment>
									{state.rates.map((item, idx) => {
										return(
											<div className="container mb-3">
												<div className="row">
													<div className="col-md-1 col-lg-2 col-xl-2"></div>
													<div className="col-md-3 col-lg-2 col-xl-2 col-sm-12 col-12">{moment(item.BillMonth).format("MMMM YYYY")}</div>
													<div className="col-md-3 col-lg-4 col-xl-4 col-sm-12 col-12">{item.Category}</div>
													<div className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-12"><a href={`https://restapi.celcor.com.ph/files/rates/${item.FileName}`} className="download-pdf-link" target="_blank"><button type="button" className="btn btn-download-pdf">Download PDF</button></a></div>
													<div className="col-md-1 col-lg-2 col-xl-2"></div>
												</div>
											</div>
										)
									})}
									{state.ismaxed === false ? (
									<div className="row mb-3">
										<div className="col-md-4 col-lg-4"></div>
										<div className="col-sm-12 col-12 col-md-4 col-lg-4 col-xl-4">
										<br />
										<button style={{visibility: 'visible'}} type="button" className="btn btn-download-pdf" onClick={(e) => {
											setState(prevState => ({
													...prevState,
													limit: prevState.limit + 3,
													loader: true,
													filtercategory: state.filtercategory,
													filteryear: state.filteryear
												}), (currentState) => {
													filterlist(state.limit, currentState);
												});
										}}>Show More</button>
										</div>
										<div className="col-md-4 col-lg-4"></div>
									</div>
									) : (
									<React.Fragment />
									)}
									</React.Fragment>
									) : ""}	
							<div className="row">
								<div className="col-12 text-center">
									{state.loader && state.loader === true ? (
										<img
											src={loader}
											style={loaderStyle}
										/> 
									) : ""}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default RatesArchives;