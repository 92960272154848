import React, { Component } from "react";
import { Link } from "react-router-dom";
import './css/contentstyle.css';
import "./css/FAQs.css";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

function FAQS() {
    return(
        <div className="bodystyle-new">
            <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
            <div className="row">
                <div className="col-md-2 col-lg-2 col-xl-2"></div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <h4 className="faqs-header hyperlink-nocursor"></h4>
                    <strong className="faqs-list hyperlink-nocursor">1. HOW TO APPLY FOR NEW METER?</strong><br />
                    <span className="faqs-list">To apply, please submit the following requirements to Counter 9 at CELCOR Main Office in Bitas:</span>
                    <ul className="faqs-list">
                        <li>Electrical Permit issued by Cabanatuan City Local Government — Engineering's Office</li>
                        <li>Recent 2x2 picture (2 pieces)</li>
                        <li>Barangay Clearance (Photocopy)</li>
                        <li>Community Tax Certificate or Cedula (Photocopy)</li>
                        <li>Two (2) Government Issued ID's (Photocopy and must be photo-bearing)</li>
                        <li>Proof of ownership</li>
                    </ul>
                    <span className="faqs-list">For more information, call at 0998-982-7305 and look for Ms. Grace Reyes</span><br />
                    <br />

                    <strong className="faqs-list hyperlink-nocursor">2. WHERE TO REPORT A POWER OUTAGE/ELECTRICITY-RELATED EMERGENCY?</strong><br />
                    <span className="faqs-list">A. Averia APP</span>
                    <ul className="faqs-list">
                        <li>To download, Android users click <a href="https://restapi.celcor.com.ph/files/CELCOR-Averia.apk" className="hyperlink" target="_blank" download>here</a>, IOS users click <a target="_blank" href="http://averia.celcor.com.ph" className="hyperlink">here</a></li>
                        {/* <li>To know how to navigate the app, click <Link className="hyperlink" to="#">here</Link></li> */}
                    </ul>
                    <span className="faqs-list">B. Hotlines</span>
                    <ul className="faqs-list hotlines">
                        <li><span>LANDLINE</span><span>(044) 463-0505; (044) 463-0408; (044) 463-0811; (044) 463-7398; (044) 464-1797 and (044) 600-2414</span></li>
                        <li><span>MOBILE</span><span>SMART (0968) 246 0131<br /><span>GLOBE (0966) 980 2280</span></span></li>
                    </ul>
                    <br />

                    <strong className="faqs-list hyperlink-nocursor">3. WHERE CAN WE PAY OUR BILLS?</strong><br />
                    <span className="faqs-list">You may settle your bills thru</span>
                    <ol className="faqs-list" type="none">
                        <li>Our Main Office in Barangay Bitas and SMI Branch in Del Pilar;</li>
                        <li>Online Apps: GCash, Paymaya, Acash</li>
                        <li>And/or thru Local Banks and Establishments within Cabanatuan City</li>
                    </ol>
                    <span className="faqs-list">For more information, <a href="https://www.facebook.com/celcorofficialpage" className="hyperlink" target="_blank">click here</a></span><br />
                    <br />

                    <strong className="faqs-list hyperlink-nocursor">4. WHERE ARE YOUR OFFICES LOCATED?</strong><br />
                    <ul className="faqs-list">
                        <li><strong>CELCOR Main Office</strong><br />Barangay Bitas, along Maharlika Highway, Cabanatuan City, Nueva Ecija</li>
                        <li><strong>Samahang Magsasaka Incorporated (SMI) Collection Office</strong><br />Del Pilar St., Cabanatuan City, Nueva Ecija</li>
                    </ul>
                    <span className="faqs-list ml-75px"><i>With Business Schedule as follows:</i></span><br />
                    <span className="faqs-list ml-75px">Monday-Friday 08:00AM – 05:00PM,</span><br />
                    <span className="faqs-list ml-75px">Saturday 08:00AM – 12:00NN</span><br />
                    <br />

                    <strong className="faqs-list hyperlink-nocursor">5. HOW TO APPLY FOR PAPERLESS BILLING?</strong><br />
                    <span className="faqs-list ml-75px">To apply for paperless billing, make sure you have registed your account first. (<Link to="/register" className="hyperlink">Register here</Link>, you may also opt-in the subscribe to CELCOR Paperless Billing option on your registration.)</span><br />
                    <span className="faqs-list ml-75px">If you already have a registered account, <Link to="/login?href=pb" className="hyperlink">click here</Link>.</span><br />
                    <br />

                    <strong className="faqs-list hyperlink-nocursor">6. WHERE SHOULD I RAISE MY CONCERNS REGARDING CELCOR SERVICES?</strong><br />
                    <span className="faqs-list ml-75px">You may visit our Customer Relations Desk at CELCOR Main Office in Brgy. Bitas</span><br />
                    <span className="faqs-list ml-75px">or SMI Collection Office for assistance.</span><br />
                    <span className="faqs-list ml-75px"><i>With Business Schedule as follows:</i></span><br />
                    <span className="faqs-list ml-75px">Monday-Friday 08:00AM – 05:00PM,</span><br />
                    <span className="faqs-list ml-75px">Saturday 08:00AM – 12:00NN</span><br />
                    <br />
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2"></div>
            </div>
        </div>
    )
}

export default FAQS;