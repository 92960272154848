import React, { useEffect, useState, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import "./css/LoginForm.css";
import "../AboutComponents/css/contentstyle.css";
import loader from "../../loader.svg";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {encrypt} from "../../Common";
import config from 'react-global-configuration';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from '../AboutComponents/js/particles.init';

const hide = <FontAwesomeIcon icon={faEyeSlash} />
const show = <FontAwesomeIcon icon={faEye} />

const loaderStyle = {
	height: "150px",
	width: "150px"
};
const cardBodyStyle = {
	height: "500px"
};

function LoginForm () {

  const [state, setState] = useState({
    serverValidation: "",
    redirect: false,
    redirectTo: "",
    canSubmit: true,
    app_id: "",
    passwordType: "password",
    href_user: '',
  });

  const emailRef = useRef(null);
  const passRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    let url = new URLSearchParams(window.location.search);
		let user;
		if((user = url.get('user')) !== null)
      setState(prevState => ({
        ...prevState,
        href_user: user
      }));
      
  }, []);

  const login = (event) => 
  {
  event.preventDefault();
  if (true)
  {
    setState(prevState => ({
      ...prevState,
      serverValidation: ""
    }));

    let email = emailRef.current.value;
    let password = passRef.current.value;
    let token ="";
    
    const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 12; i++) {
    token += possible.charAt(Math.floor(Math.random() * possible.length));
    }    
    
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("token", token);

    setState(prevState => ({
      ...prevState,
      canSubmit: false
    }));

    axios
      .post(config.get('apiUrl') + "/Login", formData)
      .then(result => {
        setState(prevState => ({
          ...prevState,
          canSubmit: true
        }));
        let data = result.data[0];
        if(data.hasOwnProperty('Type'))
        {
          setState(prevState => ({
            ...prevState,
            serverValidation: data.Message
          }));
          return;
        }
        setState(prevState => ({
          ...prevState,
          serverValidation: "Login Successful!"
        }));

        emailRef.current.value = "";
        passRef.current.value = "";

        localStorage.setItem("userData", encrypt(JSON.stringify(result.data), 1));
        localStorage.setItem("Token", encrypt(token));
        let url = new URLSearchParams(window.location.search);
        
        if (url.get('href') === 'pb')
        {
          localStorage.setItem("idx", 0);
          setState(prevState => ({
            ...prevState,
            redirect: true,
            redirectTo: "/paperless-billing"
          }));
        }
        else
        {
          if(data.TradeId == 3)
          setState(prevState => ({
            ...prevState,
            redirect: true,
            redirectTo: "/change-account"
          }));
          else
          {
            localStorage.setItem("idx", 0);
            setState(prevState => ({
              ...prevState,
              redirect: true,
              redirectTo: "/dashboard"
            }));
          }
        }
      })
  }
  else
    setState(prevState => ({
      ...prevState,
      serverValidation: "You must agree to Website use of cookies first"
    }));
  }

  if (state.redirect)
  {
    return <Navigate to={state.redirectTo} />;
  }

  if (localStorage.getItem("userData"))
  {
    return <Navigate to={"/change-account"}/>;
  }

  return (
    <>
    <div className="container-fluid relate bodystyle-new-login">
      <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
      <div className="container px-0 pt-5 pb-5">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="card gradient-bg shadow pb-3">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <div className="text-primary h5">LOG IN</div>
              </div>
              <div className="card-body">
                <div className="container">
                  <form autoComplete="off" onSubmit={login}>
                    <div className="form-group">
                      <label htmlFor="email" className="rem-125">Email</label>
                      <input type="email" className="form-control" id="email" placeholder="Enter Email" defaultValue={state.href_user} ref={emailRef} required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="rem-125">Password</label>
                      <div className="input-group">
                        <input type={state.passwordType} className="form-control" id="password" placeholder="Enter Password" ref={passRef} required />
                        <div className="input-group-append">
                          <label className="btn btn-outline-secondary" onClick={(e) => {
                            if(state.passwordType == "text")
                            setState(prevState => ({
                              ...prevState,
                              passwordType: "password"
                            }));
                            else
                            setState(prevState => ({
                              ...prevState,
                              passwordType: "text"
                            }));
                          }}
                          title={state.passwordType ? (state.passwordType == "text" ? "Hide Password" : "Show Password") : ""}>
                          {state.passwordType ? (state.passwordType == "password" ? show : hide) : ""}
                          </label>
                        </div>
                      </div>
                    </div>
                    {state.canSubmit ? (
                      <button type="submit" className="btn btn-cta d-block mx-auto mt-3">Login</button>
                    ) : (
                      <>
                      <div className="text-center">
                        <img src={loader} style={loaderStyle} alt="Please wait loader from loader.io" /> <br />
												Please wait...
                      </div>
                      <button type="submit" className="btn btn-cta d-block mx-auto mt-3" disabled>
                        Login
                      </button>
                      </>
                    )}
                    <div className="text-center color-red">
                      {state.serverValidation}
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <Link to="/register" className="create-account">Create Account</Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
        </div>
      </div>
    </div>
    </>
  );
}

export default LoginForm;
