import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css/NewsAdvisoriesComponents.css";
import loader from "../../loader.svg";
import moment from "moment";
import config from 'react-global-configuration';
import AdvisorySidebar from "./AdvisorySidebar";
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

const loaderStyle = {
	height: "150px",
	width: "150px"
};

function Advisories () {

    const [state, setState] = useState ({
        latestNews: [],
        limit: 1,
        loader: true
    })

    useEffect (() => {
			axios
				.all([
					axios.get(config.get('apiUrl') + "/Advisories/homeAdvisories", {
						params: {
								limited: 1,
								tmp: 1	
						}
					})
				])
				.then(
					axios.spread((...responses) => {
						setState(prevState => ({
							...prevState,
							latestNews: responses[0].data.list,
							loader: false
						}));
					})
				)
				.catch(ex => {

				});
    },[]);

    return (
			<div className="container-fluid relate container-newsList pt-5" style={{backgroundImage: 'none'}}>
				<Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
			<div className="container px-0 pt-2">
				<div className="row">
				<div className="col-sm-12 col-md-4">
					<AdvisorySidebar />
				</div>
				<div className="col-sm-12 col-md-8 mb-3">
					{state.latestNews && Object.keys(state.latestNews).length > 0 ? (
						<React.Fragment>
							<h3 className="gradient-text mb-3 text-center">LATEST SERVICE INTERRUPTION ADVISORY</h3>
							{state.latestNews.map((item, idx) => {
								return (
									<React.Fragment>
										<div className="row advisories-list-with-category-caller">
											<div className="col-3 col-3 col-md-2 col-lg-2 col-xl-2">
												<div className="centered-text-container-big">
													<div className={`centered-text-big color-light-black ${item.Identifier}`} >
														<p className="centered-text">
															{moment(item.Date).format('MMM').toUpperCase()}<br />
															{moment(item.Date).format('DD')}
														</p>
													</div>
												</div>
											</div>
											<div className="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 text-info">
												<strong>{item.Category}</strong><br />
												<strong>DURATION : </strong>
												<span>{item.Duration}</span><br />
												<strong>REASON : </strong>
												<span>{item.Reason}</span>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<strong className="hyperlink-no-cursor">AFFECTED AREAS :</strong><br />
												<span className="color-light-black text-align-justify">
													{item.AffectedAreas.split('\n').map(i => {
														return <span>{i}<br /></span>
													})}
												</span>
											</div>
										</div>
									</React.Fragment>
								)
							})}
							
						</React.Fragment>
					) : state.loader === true ? "" : <h3 className="gradient-text text-center">NO AVAILABLE DATA</h3>}
					<div className="row">
						<div className="col-12 text-center">
							{state.loader && state.loader === true ? (
								<img
									src={loader}
									style={loaderStyle}
								/> 
							) : ""}
						</div>
					</div>
				</div>
				</div>
			</div>
		</div>
    );
}

export default Advisories;