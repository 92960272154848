import React from "react";
import { Link } from "react-router-dom";
import "./css/NewsAdvisoriesComponents.css";

function AdvisorySidebar () {
    return(
        <div className="advisories-bodystyle">
            <div className="card gradient-bg shadow mb-2">
                <div className="list-group">
                    <Link className="hyperlink list-group-item archive-item list-group-item-action" to="/service-advisories">Service Advisories</Link>
                    <Link className="hyperlink list-group-item archive-item list-group-item-action" to="/bill-advisories">Bill Advisories</Link>
                    <Link className="hyperlink list-group-item archive-item list-group-item-action" to="/office-advisories">Office Advisories</Link>
                </div>
            </div>
        </div>
    )
}
export default AdvisorySidebar;