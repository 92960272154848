import React from "react";
import "../css/NewsContents.css";
import Pic1 from "./image/Typhoon Karding 2022 1.png";
import Pic2 from "./image/Typhoon Karding 2022 2.png";
import Pic3 from "./image/Typhoon Karding 2022 3.png";
import Pic4 from "./image/Typhoon Karding 2022 4.png";
import Pic5 from "./image/Typhoon Karding 2022 5.png";
import Pic6 from "./image/Typhoon Karding 2022 6.png";

function Content () {
    return(
        <React.Fragment>
            <div className="text-justify">
					Damaged power lines, broken poles, and other related electrical structures—despite all these, Cabanatuan
					Electric Corporation (CELCOR) made it possible to restore 60% of the consumer's electricity
					in less than 24 hours atfer Typhoon Karding hit our province last September 25, 2022.
					<br /><br />
					Signal number 4 was raised in Nueva Ecija after Karding was categorized as a Super Typhoon. Due to
					strong wind and rain, two (2) main lines of the National Grid Corporation of the Philippines (NGCP) were
					damages along with CELCOR's electrical structures causing a city-wide power interruption.
					<br /><br />
					Acccording to the estimates, 150 poles has to be replaced in addition to the power lines, transformers, and
					other operational activities in order to restore electricity supply in the whole city. After the typhoon
					surge, our crew men conducted a survey to assess the damages and went ahead with the clearing and
					power restoration efforts.
					<br /><br />
					Dagupan Electric Corporation (DECORP) assisted our team in restoring the electricity supply in Barangay
					Patalac which experienced major damages along with other Barangays such as Sumacab Este, Mayapyap
					Sur, Valdefuente, and Sapang.
					<br /><br />
					By October 7, 2022, 99% of the consumers' electricity has been restored.
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic1} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic2} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic3} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic4} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic5} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<img className="img-thumbnail img-fluid image" src={Pic6} />
					</div>
				</div>
        </React.Fragment>
    )
	
}

export default Content;