import React, { useEffect } from "react";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import ContentComponent from '../../components/NewsAdvisoriesComponents/Announcements';

function Announcements() {

    useEffect(() => {
        document.title = "CELCOR | Announcements";
    });
    
    return (
        <div className="Announcements">
            <Navbar />
            <HeroNews title="Announcements" />
            <MainMenu />
            <ContentComponent />
        </div>
    );
}

export default Announcements;