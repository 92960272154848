import React, {useState, useEffect, useRef} from 'react'
import { Navigate } from 'react-router-dom';
import axios from "axios";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { encrypt } from '../../../Common';
import config from 'react-global-configuration';
import CurrencyFormat from 'react-currency-format';
import Particles from "react-tsparticles";
import ParticlesSetup from '../../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "../../AboutComponents/js/particles.init";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import swal from 'sweetalert';

const hide = <FontAwesomeIcon icon={faEyeSlash} />
const show = <FontAwesomeIcon icon={faEye} />

const BodyStyle = {
	paddingTop: "96px",
	marginBottom: "80px"
};

function AccountSettings() {

  const [state, setState] = useStateWithCallbackLazy ({
    redirect: false,
    redirectToDash: false,
    emailChanging: false,
    user_id: '',
    token: "",
    passwordType: "password",
    newPasswordType: "password",
    passUpdateValidation: "",
    emailUpdateValidation: "",
    code: ''
  });

  const curpassRef = useRef(null);
  const newpassRef = useRef(null)
  const emailRef = useRef(null);
  const btnUpdateEmailRef = useRef(null);

  useEffect (() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (localStorage.getItem("userData"))
    {
      const userData = JSON.parse(encrypt(localStorage.getItem("userData"), 2));
			const token = encrypt(localStorage.getItem("Token"));
			
      setState(prevState => ({
        ...prevState,
        user_id: userData[0].Id,
				email: userData[0].Email,
				token: token
      }));
    }
  }, [])

  const codechanged = (e) =>
  {
    const code = e.target.value;
		if(code.length === 6 && countString(code, '_') === 0)
			return code;
		return '';
  }

  const countString = (str, strToCount) =>
  {
    var result = 0, i = 0;
		for(i; i<str.length; i++)
		{
			if(str[i] == strToCount)
				result++;
		}
		return result;
  }

  const updatePassword = (event) => 
  {
    event.preventDefault();

    if(newpassRef.current.value.length < 6)
    {
      setState(prevState => ({
        ...prevState,
        passUpdateValidation: "New password must be more than 5 characters"
      }));
    }
    else
    {
      const formData = new FormData();
      formData.append("cust_id", state.user_id);
      formData.append("curr_pass", curpassRef.current.value);
      formData.append("new_pass", newpassRef.current.value);
      formData.append("token", state.token);

      axios
        .post(config.get('apiUrl') + "/Main/Profile_update_pass", formData)
        .then(result => {
					if (result.data == 1) {
            swal({
              title: '',
              text: 'Update Successful!',
              icon: 'success',
              timer: 2500,
              buttons: false,
            })
            .then(() => {
              setState(prevState => ({
                ...prevState,
                redirectToDash: true
              }));
            });

            // setState(prevState => ({
            //   ...prevState,
            //   passUpdateValidation: "Update Successful!"
            // }));
            
					} else if (result.data == 0) {
						setState(prevState => ({
              ...prevState,
              passUpdateValidation: "New Password cannot be the same as your Current Password."
            }));
					} else {
						setState(prevState => ({
              ...prevState,
              passUpdateValidation: result.data
            }));
					}
				})
    }
  }

  const changeemail = () => 
  {
    const formData = new FormData();
    formData.append("cust_id", state.user_id);
    formData.append("email", emailRef.current.value);
    formData.append("token", state.token);

    if(state.emailChanging === true)
    {
      formData.append("code", state.code);
      axios
        .post(config.get('apiUrl') + "/Main/profile_update_email", formData)
        .then(response => {
          let data = response.data;
          setState(prevState => ({
            ...prevState,
            emailUpdateValidation: data.Message
          }), () => {
            if(data.Type === 'Success')
            {
              btnUpdateEmailRef.current.disabled = true;
              setState(prevState => ({
                ...prevState,
                emailChanging: false
              }), () => {
                setTimeout(() => {
                  localStorage.setItem("userData", "");
                  localStorage.clear();

                  setState(prevState => ({
                    ...prevState,
                    redirect: true
                  }), 2500);
                })
              });
            }
          });
        })
        .catch(result => {

        });
    }
    else
    {
      axios
        .post(config.get('apiUrl') + 'Main/profile_update_email_changing', formData)
        .then(response => {
          let data = response.data;
          setState(prevState => ({
            ...prevState,
            emailUpdateValidation: data.Message
          }), () => {
            if(data.Type === 'Success')
            {
              setState(prevState => ({
                ...prevState,
                emailChanging: true
              }));
            }
          });
        })
        .catch(ex => {

        });
    }
  }

  const change = e => 
  {
    setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }

  if(state.redirect)
  {
    return <Navigate to={"/login"} />
  }

  if(state.redirectToDash)
  {
    return <Navigate to={"/dashboard"} />
  }

  return (
    <>
    <div className="bodyStyle" style={BodyStyle}>
      <div className="container-fluid relate">
      <Particles className="particlesdiv" id="tsparticles" init={ParticlesInit}	options={ParticlesSetup}/>
        <div className="row">
          <div className="col-sm-12 col-md-6 offset-md-3">
            <h2 className="text-center text-info">Change Email Address</h2>
            <div
              className="px-3 form-updateEmail">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  ref={emailRef}
                  name="email"
                  defaultValue={state.email}
                  onChange={e => change(e)}
                  disabled={state.emailChanging}
                  onKeyDown={(e) => {
                    if(e.keyCode === 13)
                    {
                      e.target.blur();
                      changeemail();
                    }
                  }}
                />
              </div>
              {state.emailChanging ? (
                state.emailChanging === true ? (
                  <div className="form-group">
                    <label htmlFor="email">6-Digit Code</label>
                    <CurrencyFormat 
                      className="form-control text-center"
                      type="tel"
                      format="######" 
                      mask="_"
                      required
                      onBlur={(e) => {
                        let code = codechanged(e);
                        setState({
                          code: code
                        })
                      }}
                      onKeyDown={(e) => {
                        if(e.keyCode === 13)
                        {
                          let code = codechanged(e);
                          setState(prevState => ({
                            ...prevState,
                            code: code
                          }));
                          changeemail();
                        }
                      }}
                    />
                  </div>
                ) : ""
              ) : ""}
              
              <div className="text-center color-red m-1">
                {state.emailUpdateValidation ? (
                  <div className="text-center">
                    <React.Fragment>
                      {state.emailUpdateValidation}
                    </React.Fragment>
                  </div>
                ) : ("")}
              </div>
              <button
                type="submit"
                className="btn btn-cta  d-block mx-auto mt-3"
                ref={btnUpdateEmailRef}
                id="btnUpdateEmail"
                onClick={(e) => {
                  changeemail();
                }}
              >
                Update
              </button>
            </div>

            <hr className="my-5" />
            <h2 className="text-center text-info">Change Password</h2>

            <form autoComplete="off"
              className="px-3 form-updatePass"
              onSubmit={updatePassword}
            >
              <div className="form-group" style={{marginBottom: '1rem'}}>
                <label>Current Password</label>
                <div className="input-group">
                  <input type={state.passwordType} className="form-control" placeholder="Enter current password" ref={curpassRef} required />
                  <div className="input-group-append">
                    <label className="btn btn-outline-secondary" onClick={(e) => {
                      if(state.passwordType == "text")
                        setState(prevState => ({
                          ...prevState,
                          passwordType: "password"
                        }));
                      else
                        setState(prevState => ({
                          ...prevState,
                          passwordType: "text"
                          }));
                      }} 
                      title={state.passwordType ? (state.passwordType == "text" ? "Hide Password" : "Show Password") : ""}>
                      {state.passwordType ? (state.passwordType == "password" ? show : hide) : ""}
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group" style={{marginBottom: '1rem'}}>
                <label>New Password</label>
                <div className="input-group">
                  <input
                    type={state.newPasswordType}
                    className="form-control"
                    placeholder="Enter new password"
                    ref={newpassRef}
                    required
                  />
                  <div className="input-group-append">
                    <label className="btn btn-outline-secondary" onClick={(e) => {
                      if(state.newPasswordType == "text")
                        setState(prevState => ({
                          ...prevState,
                          newPasswordType: "password"
                        }));
                      else
                        setState(prevState => ({
                          ...prevState,
                          newPasswordType: "text"
                        }));
                      }} 
                      title={state.newPasswordType ? (state.newPasswordType == "text" ? "Hide Password" : "Show Password") : ""}>
                      {state.newPasswordType ? (state.newPasswordType == "password" ? show : hide) : ""}
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-center color-red m-1">
                {state.passUpdateValidation}
              </div>
              <button
                type="submit"
                className="btn btn-cta  d-block mx-auto mt-3"
                style={{marginBottom: '107px'}}
                >
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
		</div>
    </>
  )
}

export default AccountSettings