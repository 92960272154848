import React, { useEffect } from "react";
import MainMenu from "../../components/MainMenu/MainMenu";
import Navbar from "../../components/Navbar/Navbar";
import HeroFAQs from '../../components/Hero/HeroFAQs/HeroFAQs';
import ContentComponent from '../../components/FAQs/FAQs';

function FAQs() {

    useEffect (() => {
        document.title = "CELCOR | Frequently Asked Questions";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return(
        <React.Fragment>
            <Navbar />
            <HeroFAQs />
            <MainMenu />
            <ContentComponent />
        </React.Fragment>
    );
}

export default FAQs;