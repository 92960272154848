import React, {useEffect} from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroOfficeSchedule from "../../components/Hero/HeroOfficeSchedule/HeroOfficeSchedule";
import MainMenu from "../../components/MainMenu/MainMenu";
import ContentComponent from "../../components/OfficeSchedule/OfficeSchedule";

function OfficeSchedule () {

    useEffect(() => {
        document.title = "CELCOR | Office Schedule";
    },[],);

    return (
        <>
        <Navbar />
        <HeroOfficeSchedule />
        <MainMenu />
        <ContentComponent />
        </>
    );
    
}
export default OfficeSchedule;