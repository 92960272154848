import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import HeroBillsPayment from "../../components/Hero/HeroBillsPayment/HeroBillsPayment";
import ContentComponent from '../../components/BillsPaymentComponents/Online';

function Online() {

    useEffect(() => {
        document.title = "CELCOR | Online";
    },[]);
    
    return (
        <div className="Online">
            <Navbar />
            <HeroBillsPayment title="Online"/>
            <MainMenu />
            <ContentComponent />
        </div>
    );

}
export default Online;