import React, { Component } from "react";
import {Link} from 'react-router-dom';
import img from './image/METER APPLICATION.png';
import Particles from "react-tsparticles";
import ParticlesSetup from '../AboutComponents/js/particlesjs-config.json';
import ParticlesInit from "./../AboutComponents/js/particles.init";

function MeterApplication() {

    return (
		<React.Fragment>
			<div className="container-fluid relate">
			<Particles className="particlesdiv" id="tsparticles" init={ParticlesInit} options={ParticlesSetup}/>
				<div className="container px-0 pt-5 pb-5">
					<div className="card relate box-shadow card-contact">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<Link to="/contact-us"><img src={img} className="about-new-img" /></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
    );
}

export default MeterApplication;