import React, {useEffect} from "react";
import HeroNews from "../../components/Hero/HeroNews/HeroNews";
import Navbar from "../../components/Navbar/Navbar";
import MainMenu from "../../components/MainMenu/MainMenu";
import Archive from "../../components/NewsArchive/NewsArchive";

function NewsArchive () {

    useEffect (() => {
        document.title = "CELCOR | News Archive";
    });

    return (
        <div className="Home">
            <Navbar />
            <HeroNews title="News" />
            <MainMenu />
            <Archive />
        </div>
    );
}

export default NewsArchive;